import React, { useState, useEffect } from "react";
import style from "./TaskAssignedByMeComponent.module.css";
import filterimg from "./../../../../assets/filter-results-button.png";
import Iconmetrocross from "../../../../assets/Iconmetrocross.png";
import Iconionicmdcheckbox from "../../../../assets/Iconionicmdcheckbox.png";
import sortDownarrowImg from "./../../../../assets/Down.png";
import sortUparrowImg from "./../../../../assets/Up.png";
import milestone from "../../../../assets/milestone.png";
import Polygon from "../../../../assets/Polygon.png";
import downarrow from "../../../../assets/downarrow.png";
import loaderImg from "../../../../assets/loader-green2.gif";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import Pagination from "../../commonComponent/Pagination";
import Filter from "../../myTasks/component/Filter";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import axios from "axios";
import constants, { employeeId } from "../../../../Utils/constants";
import { taskTrackerAccessCode } from "../../../../Utils/constants";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import cookie from "react-cookies";
const arr = [];

const TaskAssignedByMeComponent = (props) => {
  const { checkValidateAccess } = useValidationAccess();
  const [data, setData] = useState();

  const [tasks, setTasks] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [applied, setApplied] = useState(false);
  const [totalTasks, settotalTasks] = useState(0);
  const [creationDateOrder, setCreationDateOrder] = useState(null);
  const [pageCount, setpageCount] = useState(0);
  const history = useHistory();
  const limit = 15;
  const [sortBy, setSortBy] = useState("planedEndDate");
  const [sort, setSort] = useState("ASC");
  const [assigned, setAssigned] = useState([]);
  const [planedEndDateOrder, setPlanedEndDateOrder] = useState(null);
  const [priority, setPriority] = useState([]);
  const [priorityOrder, setPriorityOrder] = useState(null);
  const [status, setStatus] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [allData2, setAllData] = useState({
    assignedTo: assigned,
    creationDateOrder: creationDateOrder,
    planedEndDateOrder: planedEndDateOrder,
    priority: priority,
    priorityOrder: priorityOrder,
    status: status,
    functionalAreaId: [],
  });

  const url2 = constants.apiBaseUrl2;

  const getAssignedByme = () => {
    axios
      .get(url2 + "/getaskAssignedByFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setData(res.data.responseData.employeeNames);
      })
      .catch(() => { });
  };

  const handleapidata = () => {
    setIsLoaded(true);
    const taskAssignData = axios
      .post(
        url2 + "/getTaskAssignedByMe?limit=" + limit + "&page=" + currentPage + "&sortBy=" + sortBy + "&sort=" + sort,
        allData2,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          setIsLoaded(false);
        }, 250);
        if (response.data.statusCode == 0) {
          const responseData = response.data.responseData;
          setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
          setTasks(responseData.taskResponse);
          settotalTasks(responseData.totalTask);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const geyEmployeeList2 = () => {
    const employeeData = axios
      .get(url2 + "/getaskAssignedByFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })

      .then((res) => {
        if (res.data.statusCode === 0) {
          setUserLists(res.data.responseData.employeeNames);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const handleApplied = (arg) => {
    setCurrentPage(1);

    if (assigned.length !== 0 || priority.length !== 0 || status.length !== 0) {
      setApplied(true);
    }
    setShowFilter(false);
    handleapidata();
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleTask = () => {
    history.push("/taskTracker/createTask");
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const closeFilter = () => {
    setAllData((preValue) => {
      return preValue
        ? {
          ...preValue,
          assignedTo: [],
          priority: [],
          status: [],
          functionalAreaId: [],
        }
        : null;
    });
    setAssigned([]);
    setPriority([]);
    setStatus([]);
    setShowFilter(false);
    setApplied(false);
  };



  const handleTaskDetail = (arg) => {
    history.push({
      pathname: "/taskAssignedByMeDetails",
      state: {
        task: arg,
        activePage: 2,
      },
    });
  };

  useEffect(() => {
    handleapidata();
    getAssignedByme();
    geyEmployeeList2();
  }, [currentPage, creationDateOrder, allData2, sortBy, sort]);

  function formatDate(dateString) {
    return dateString.split(' ')[0] + ' ' + dateString.split(' ')[1];
  }

  const handleSort = (field) => {
    setSortBy(field);
    if (sort === "ASC") {
      setSort("DESC")
    } else {
      setSort("ASC")
    }
  };


  const renderSortArrow = (field) => {
    if (sortBy !== field) return null;

    return sort === "ASC" ? (
      <TiArrowSortedUp
        style={{
          // fontSize: "1.1rem",
          cursor: "pointer",
          color: "blue",
        }}
      />
    ) : (
      <TiArrowSortedDown
        style={{
          // fontSize: "1.1rem",
          cursor: "pointer",
          color: "blue",
        }}
      />
    );

  };

  return (
    <>
      <div className={style.taskTracker}>
        {/* <div className={style.breadcrums}>
          <span className={style.font14}>Task Tracker {">"}</span>
          <span className={style.font15}> Tasks Assigned By Me </span>
        </div> */}
        <div className={style.header}>
          <div>
            <span>Task Assigned By Me</span>
            <p>
              <span>{tasks ? totalTasks : 0}</span>
            </p>
          </div>
          <button className={style.filter} onClick={handleFilter}>
            {applied && (
              <img
                className={style.appliedcheck}
                src={Iconionicmdcheckbox}
                alt="applied"
              />
            )}
            <img src={filterimg} alt="filterimg" />
            <span>Filter</span>
          </button>

          {showFilter && (
            <img className={style.polygon} src={Polygon} alt="Polygon" />
          )}
          <div>
            {checkValidateAccess(taskTrackerAccessCode?.create_Task_TaskAssigned) && (
              <button onClick={handleTask} className={style.createTask}>
                <img
                  style={{ width: "15px", marginRight: "10px" }}
                  src={Iconmetrocross}
                  alt="Iconmetrocross"
                />
                <span>Create Task</span>
              </button>
            )}</div>
          <div className={style.mobileHeader}>
            {/* <p>
              <img onClick={handleFilter} src={Iconfeatherfilter} />
            </p>
            <p style={{ backgroundColor: "#65A15E" }}>
              <img onClick={handleTask} src={Iconmetrocross} />
            </p> */}
          </div>
        </div>
        <div
          className={style.container}
          onClick={() => {
            setShowFilter(false);
          }}
        >
          {isLoaded ? (
            <div>
              <img src={loaderImg} alt="loader" className={style.loader} />
              <p className={style.loaderPara}>Loading...</p>
            </div>
          ) : (
            ""
          )}
          {tasks.length > 0 && !isLoaded ? (
            <div style={{ height: '485px', overflowY: 'auto' }}>
              <table>
                <thead style={{ position: 'sticky', top: "0" }}>
                  <tr style={{ textDecoration: 'underline', cursor: 'pointer' }} className={style.th}>
                    <th
                      onClick={() => handleSort("id")}
                      style={{ width: '4rem',paddingLeft: '.2rem' }}
                    >
                      Task {renderSortArrow("id")}<br></br>
                      ID
                    </th>
                    <th
                      onClick={() => handleSort("created")}
                      style={{ width: '5rem' }}
                    >
                      Creation
                      {renderSortArrow("created")}
                      <br></br> Date

                    </th>
                    <th style={{width: "1rem"}}></th>
                    <th
                      onClick={() => handleSort("title")}
                      style={{paddingLeft: "10px", width: '33rem' }}
                    >
                      Task Title
                      {renderSortArrow("title")}
                    </th>
                    <th
                      onClick={() => handleSort("assignedTo")}
                      style={{ width: '7rem' }}
                    >
                      Assigned To
                      {renderSortArrow("assignedTo")}
                    </th>
                    <th
                      onClick={() => handleSort("status")}
                      style={{ width: '5rem' }}
                    >
                      Status
                      {renderSortArrow("status")}
                    </th>
                    <th
                      onClick={() => handleSort("priority")}
                      style={{ width: '5rem' }}
                    >
                      Priority
                      {renderSortArrow("priority")}
                    </th>
                    <th
                      onClick={() => handleSort("planedEndDate")}
                      style={{ width: '5rem' }}
                    >
                      Planned
                      {renderSortArrow("planedEndDate")}
                      <br /> End Date
                    </th>

                    {/* <th
                      style={{ width: '4rem' }}
                    >
                      Functional Area
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {tasks &&
                    tasks.map((task, index) => (
                      <tr className={style.td} key={index}>
                        <td
                          style={{ width: '4rem',paddingLeft: '.2rem' }}
                        >
                          {task.taskId}
                        </td>
                        <td
                          style={{ width: '5rem' }}
                        >
                          {formatDate(task?.created)}
                        </td>
                        <td style={{width: "1rem"}}>{tasks[index]?.recurringTask ? (<span
                      style={{
                        fontSize: "10px",
                        color: "rgb(168, 50, 50)",
                        fontWeight: "800",
                        border: "1px solid rgb(168, 50, 50)",
                        borderRadius: "999px",
                        padding: "2px 6px",
                        // marginLeft: "5px",
                      }}
                    >
                      R
                    </span>) : null}</td>
                        <td
                          style={{paddingLeft: "10px", width: "33rem", fontSize: '15px', fontWeight: '500', textDecoration: 'underline', cursor: "pointer", color: task.status === "Closed" ? "red" : "#65A15E" }}
                          onClick={() => handleTaskDetail(task)}
                          title={task && task?.title?.length > 67 && task?.title}
                        >
                          <b>{task && task.title && task.title?.length > 67 ? task.title.substring(0, 67) + "..." : task.title}</b>
                        </td>
                        <td
                          style={{ width: '7rem' }}
                          title={task && task.name?.length > 20 && task?.name}
                        >
                          {task && task.name && task.name?.length > 20 ? task.name.substring(0, 20) + "..." : task.name}
                        </td>
                        <td
                          style={{ width: '5rem' }}
                          className={
                            task.status === "Assigned"
                              ? style.asigned_color
                              : task.status === "Done"
                                ? style.done_color
                                : task.status === "Onhold"
                                  ? style.onhold_color
                                  : style.Closed_color
                          }
                        >
                          {task.status === "Closed"
                            ? "Closed"
                            : task.status === "Onhold"
                              ? "On Hold"
                              : task.status}
                        </td>
                        <td
                          style={{ width: '5rem' }}
                        >
                          {task.priority}
                        </td>
                        <td
                          style={{ width: '5rem' }}
                        >
                          {Moment(task.planedEndDate).format("DD MMM")}
                        </td>
                        {/* <td
                          style={{ width: '4rem' }}
                        >
                          {task.functionalArea}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={style.noTasks}>
              {!isLoaded ? (
                <div style={{ display: "block" }}>
                  <img src={milestone} alt="milestone" />
                  <h2>No Task Assigned Yet</h2>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <div className={style.mobileContainer}>
            {arr.map((ar, indexar) => {
              return (
                <div
                  style={{
                    display: "block",
                    borderBottom: "1px solid #0000001A",
                    marginBottom: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <strong>{indexar + 1}. Database Creation</strong>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Assigned By :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.b}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Status :
                    </span>
                    <span
                      style={{
                        color: "#FCB902",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      {" " + ar.c}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Priority :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.d}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Planned End Date :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.a}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={style.responsivearrow}>
          <img
            src={downarrow}
            style={{ transform: "rotateZ(90deg)" }}
            onClick={handlePageChange}
            alt="down"
          />
          <img
            src={downarrow}
            style={{ transform: "rotateZ(-90deg)" }}
            alt="down"
          />
        </div>
        {totalTasks > limit && pageCount > 0 && (
          <div
            className={style.paginationmobileContainer}
            style={{ marginRight: "1.3rem", marginTop: "20px" }}
          >
            <Pagination
              pagesCount={pageCount}
              pageSize={limit}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>

      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          userLists={userLists}
          filterData={allData2}
          setassigned={setAssigned}
          setpriority={setPriority}
          setstatus={setStatus}
          data={data}
        />
      )}
    </>
  );
};

export default TaskAssignedByMeComponent;
