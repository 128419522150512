import React, { useState, useEffect } from "react";
import style from "./ViewOffer.module.css";
import Loader from "../../../../GenericComponent/Loader/Loader";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import {
  getCandidateById,
  getOfferByOfferId,
  uploadResume,
  deleteResume,
  approveOffer,
  releaseOffer,
  addAcceptDeclineOffer,
  revokeOffer,
  cancelOffer,
} from "../../../../Utils/axios/api";
import DeclinePopup from "../../../../Pages/userManegment/pageLayout/viewUser/ConfirmPopup";
import AcceptPopup from "../../../../Pages/userManegment/pageLayout/viewUser/ConfirmPopup";
import ReleasePopup from "../../../../Pages/userManegment/pageLayout/viewUser/ConfirmPopup";
import RevokePopUp from "../../../../Pages/userManegment/pageLayout/viewUser/ConfirmPopup";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { notifyError, notifySuccess } from "../../utils/notify";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../breadcrums/BreadCrum";
import DatePicker from "react-datepicker";
import calendarIcon from "../../../../assets/calendarIcon.png";
import CustomTooltip from "../../customTooltip/CustomTooltip";
import { employeeId } from "../../../../Utils/constants";
import ValidationEmpAccess from "../validationEmpAccess/ValidationEmpAccess";
import { accessCode } from "../../../../Utils/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import docx from "../../../../assets/docx-icon.svg";
import doc from "../../../../assets/doc-icon.svg";
import pdf from "../../../../assets/pdf-icon.svg";
import CopyEmail from "../../../../GenericComponent/CopyEmailFeature/CopyEmail";

const ViewOffer = ({
  setNextScreen,
  prevData,
  setPrevData,
  validateAccess,
}) => {
  const [resumeName, setResumeName] = React.useState("");
  const [offerDoc, setOfferDoc] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [offerReleModal, setOfferReleModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModal2, setErrorModal2] = useState(false);
  const [offerDeclModal, setOfferDeclModal] = useState(false);
  const [offerAcceptModal, setOfferAcceptModal] = useState(false);
  const [validate, setValidate] = useState(false);
  const [isLoadingApprov, setIsLoadingApprov] = useState(false);
  const [releseDate, setRelesDate] = useState(null);
  const { offerId, candidateId } = useParams();
  const [offerRevokedModal, setOfferRevokedModal] = useState(false);
  const [offerCancelModal, setOfferCancelModal] = useState(false);
  const [offerRevokedForm, setOfferRevokedForm] = useState({});
  const [openNote, setOpenNote] = useState(false);
  const [currNote, setCurrNote] = useState("");
  const { data: candidateData } = useQuery(
    ["getCandidateById"],
    () =>
      getCandidateById(
        prevData?.candidateId ? prevData?.candidateId : candidateId
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: offerData, refetch: refetchOfferData } = useQuery(
    ["getOfferByOfferId"],
    () => getOfferByOfferId(prevData?.offerId ? prevData.offerId : offerId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: approveFetch } = useQuery(
    ["approveOfferKey"],
    () => approveOffer(employeeId, prevData?.offerId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setAcceptForm(null);
        setIsLoadingApprov(false);

        prevData?.page1 === "myCandidateFinalStages"
          ? setPrevData({
              ...prevData,
              page1: "myCandidateFinalStages",
              page2: "CandidateOfferScreen",
              page3: null,
              page4: null,
              active: "CandidateOfferScreen",
            })
          : setPrevData({
              ...prevData,
              page1: "candidateList",
              page2: "ViewCandidate",
              page3: "CandidateOfferScreen",
              page4: null,
              active: "CandidateOfferScreen",
            });
        setNextScreen("CandidateOfferScreen");
      },
    }
  );

  const [acceptForm, setAcceptForm] = useState({
    candidateId: candidateData?.id,
    offerId: offerData?.offerId
      ? offerData.offerId
      : prevData?.offerId
      ? prevData.offerId
      : offerId,
    acceptDeclineDate: "",
    note: null,
    docUrl: null,
    status: true,
    empId: employeeId,
  });

  const [declineForm, setDeclineForm] = useState({
    candidateId: candidateData?.id,
    offerId: offerData?.offerId
      ? offerData.offerId
      : prevData?.offerId
      ? prevData.offerId
      : offerId,
    acceptDeclineDate: "",
    note: "",
    docUrl: "",
    status: false,
    empId: employeeId,
  });

  // const empId = employeeId;

  const uploadDocMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setDeclineForm({ ...declineForm, docUrl: data.toString() });
      setIsLoadingApprov(false);
    },
  });

  const offerdeclineMutation = useMutation(addAcceptDeclineOffer, {
    onSuccess: (res) => {
      notifySuccess("Offer Status Changed");
      prevData?.page1 === "myCandidateFinalStages"
        ? setPrevData({
            ...prevData,
            page1: "myCandidateFinalStages",
            page2: "CandidateOfferScreen",
            page3: null,
            page4: null,
            active: "CandidateOfferScreen",
          })
        : setPrevData({
            ...prevData,
            page1: "candidateList",
            page2: "ViewCandidate",
            page3: "CandidateOfferScreen",
            page4: null,
            active: "CandidateOfferScreen",
          });
      setNextScreen("CandidateOfferScreen");
      setDisabled(false);
      setDeclineForm(false);
      setIsLoadingApprov(false);
    },
    onError: (error) => {
      notifyError(error);
      setDisabled(false);
    },
  });

  const declineFormHandler = () => {
    if (!declineForm.note) {
      setValidate(true);
    } else {
      setValidate(false);
      offerdeclineMutation.mutate(declineForm);
      setOfferDeclModal(false);
    }
  };

  const offerAcceptMutation = useMutation(addAcceptDeclineOffer, {
    onSuccess: (res) => {
      notifySuccess("Offer Status Changed");
      setNextScreen("CandidateOfferScreen");
      prevData?.page1 === "myCandidateFinalStages"
        ? setPrevData({
            ...prevData,
            candidateId: candidateData?.id,
            page1: "myCandidateFinalStages",
            page2: "CandidateOfferScreen",
            page3: null,
            page4: null,
            active: "CandidateOfferScreen",
          })
        : setPrevData({
            ...prevData,
            page1: "candidateList",
            page2: "ViewCandidate",
            page3: "CandidateOfferScreen",
            page4: null,
            active: "CandidateOfferScreen",
          });
      setDisabled(false);
      setApproveModal(false);
      setIsLoadingApprov(false);
    },
    onError: (error) => {
      notifyError(error);
      setDisabled(false);
    },
  });

  const acceptOfferHandler = () => {
    if (
      acceptForm?.acceptDeclineDate &&
      offerData?.offerReleaseDate &&
      acceptForm?.acceptDeclineDate &&
      new Date(acceptForm?.acceptDeclineDate).getTime() >=
        new Date(offerData?.offerReleaseDate).getTime()
    ) {
      setValidate(false);
      offerAcceptMutation.mutate(acceptForm);
      setOfferAcceptModal(false);
      // setIsLoadingApprov(true);
    } else {
      setValidate(true);
    }
  };

  const approvedStatus = () => {
    approveFetch();
    setApproveModal(false);
    setIsLoadingApprov(true);
  };

  useEffect(() => {
    setResumeName(
      offerData?.offerUrl
        ? offerData?.offerUrl?.split("@")[1]
          ? offerData?.offerUrl?.split("@")[1]
          : offerData?.offerUrl
        : "No resume selected"
    );
  }, [offerData]);

  useEffect(() => {
    setOfferDoc(declineForm?.docUrl);
  }, [declineForm?.docUrl]);

  const getContentForResume = (resumeName) => {
    const extension = resumeName.split(".").pop().toLowerCase(); // Extract file extension and convert to lowercase

    switch (extension) {
      case "pdf":
        return (
          <img
            style={{ cursor: "pointer", color: "green" }}
            src={pdf}
            alt="PDF Icon"
          ></img>
        );
      case "doc":
        <img style={{ cursor: "pointer" }} src={doc} alt="DOC Icon"></img>;
      case "docx":
        return (
          <img style={{ cursor: "pointer" }} src={docx} alt="DOCX Icon"></img>
        );
      default:
        return (
          <img style={{ cursor: "pointer" }} src={doc} alt="DOC Icon"></img>
        );
    }
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            padding: "8px",
            fontWeight: 600,
            width: "100%",
            textAlign: "start",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            zIndex: "0",
          }}
          src={calendarIcon}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const releaseMutation = useMutation(releaseOffer, {
    onSuccess: () => {
      setAcceptForm(null);
      setIsLoadingApprov(false);
      prevData?.page1 === "myCandidateFinalStages"
        ? setPrevData({
            ...prevData,
            page1: "myCandidateFinalStages",
            page2: "CandidateOfferScreen",
            page3: null,
            page4: null,
            active: "CandidateOfferScreen",
          })
        : setPrevData({
            ...prevData,
            page1: "candidateList",
            page2: "ViewCandidate",
            page3: "CandidateOfferScreen",
            page4: null,
            active: "CandidateOfferScreen",
          });
      setNextScreen("CandidateOfferScreen");
    },
    onError: () => {
      setIsLoadingApprov(false);
    },
  });
  const handleRealesDate = () => {
    if (releseDate != null) {
      releaseMutation.mutate({
        recruiterId: employeeId,
        offerId: prevData?.offerId,
        offerReleaseDate: releseDate,
      });
      setIsLoadingApprov(true);
      setOfferReleModal(false);
      setValidate(false);
    } else {
      setValidate(true);
    }
  };

  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
      default:
        return null;
    }
  };

  const revokeMutation = useMutation(revokeOffer, {
    onSuccess: (res) => {
      if (res?.data?.responseStatus?.statusCode === 1) {
        refetchOfferData();
        setOfferRevokedModal(false);
        setOfferRevokedForm(null);
        notifySuccess("Offer revoked successfully");
      } else {
        notifyError("Something went wrong");
      }
    },
    onError: (err) => {
      notifyError(err);
    },
  });

  const cancelMutation = useMutation(cancelOffer, {
    onSuccess: (res) => {
      if (res?.data?.responseStatus?.statusCode === 1) {
        refetchOfferData();
        setOfferCancelModal(false);
        notifySuccess(res?.data?.responseData);
      } else {
        notifyError("Something went wrong");
      }
    },
    onError: (err) => {
      notifyError(err);
    },
  });

  const revokeStatus = () => {
    if (!offerRevokedForm?.note || !offerRevokedForm?.cancelRevokeDate) {
      setValidate(true);
      return;
    }
    setValidate(false);
    revokeMutation.mutate({
      offerId: offerData?.offerId,
      empId: employeeId,
      cancelRevokeDate: moment(new Date(offerRevokedForm?.cancelRevokeDate))
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      offerStatus: "Offer Revoked",
      note: offerRevokedForm?.note,
    });
  };

  const changeOfferStatusToCancel = () => {
    cancelMutation.mutate({
      offerId: offerData?.offerId,
      empId: employeeId,
      cancelRevokeDate: moment(new Date())
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      offerStatus: "Offer Cancel",
    });
  };

  return (
    <>
      <ToastContainer />

      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      ></BreadCrum>
      <div className={style.offerContainer}>
        <div className={style.card}>
          <div className={style.th11}>
            <p>Candidate Details</p>
            <hr />
          </div>
          <div className={style.Cu_row1}>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Name</p>
              <p className={style.cu_field2}>{candidateData?.name}</p>
            </div>
            <div className={style.cu_col2}>
              <p className={style.cu_label}>Email Id</p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <CustomTooltip
                  value={candidateData?.email}
                  styles={{
                    padding: "7px 5px 10px 4px",
                    marginTop: "10px",
                    color: "#666",
                  }}
                  limit={33}
                  index={"email"}
                />{" "}
                
              </p>
            </div>
            <div className={style.cu_col3}>
              <p className={style.cu_label}>Technology</p>
              <CustomTooltip
                value={candidateData?.coreSkill}
                styles={{
                  padding: "7px 5px 10px 4px",
                  marginTop: "10px",
                  color: "#666",
                }}
                limit={30}
                index={"email"}
              />
            </div>
            <div className={style.cu_col4}>
              <p className={style.cu_label}>Last Company Name</p>
              <p className={style.cu_field2}>{candidateData?.companyName}</p>
            </div>
          </div>
        </div>
        {/* ViewData */}

        <div className={style.card}>
          <div>
            <div className={style.th11}>
              <p>View Offer</p>
              <hr />
            </div>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Offer Number</p>
                <p className={style.cu_field4}>{offerData?.offerNumber}</p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Release Date</p>
                <p className={style.cu_field4}>
                  {offerData?.offerReleaseDate
                    ? moment(offerData?.offerReleaseDate).format("DD MMM YY")
                    : "-"}
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Offered CTC</p>
                <p className={style.cu_field4}>{offerData?.offeredCtc}</p>
              </div>
              <div className={style.cu_col4}>
                <p className={style.cu_label}>Bond</p>
                <p className={style.cu_field4}>{`${
                  offerData?.bond === true ? "Yes" : "No"
                }`}</p>
              </div>
              <div className={style.cu_col5}>
                <p className={style.cu_label}>Offer Document</p>
                <p className={style.cu_field4}>
                  <a href={offerData?.offerUrl} target="_blank">
                    <p
                      style={{
                        color: "limegreen",
                        textDecoration: "underline",
                        width: "3rem",
                      }}
                    >
                      {" "}
                      {getContentForResume(resumeName)}
                    </p>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Prepared By</p>
                <p className={style.cu_field4}>{offerData?.preparedBy}</p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Reviewed By</p>
                <p className={style.cu_field4}>
                  {offerData?.reviewedBy ? offerData?.reviewedBy : "_"}
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Status</p>
                <p className={style.cu_field4}>{offerData?.status?.status}</p>
              </div>
              <div className={style.cu_col4}>
                <p className={style.cu_label}>Expected DOJ</p>
                <p className={style.cu_field4}>
                  {offerData?.expectedJoiningDate &&
                    moment(offerData?.expectedJoiningDate).format("DD MMM YY")}
                </p>
              </div>
              <div className={style.cu_col5}>
                <p className={style.cu_label}>Employment Type</p>
                <p className={style.cu_field4}>{offerData?.employmentType}</p>
              </div>
            </div>
          </div>

          <div>
            <div className={style.Cu_row1} style={{ justifyContent: "normal" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Offer Decline/ <br />
                  Accepted Date
                </p>
                <p className={style.cu_field4}>
                  {offerData?.acceptDeclineDate
                    ? moment(offerData?.acceptDeclineDate).format("DD MMM YY")
                    : "-"}
                </p>
              </div>
              {offerData?.offerDeclineAcceptedDocument && (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Offer Declined/ <br />
                    Accepted Document
                  </p>
                  <p className={style.cu_field4}>
                    <a
                      href={offerData?.offerDeclineAcceptedDocument}
                      target="_blank"
                    >
                      <p
                        style={{
                          color: "limegreen",
                          textDecoration: "underline",
                          width: "3rem",
                        }}
                      >
                        {" "}
                        {getContentForResume(
                          offerData?.offerDeclineAcceptedDocument
                        )}
                      </p>
                    </a>
                  </p>
                </div>
              )}
              {offerData?.status?.status === "Offer Revoked" && (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Offer Revoked Date</p>
                  <p className={style.cu_field4}>
                    {offerData?.cancelRevokeDate &&
                      moment(offerData?.cancelRevokeDate).format("DD MMM YY")}
                  </p>
                </div>
              )}
              {offerData?.status?.status === "Offer Cancel" && (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Offer Cancelled Date</p>
                  <p className={style.cu_field4}>
                    {offerData?.cancelRevokeDate &&
                      moment(offerData?.cancelRevokeDate).format("DD MMM YY")}
                  </p>
                </div>
              )}
              {offerData?.status?.status === "Offer Revoked" &&
                offerData?.revokeNote && (
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Offer Revoked Note</p>
                    <p className={style.cu_field4}>
                      {offerData?.revokeNote?.length > 17 ? (
                        <span
                          className={style.noteText}
                          onClick={() => {
                            setOpenNote(true);
                            setCurrNote(offerData?.revokeNote);
                          }}
                        >
                          {offerData?.revokeNote?.substring(0, 17) + "..."}
                        </span>
                      ) : (
                        offerData?.revokeNote
                      )}
                    </p>
                  </div>
                )}
              {offerData?.status?.status === "Offer Declined" &&
                offerData?.acceptDeclineNote && (
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Offer Declined Note</p>
                    <p className={style.cu_field4}>
                      {offerData?.acceptDeclineNote?.length > 17 ? (
                        <span
                          className={style.noteText}
                          onClick={() => {
                            setOpenNote(true);
                            setCurrNote(offerData?.acceptDeclineNote);
                          }}
                        >
                          {offerData?.acceptDeclineNote?.substring(0, 17) +
                            "..."}
                        </span>
                      ) : (
                        offerData?.acceptDeclineNote
                      )}
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
        {isLoadingApprov ? <Loader /> : null}

        <div className={style.buttonContainer}>
          {offerData?.status?.status === "Offer Prepared" ? (
            <>
              {ValidationEmpAccess(validateAccess, accessCode?.offer_edit) && (
                <button
                  className={style.btn}
                  onClick={() => {
                    setNextScreen("EditOffer");
                    prevData?.page1 == "myCandidateFinalStages"
                      ? setPrevData({
                          ...prevData,
                          // candidateId: candidateData?.id,
                          page1: "myCandidateFinalStages",
                          page2: "CandidateOfferScreen",
                          page3: "viewOffer",
                          page4: "EditOffer",
                          active: "EditOffer",
                        })
                      : setPrevData({
                          ...prevData,
                          page1: "candidateList",
                          page2: "ViewCandidate",
                          page3: "CandidateOfferScreen",
                          page4: "viewOffer",
                          page5: "EditOffer",
                          active: "EditOffer",
                        });
                  }}
                >
                  Edit
                </button>
              )}
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_approve
              ) && (
                <button
                  className={style.btn}
                  onClick={() => {
                    setApproveModal(true);
                  }}
                >
                  Approved
                </button>
              )}
            </>
          ) : null}

          {/* offer Approved done1 */}
          {offerData?.status?.status === "Offer Approved" ? (
            <>
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_cancel
              ) && (
                <button
                  className={style.btn_dicline}
                  onClick={() => setOfferCancelModal(true)}
                >
                  Offer Cancel
                </button>
              )}
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_Mark_as_Offer_Released
              ) && (
                <button
                  className={style.btn}
                  onClick={() => {
                    setOfferReleModal(true);
                  }}
                  disabled={disabled}
                >
                  Mark as Offer Released
                </button>
              )}
            </>
          ) : null}

          {/* offer Released done1  */}
          {offerData?.status?.status === "Offer Released" ? (
            <>
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_revoke
              ) && (
                <button
                  className={style.btn_dicline}
                  onClick={() => setOfferRevokedModal(true)}
                >
                  Offer Revoke
                </button>
              )}
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_offer_decline
              ) && (
                <button
                  className={style.btn_dicline}
                  onClick={() => setOfferDeclModal(true)}
                >
                  Offer Decline
                </button>
              )}
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_Offer_Accept
              ) && (
                <button
                  className={style.btn}
                  onClick={() => setOfferAcceptModal(true)}
                >
                  Offer Accept
                </button>
              )}
            </>
          ) : null}

          {/* offer Accept done */}
          {offerData?.status?.status === "Offer Accepted" ? (
            <>
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_revoke
              ) && (
                <button
                  className={style.btn_dicline}
                  onClick={() => setOfferRevokedModal(true)}
                >
                  Offer Revoke
                </button>
              )}
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_offer_decline
              ) && (
                <button
                  className={style.btn_dicline}
                  onClick={() => setOfferDeclModal(true)}
                >
                  Offer Decline
                </button>
              )}
            </>
          ) : null}

          {/* offer Decline done */}
          {offerData?.status?.status === "Offer Declined" ? (
            <>
              {ValidationEmpAccess(
                validateAccess,
                accessCode?.offer_Offer_Accept
              ) && (
                <button
                  className={style.btn}
                  onClick={() => setOfferAcceptModal(true)}
                >
                  Offer Accept
                </button>
              )}
            </>
          ) : null}
        </div>
      </div>

      {/* offer Decline */}
      <DeclinePopup open={offerDeclModal}>
        <div className={style.confirmPopup1}>
          <h5 className={style.confirmPopupTitle1}>Offer Decline</h5>
          <div className={style.inner_box}>
            <div className={style.date1}>
              <p style={{ color: "#666" }}>Offer Decline Date</p>
            </div>
            <div className={style.csd1} style={{ marginLeft: "30px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                // minDate={new Date()}
                selected={
                  declineForm?.acceptDeclineDate
                    ? moment(declineForm?.acceptDeclineDate).toDate()
                    : null
                }
                onChange={(date) => {
                  setDeclineForm({
                    ...declineForm,
                    acceptDeclineDate: moment(date).format(),
                  });
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
          </div>
          <div className={style.note}>
            <p>Notes</p>
            <span style={{ color: "red" }}>*</span>
          </div>
          <div className={style.text_area}>
            <textarea
              className={style.text_box}
              onChange={(e) =>
                setDeclineForm({ ...declineForm, note: e.target.value })
              }
            />
          </div>
          {
            <p
              className={
                validate && !declineForm?.note
                  ? style.errorText
                  : style.errorTextHidden
              }
              style={{ paddingTop: "0.1rem", marginLeft: "0px" }}
            >
              Please Enter Note
            </p>
          }

          <div className={style.docOffer}>
            <p>Upload Offer Document</p>
          </div>
          <div className={style.docOffer1}>
            {offerDoc ? (
              <div className={style.Cu_resume}>
                {/*                   {/* <img src={pdfIcon} alt="pdf" /> */}
                <div className={style.rmpdf}>
                  {checkfileType(offerDoc.split("@")[1]?.split(".")[1])}&nbsp;
                  <span>{offerDoc.split("@")[1]}</span>
                </div>
                <p
                  onClick={() => {
                    setDeclineForm({ ...declineForm, docUrl: "" });
                    setOfferDoc(null);
                    deleteResume(declineForm?.docUrl);
                  }}
                  className={style.rmpdfcross}
                >
                  x
                </p>
              </div>
            ) : (
              <input
                placeholder={`${
                  declineForm?.docUrl ? declineForm?.docUrl : "no file selected"
                }`}
                type="file"
                className={style.upload_resume}
                onChange={(e) => {
                  uploadDocMutation.mutate(e.target.files[0]);
                  // setOfferDoc(e.target.files);
                }}
              />
            )}
          </div>
          <div className={style.docOffer} style={{ width: "33%" }}>
            <p>Expected DOJ</p>
          </div>
          <div className={style.csd1} style={{ marginLeft: "30px" }}>
            <DatePicker
              dateFormat="dd MMM yy"
              selected={
                declineForm?.expectedJoiningDate
                  ? moment(declineForm?.expectedJoiningDate).toDate()
                  : null
              }
              onChange={(date) => {
                setDeclineForm({
                  ...declineForm,
                  expectedJoiningDate: moment(date).format(),
                });
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              defaultValue={null}
              customInput={<CustomInputClg />}
              style={{ backgroundColor: "#F5F6FA" }}
            />
          </div>
          <div className={style.button_btn1}>
            <button
              className={style.noBtn1}
              onClick={() => {
                setOfferDeclModal(false);
                setDeclineForm(null);
              }}
            >
              Cancel
            </button>
            <button className={style.yesBtn2} onClick={declineFormHandler}>
              OK
            </button>
          </div>
        </div>
      </DeclinePopup>

      {/* New Offer Revoke Modal */}
      <RevokePopUp open={offerRevokedModal}>
        <div className={style.confirmPopup1} style={{ minHeight: "unset" }}>
          <h5 className={style.confirmPopupTitle1}>Offer Revoke</h5>
          <div className={style.inner_box}>
            <div className={style.date1}>
              <p style={{ color: "#666" }}>Offer Revoke Date</p>
              <span style={{ color: "red" }}>*</span>
            </div>
            <div className={style.csd1} style={{ marginLeft: "30px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                selected={
                  offerRevokedForm?.cancelRevokeDate
                    ? moment(offerRevokedForm?.cancelRevokeDate).toDate()
                    : null
                }
                onChange={(date) => {
                  setOfferRevokedForm({
                    ...offerRevokedForm,
                    cancelRevokeDate: moment(date).format(),
                  });
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
            {
              <p
                className={
                  validate && !offerRevokedForm?.cancelRevokeDate
                    ? style.errorText
                    : style.errorTextHidden
                }
                style={{ paddingTop: "0.1rem", marginLeft: "0px" }}
              >
                Please Enter Offer Revoked Date
              </p>
            }
          </div>
          <div className={style.note}>
            <p>Notes</p>
            <span style={{ color: "red" }}>*</span>
          </div>
          <div className={style.text_area}>
            <textarea
              className={style.text_box}
              onChange={(e) =>
                setOfferRevokedForm({
                  ...offerRevokedForm,
                  note: e.target.value,
                })
              }
            />
          </div>
          {
            <p
              className={
                validate && !offerRevokedForm?.note
                  ? style.errorText
                  : style.errorTextHidden
              }
              style={{ paddingTop: "0.1rem", marginLeft: "0px" }}
            >
              Please Enter Note
            </p>
          }
          <div className={style.button_btn1}>
            <button
              className={style.noBtn1}
              onClick={() => {
                setOfferRevokedModal(false);
                setOfferRevokedForm({});
                setValidate(false);
              }}
            >
              Cancel
            </button>
            <button className={style.yesBtn2} onClick={revokeStatus}>
              OK
            </button>
          </div>
        </div>
      </RevokePopUp>

      {/* offer Accept */}
      <AcceptPopup open={offerAcceptModal}>
        <div
          className={style.confirmPopup}
          style={{ height: "auto", paddingBottom: "6px" }}
        >
          <h5 className={style.confirmPopupTitle1}>Offer Accepted Date </h5>
          <div className={style.inner_box}>
            <div className={style.date} style={{ width: "27%" }}>
              <p style={{ color: "#666" }}>
                Date <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <div className={style.csd} style={{ marginLeft: "60px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                selected={
                  acceptForm?.acceptDeclineDate
                    ? moment(acceptForm?.acceptDeclineDate).toDate()
                    : null
                }
                onChange={(date) => {
                  setAcceptForm({
                    ...acceptForm,
                    acceptDeclineDate: moment(date).format(),
                  });
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
          </div>
          <p
            className={
              validate &&
              (!acceptForm?.acceptDeclineDate ||
                (offerData?.offerReleaseDate &&
                  acceptForm?.acceptDeclineDate &&
                  new Date(acceptForm?.acceptDeclineDate).getTime() <
                    new Date(offerData?.offerReleaseDate).getTime()))
                ? style.errorText1
                : style.errorTextHidden
            }
            style={{ textAlign: "left" }}
          >
            {!acceptForm?.acceptDeclineDate ? (
              "Please Select Offer Accept Date"
            ) : (
              <>
                Offer Accept Date must exceed Offer <br /> Release Date.
              </>
            )}
          </p>
          {/* <p
            className={
              validate && 
                ? style.errorText1
                : style.errorTextHidden
            }
            style={{textAlign: "left"}}
          >
            Offer Accept Date must exceed Offer <br/> Release Date.
          </p> */}

          <div className={style.date} style={{ width: "41.8%" }}>
            <p style={{ color: "#666" }}>Expected DOJ</p>
          </div>
          <div className={style.csd} style={{ marginLeft: "60px" }}>
            <DatePicker
              dateFormat="dd MMM yy"
              selected={
                acceptForm?.expectedJoiningDate
                  ? moment(acceptForm?.expectedJoiningDate).toDate()
                  : null
              }
              onChange={(date) => {
                setAcceptForm({
                  ...acceptForm,
                  expectedJoiningDate: moment(date).format(),
                });
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              defaultValue={null}
              customInput={<CustomInputClg />}
              style={{ backgroundColor: "#F5F6FA" }}
            />
          </div>

          <div className={style.button_btn} style={{ marginTop: "1.5rem" }}>
            <button
              className={style.noBtn}
              onClick={() => {
                setOfferAcceptModal(false);
                setValidate(false);
                setAcceptForm({
                  ...acceptForm,
                  acceptDeclineDate: null,
                });
              }}
            >
              Cancel
            </button>
            <button className={style.yesBtn} onClick={acceptOfferHandler}>
              OK
            </button>
          </div>
        </div>
      </AcceptPopup>

      {/* Approval modal */}
      <Modal
        open={approveModal}
        center
        onClose={() => setApproveModal(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container2}>
          <div>
            <p className={style.textM}>
              Are you sure you want to approve this offer?
            </p>
          </div>
          <div className={style.M_button1}>
            <div className={style.button_btn2}>
              <button
                className={style.noBtn}
                onClick={() => setApproveModal(false)}
              >
                No
              </button>
              <button
                className={style.yes1Btn}
                onClick={() => {
                  approvedStatus();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Offer Cancel Model */}
      <Modal
        open={offerCancelModal}
        center
        onClose={() => setOfferCancelModal(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container2}>
          <div>
            <p className={style.textM}>
              Are you sure you want to Cancel this offer?
            </p>
          </div>
          <div className={style.M_button1}>
            <div className={style.button_btn2}>
              <button
                className={style.noBtn}
                onClick={() => setOfferCancelModal(false)}
              >
                No
              </button>
              <button
                className={style.yes1Btn}
                onClick={() => changeOfferStatusToCancel()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ReleasePopup open={offerReleModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle1}>Offer Release Date </h5>
          <div className={style.inner_box}>
            <div className={style.date}>
              <p style={{ color: "#666" }}>
                Date <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <div className={style.csd} style={{ marginLeft: "45px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                selected={releseDate ? moment(releseDate).toDate() : null}
                onChange={(e) => setRelesDate(e)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
          </div>
          <p
            className={
              validate && !releseDate ? style.errorText1 : style.errorTextHidden
            }
          >
            Please Select Offer Release Date
          </p>

          <div className={style.button_btn}>
            <button
              className={style.noBtn}
              onClick={() => {
                setOfferReleModal(false);
                setValidate(false);
                setRelesDate(null);
              }}
            >
              Cancel
            </button>
            <button
              className={style.yesBtn}
              onClick={() => {
                handleRealesDate();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </ReleasePopup>

      {/* Error modal */}
      <Modal
        open={errorModal}
        center
        onClose={() => setErrorModal(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container2}>
          <div>
            <p className={style.textM}>
              Are you sure you want to Release this offer?
            </p>
          </div>
          <div className={style.M_button1}>
            <div className={style.button_btn2}>
              <button
                className={style.yesBtn}
                onClick={() => setErrorModal(false)}
              >
                okay
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={errorModal2}
        center
        onClose={() => setErrorModal2(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container2}>
          <div>
            <p className={style.textM}>
              You are not the recruiter of this candidate
            </p>
          </div>
          <div className={style.M_button1}>
            <div className={style.button_btn2}>
              <button
                className={style.yesBtn}
                onClick={() => setErrorModal2(false)}
              >
                okay
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openNote}
        center
        onClose={() => setOpenNote(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container2}>
          <div>
            <p className={style.textM}>{currNote}</p>
          </div>
          <div className={style.M_button1}>
            <div className={style.button_btn2}>
              <button
                className={style.yesBtn}
                onClick={() => setOpenNote(false)}
              >
                okay
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewOffer;
