import * as XLSX from "xlsx";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { getDashboardList } from "../../utils/api";
import moment from "moment";
import { useQuery } from "react-query";

const ReviewFileDownload = (filter) => {
  const { refetch } = useQuery(
    ["getDashboardList"],
    () => getDashboardList(filter.filter.empId, filter.filter.leadId, filter.filter.limit, filter.filter.page, filter.filter.year, filter.filter.functionalArea, filter.filter.employeeType),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        handleDownloadButtonClick(res?.response);
      },
    }
  );

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Code",
        "Name",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ], // Header row
      // ...data.map((val) => [
      //   val.empCode,
      //   // moment(val.reviewDate).format("DD MMM YY"),
      //   // moment(val.reviewDate).format("MMM"),
      //   val.empName,
      // ]), // Data rows
    ];

    const rows = data.map((val) => {
      const row = [
          val.empCode || "",
          val.empName || "",
      ];

      const ratings = new Array(12).fill(""); // Default empty value for all months

      val.monthWishResponse.forEach((response) => {
          const monthIndex = response.month - 1;
          ratings[monthIndex] = response.rating;
      });

      return row.concat(ratings);
  });

  sheetData.push(...rows);


    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);

    downloadExcelFile(workbook);
  }

  return (
    <div>
      <FiDownload
        style={{ verticalAlign: "baseline" }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default ReviewFileDownload;
