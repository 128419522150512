import React, { useEffect, useState } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import style from "./duplicity.module.scss";
import { AiOutlineClose } from "react-icons/ai";
import searchIcon from "../../../../assets/Icon-search.png";
import ATSContainer from "./../../customTooltip/ATSContainer";
import { useQuery } from "react-query";
import { getCandidatesByEmailAndMobile } from "../../../../Utils/axios/api";
import moment from "moment";
import CopyEmail from "../../../../GenericComponent/CopyEmailFeature/CopyEmail";

const DuplicityCheck = ({ setNextScreen, prevData, setPrevData }) => {
  const [filter, setFilter] = useState(
    prevData?.DCFilter ? prevData?.DCFilter : { email: "", number: "" }
  );
  useEffect(() => {
    setPrevData((prev) => ({
      ...prev,
      DCFilter: filter,
    }));
  }, [filter]);
  const { data, isLoading, isError } = useQuery(
    ["getCandidatesByEmailAndMobile", filter],
    () => getCandidatesByEmailAndMobile(filter?.email, filter?.number)
  );
  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
        // PlusComponent={}
      >
        <div className={style.topBar}>
          <div className={style.s1}>
            {/* 1 */}
            {filter?.email === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt={"searchIcon"} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilter((prev) => ({ ...prev, email: "" }));
                }}
              >
                <AiOutlineClose />
              </span>
            )}
            <input
              className={style.coreSkillInput}
              placeholder="Email"
              value={filter?.email}
              onChange={(e) => {
                setFilter((prev) => ({ ...prev, email: e?.target?.value }));
              }}
            />
          </div>
          <div className={style.s1}>
            {/* 1 */}
            {filter?.number === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt={"searchIcon"} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilter((prev) => ({ ...prev, number: "" }));
                }}
              >
                <AiOutlineClose />
              </span>
            )}
            <input
              className={style.coreSkillInput}
              type="number"
              placeholder="Mobile Number"
              value={filter?.number}
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  number: e?.target?.value,
                }));
              }}
            />
          </div>
        </div>
      </BreadCrum>
      {isLoading && <div className={style.noData}>Loading...</div>}
      {isError && <div className={style.noData}>Something went wrong</div>}

      {!isLoading && !isError && (filter?.email || filter?.number) && (
        <ATSContainer>
          <div className={style.th1}>
            <div>Name</div>
            <div>Email Id</div>
            <div>Contact Number</div>
            <div>Recruiter</div>
            <div> Lead Name</div>
            <div> Sourced Date</div>
            <div>Dropped Reason</div>
          </div>
          {!isLoading && !isError ? (
            data?.length > 0 ? (
              data?.map((item) => {
                return (
                  <div className={style.td1}>
                    <div
                      onClick={() => {
                        setNextScreen("ViewCandidate");
                        setPrevData((prevData) => ({
                          ...prevData,
                          candidateId: item?.candidateId,
                          page1: "duplicityCheck",
                          page2: "ViewCandidate",
                          page3: null,
                          page4: null,
                          active: "ViewCandidate",
                        }));
                      }}
                    >
                      {item?.candidateName}
                    </div>
                    <div style={{display: "flex"}}><span style={{fontSize: "var(--atsFontSize)", fontWeight: "400", width: "9rem", textOverflow: "ellipsis", overflow: "hidden"}}>{item?.email}</span> <span><CopyEmail email={item?.email} /></span></div>
                    <div>{item?.number}</div>
                    <div>{item?.recruiterName}</div>
                    <div> {item?.leadName}</div>
                    <div> {moment(item?.sourceDate).format("DD MMM YY")}</div>
                    <div>{item?.droppedReason}</div>
                  </div>
                );
              })
            ) : (
              <div className={style.noData}>No Data</div>
            )
          ) : null}
        </ATSContainer>
      )}
    </>
  );
};

export default DuplicityCheck;
