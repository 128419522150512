import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../CreatePartner/CreatePartner.module.css";
import { useMutation, useQuery } from "react-query";
import {
  createPartner,
  getPartnerTypeList,
  removeLead,
} from "../../../../Utils/axios/PortalManagement";
import Select from "react-select";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { InvoiceStyles4, InvoiceStyles5 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import { IoIosAddCircle } from "react-icons/io";
import { urlValidation } from "../../../atsDashboard/utils/validation";
import { ToastifyError } from "../../../../App";
import "react-toastify/dist/ReactToastify.css";

import {
  getCityList,
  getStateList,
  addViewCity,
  getWorkModeList,
} from "../../../../Utils/axios/PortalManagement";
import { employeeId } from "../../../../Utils/constants";
import { notifySuccess } from "../../../atsDashboard/utils/notify";
const CreatePartner = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPag,
}) => {
  console.log("prevData", prevData);

  const [formData, setFormData] = useState({
    partnerName: "",
    gstin: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    cityId: "",
    stateId: "",
    pinCode: "",
    companySize: "",
    linkedinLink: "",
    companyLink: "",
    msafileUrl: "",
    ndafileUrl: "",
    workModeId: "",
    partnerTypeId: "",
    primaryContactName: "",
    primaryContectEmail: "",
    primaryMobileNumber: "",
    seconderyContactName: "",
    seconderyContectEmail: "",
    seconderyMobileNumber: "",
    paymentDueDays: "",
    paymentDueDaysAsPrPartnerRequest: "",
    empId: Number(employeeId),
  });

  useEffect(() => {
    setFormData({
      ...formData,
      partnerName: prevData.item.partnerName,
      companySize: prevData.item.companySize.toString(),
      linkedinLink: prevData.item.linkedInUrl,
    });
  }, []);

  const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
  const mutation = useMutation(createPartner, {
    onSuccess: (res) => {
      notifySuccess("Partner Created Successfully")
      removeLead(prevData?.item?.id);
      setPrevData((prevData) => ({
        ...prevData,
        CreateId: res?.id,
      }));

      setNextScreen("ViewCreatePartner");
    },
  });
  const [validate, setValidate] = React.useState(false);
  const [validate1, setValidate1] = React.useState(false);

  const [show, setShow] = useState(false);
  const [AddCity, setAddCity] = useState("");

  const mutationCity = useMutation(addViewCity, {
    onSuccess: (res) => {
      refetch();
    },
  });

  const handleAdd = () => {
    if (AddCity === "") {
      setValidate1(true);
    } else {
      mutationCity.mutate({ id: AddCity });
      setValidate1(false);
      setShow(false);
    }
  };

  const handleAddCancle = () => {
    setShow(false);
  };

  const handleShow = (expenseId) => {
    setShow(true);
  };

  const handleCreatePartner = () => {
    console.log("formData?.partnerName", formData?.partnerName);
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (
      formData?.partnerName.trim() &&
      formData?.companySize.trim() &&
      formData?.addressLine1.trim() &&
      formData?.addressLine2.trim() &&
      formData?.country.trim() &&
      formData?.ndafileUrl &&
      formData?.msafileUrl &&
      formData?.workModeId &&
      formData?.partnerTypeId &&
      formData?.linkedinLink.trim() &&
      linkendInUrl(formData?.linkedinLink) &&
      formData?.companyLink.trim() &&
      linkendInUrl(formData?.companyLink) &&
      formData?.cityId &&
      formData?.stateId &&
      formData?.pinCode.trim() &&
      formData?.primaryContactName &&
      isValidEmail(formData?.primaryContectEmail) &&
      formData?.primaryMobileNumber.trim() &&
      formData.primaryMobileNumber.length >= 10 &&
      (formData?.seconderyContectEmail
        ? isValidEmail(formData?.seconderyContectEmail)
        : true)
    ) {
      setValidate(false);
      mutation.mutate(formData);
    } else {
      setValidate(true);
    }
  };
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  async function handleFileUpload(file) {
    setFormData({ ...formData, ndafileUrl: file.name });
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(baseUrl + "/upload-file", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, ndafileUrl: response.data.responseData });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileUpload1(file) {
    setFormData({ ...formData, msafileUrl: file.name });
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(baseUrl + "/upload-file", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, msafileUrl: response.data.responseData });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileDeletion() {
    // setFormData({...formData, ndafileUrl:""});
    try {
      const response = await axios.put(
        baseUrl + `/delete-file-url?url=${formData.ndafileUrl}`
      );
      if (response.status === 200) {
        setFormData({ ...formData, ndafileUrl: "" });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileDeletion1() {
    try {
      const response = await axios.put(
        baseUrl + `/delete-file-url?url=${formData.msafileUrl}`
      );
      if (response.status === 200) {
        setFormData({ ...formData, msafileUrl: "" });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { data: GetCityTypeData, refetch } = useQuery(
    ["getCityList"],
    getCityList
  );
  GetCityTypeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const GetCityDataOption = GetCityTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: GetStateTypeData } = useQuery(["getStateList"], getStateList);
  GetStateTypeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  const { data: GetWorkModeData } = useQuery(
    ["getWorkModeList"],
    getWorkModeList
  );

  const { data: getPartnerTypeData } = useQuery(
    ["getPartnerTypeList"],
    getPartnerTypeList
  );

  GetWorkModeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const GetStateDataOption = GetStateTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const GetWorkMode = GetWorkModeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const getPartnerType = getPartnerTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const linkendInUrl = (value) => {
    if (!value) {
      return true;
    } else if (urlValidation(value)) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>
              Create Partner
            </h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label}>
                Partner Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData.partnerName}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Partner Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                GSTIN
              </p>
              <input
                type="text"
                maxLength={15}
                minLength={15}
                className={classes.smallInput}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 15) {
                    inputVal = inputVal.slice(0, 15);
                  }
                  setFormData({
                    ...formData,
                    gstin: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
                onClick={handleInputClick}
              />
              <p
                className={
                  validate && !formData?.gstin.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter GSTIN
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Company Size
                <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData.companySize}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companySize: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
                onClick={handleInputClick}
              />
              <p
                className={
                  validate && !formData?.companySize.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Company Size
              </p>
            </Col>

            <Col md={4}>
              <p className={classes.label}>
                Address 1 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine1: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine1.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 1
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Address 2 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine2: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine2.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 2
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Payment Due Days (As per Agreement)
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData?.paymentDueDays}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    paymentDueDays: e.target.value
                      ? /^\d+$/.test(e.target.value)
                        ? parseInt(e.target.value)
                        : ""
                      : "",
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Payment Due Days (As per Partner Requested)
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData?.paymentDueDaysAsPrPartnerRequest}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    paymentDueDaysAsPrPartnerRequest: e.target.value
                      ? /^\d+$/.test(e.target.value)
                        ? parseInt(e.target.value)
                        : ""
                      : "",
                  });
                }}
              />
            </Col>
            <Col md={3}>
              <p className={classes.label1}>
                City <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetCityDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cityId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.cityId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter City
              </p>
            </Col>
            <Col md={1}>
              <IoIosAddCircle
                className={classes.remove_icon}
                size={30}
                onClick={() => {
                  handleShow();
                }}
              />
            </Col>
            <Col md={3}>
              <p className={classes.label1}>
                State <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetStateDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    stateId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.stateId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select State
              </p>
            </Col>
            <Col md={2}>
              <p className={classes.label1}>
                Pincode <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData?.pinCode}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pinCode:
                      e.target.value && /^\d{1,6}$/.test(e.target.value)
                        ? e.target.value
                        : "",
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.pinCode.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter pincode
              </p>
            </Col>
            <Col md={2}>
              <p className={classes.label1}>
                Country <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    country: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.country.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Country
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                LinkedIn Url <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData.linkedinLink}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    linkedinLink: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (!formData?.linkedinLink.trim() ||
                    !linkendInUrl(formData?.linkedinLink))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter LinkedIn Url
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Upload MSA <span style={{ color: "red" }}>*</span>
              </p>
              <div className={classes.chooseFile}>
                <div>
                  {formData?.msafileUrl ? (
                    <div
                      className={classes.rmpdf}
                      style={{ paddingTop: "3px" }}
                    >
                      {
                        <>
                          {checkfileType(formData?.msafileUrl?.split(".")[1])}
                          &nbsp;
                          <span>
                            {formData?.msafileUrl &&
                            formData?.msafileUrl.length > 25
                              ? `${formData?.msafileUrl
                                  ?.split("@")[1]
                                  ?.substring(0, 25)}...`
                              : formData?.msafileUrl}
                          </span>
                        </>
                      }
                      <ClearIcon
                        onClick={handleFileDeletion1}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <input
                      id="file-upload"
                      accept="image/*, .pdf, .doc, .docx"
                      type="file"
                      className={classes.upload_resume}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const fileSize = file.size / 1024 / 1024; // File size in MB
                          if (fileSize <= 10) {
                            handleFileUpload1(file);
                          } else {
                            ToastifyError(
                              "File size exceeds the limit of 10MB."
                            );
                            e.target.value = null; // Reset the file input value
                            return;
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <p
                className={
                  validate && !formData?.msafileUrl
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Upload MSA
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Upload NDA <span style={{ color: "red" }}>*</span>
              </p>
              <div className={classes.chooseFile}>
                <div>
                  {formData?.ndafileUrl ? (
                    <div
                      className={classes.rmpdf}
                      style={{ paddingTop: "3px" }}
                    >
                      {
                        <>
                          {checkfileType(formData?.ndafileUrl?.split(".")[1])}
                          &nbsp;
                          <span>
                            {formData?.ndafileUrl &&
                            formData?.ndafileUrl.length > 25
                              ? `${formData?.ndafileUrl
                                  ?.split("@")[1]
                                  ?.substring(0, 25)}...`
                              : formData?.ndafileUrl}
                          </span>
                        </>
                      }
                      <ClearIcon
                        onClick={handleFileDeletion}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <input
                      id="file-upload"
                      accept="image/*, .pdf, .doc, .docx"
                      type="file"
                      className={classes.upload_resume}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const fileSize = file.size / 1024 / 1024; // File size in MB
                          if (fileSize <= 10) {
                            handleFileUpload(file);
                          } else {
                            ToastifyError(
                              "File size exceeds the limit of 10MB."
                            );
                            e.target.value = null; // Reset the file input value
                            return;
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <p
                className={
                  validate && !formData?.ndafileUrl
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Upload NDA
              </p>
            </Col>

            <Col md={4}>
              <p className={classes.label}>
                Company Url <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companyLink: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (!formData?.companyLink.trim() ||
                    !linkendInUrl(formData?.companyLink))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Company Url
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p className={classes.label1}>
                Work Mode <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetWorkMode}
                styles={InvoiceStyles5}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    workModeId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.workModeId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Work Mode
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Partner Type <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={getPartnerType}
                styles={InvoiceStyles5}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerTypeId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerTypeId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Partner Type
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContactName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.primaryContactName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Email ID <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContectEmail: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (!formData?.primaryContectEmail ||
                    !isValidEmail(formData?.primaryContectEmail))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {validate &&
                (!formData?.primaryContectEmail ||
                  !isValidEmail(formData?.primaryContectEmail))
                  ? "Please Enter a Valid Email"
                  : "Please Enter Name"}
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="number"
                className={classes.smallInput}
                maxLength={10}
                minLength={10}
                value={formData?.primaryMobileNumber}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  setFormData({
                    ...formData,
                    primaryMobileNumber: inputVal,
                  });
                }}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
              <p
                className={
                  validate &&
                  (!formData?.primaryMobileNumber ||
                    formData.primaryMobileNumber.length < 10)
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {formData?.primaryMobileNumber &&
                formData.primaryMobileNumber.length < 10
                  ? "Mobile number should have at least 10 digits"
                  : "Please Enter Mobile Number"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.SecondaryContact}>Secondary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Name</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContactName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Email ID</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContectEmail: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  formData?.seconderyContectEmail &&
                  !isValidEmail(formData?.seconderyContectEmail)
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter a Valid Email
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Mobile Number</p>
              <input
                type="number"
                className={classes.smallInput}
                maxLength={10}
                minLength={10}
                value={formData?.seconderyMobileNumber}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  setFormData({
                    ...formData,
                    seconderyMobileNumber: inputVal,
                  });
                }}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>

      {/* <Container>
        <div className={classes.module_heading}>Create Partner</div>

        <div className={classes.container}>
          <p className={classes.container_head}>Partner Details</p>
        </div>
      </Container> */}

      <button className={classes.buttonAdd} onClick={handleCreatePartner}>
        Submit
      </button>

      <style type="text/css">
        {`
      .modal-content {
        width: 80%;
      }
    `}
      </style>
      <Modal
        show={show}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "7px" }}>
          Do you want to add City ?
        </h6>
        <p className={classes.label_item1}>
          City Name <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={classes.smallInput1}
          onChange={(e) => setAddCity(e.target.value)}
        />
        <p
          className={
            validate1 && !AddCity
              ? classes.errorText1
              : classes.errorTextHidden1
          }
        >
          Please Enter City Name
        </p>
        <div className={classes.buttonText}>
          <button className={classes.buttonAdditem} onClick={handleAdd}>
            Yes
          </button>
          <button className={classes.buttonCancle} onClick={handleAddCancle}>
            No
          </button>
        </div>
      </Modal>
    </>
  );
};
export default CreatePartner;
