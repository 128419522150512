import axios from "axios";
import cookie from "react-cookies";
import { employeeId } from "../constants";
import moment from "moment";
import { toast } from 'react-toastify';
import CustomExcelFileDownload from "../../Pages/atsDashboard/commonComponent/commonExcelDownload/CommonExcelDownload";
const apiKey = process.env.REACT_APP_ATS_APP_URL;
const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
const resourceUrl = process.env.REACT_APP_RESOURCEMASTER_APP_URL;
const prUrl = process.env.REACT_APP_EPR_APP_URL;
const url = process.env.REACT_APP_UMS_APP_URL;
let token = cookie.load("token");
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const positionList = async (limit, page, requestData) => {
  try {
    const response = await axios.post(
      `${apiKey}/getAllCreatePositions?limit=${limit}&page=${page}`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.responseData; // response.data already contains the JSON data
  } catch (error) {
    // Handle errors
    console.error("Error fetching position list:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const getAllPosition = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllPosition?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};
export const getCurrentAllocations = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getCurrentAllocations?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};
export const getCurrentAllocationsNonClient = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getCurrentAllocationsNonClient?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const getCandidateAllocations = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getCandidateAllocations?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const createPosition = async (data) => {
  const response = await axios.post(`${apiKey}/createPosition`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};
export const deallocateEmployeeOnPosition = async (data) => {
  const response = await axios.post(
    `${apiKey}/deallocateEmployeeOnPosition?allocationId=${data}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const allocateEmployeeOnPosition = async (data) => {
  const response = await axios.post(
    `${apiKey}/allocateEmployeeOnPosition`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: employeeId,
      },
    }
  );
  return response.data.responseData;
};

export const getCandidateRoles = async () => {
  const response = await axios.get(
    `${apiKey}/getAllCandidateRoles
    `
  );
  return response.data.responseData;
};

export const getAllEduction = async () => {
  const response = await axios.get(
    `${apiKey}/getAllEduction
    `
  );
  return response.data.responseData;
};

// export const getAllPreferredSkill = async () => {
//   const response = await axios.get(
//     `${apiKey}/getSkills
//     `
//   );
//   return response.data.responseData;
// };

// export const getMandatorySkillByCoreSkillId = async () => {
//   const response = await axios.get(
//     `${apiKey}/getSkills
//     `
//   );
//   return response.data.responseData;
// };

export const getSkills = async () => {
  const response = await axios.get(
    `${apiKey}/getSkills
    `
  );
  return response.data.responseData;
};

export const getCoreSkill = async () => {
  const response = await axios.get(
    `${apiKey}/getSkills
    `
  );
  return response.data.responseData;
};
export const getAllCoreSkills = async () => {
  const response = await axios.get(
    `${apiKey}/get-all-core-skills
    `
  );
  return response.data.responseData;
};

export const getInActiveDataList = async () => {
  const response = await axios.get(
    `${apiKey}/get-all-inactive-recruiters
    `
  );
  return response.data.responseData;
};

export const getPositionHistoryDetails = async (limit, page, positionId) => {
  const response = await axios.get(
    `${apiKey}/getPositionHistoryDetails?limit=${limit}&page=${page}&positionId=${positionId}`
  );
  return response.data.responseData;
};
export const getPositionById = async (id) => {
  const response = await axios.get(`${apiKey}/getPositionById?id=${id}`);
  return response.data.responseData;
};

export const getLeadEmployeeList = async (id) => {
  const response = await axios.get(
    `${apiKey}/getLeadEmployeeList?leadId=${id}`
  );
  return response.data.responseData;
};
export const getPositionCreatedBy = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-position-created-by?empId=${id}`
  );
  return response.data.responseData;
};
export const getRecruiterAndLeadsList = async () => {
  const response = await axios.get(`${apiKey}/getRecruiterAndLeadsList`);
  return response.data.responseData;
};

export const getPositionOwnerList = async () => {
  const response = await axios.get(`${apiKey}/getPositionOwnerList`);
  return response.data.responseData;
};

export const getPositionRoleList = async () => {
  const response = await axios.get(`${apiKey}/get-employees-with-hiring-role`);
  return response.data.responseData;
};

export const getPriorityData = async () => {
  const response = await axios.get(`${apiKey}/getAllPriorityList`);
  return response.data.responseData;
};

// export const getPositionById = async (id) => {
//   const response = await axios.get(
//     `${apiKey}/getPositionById?id=${id}`
//   );
//   return response.data.responseData;
// };

export const getAllocations = async (limit, page, positionId, employeeId) => {
  const response = await axios.get(
    `${apiKey}/getAllocations?limit=${limit}&page=${page}&positionId=${positionId}&empId=${employeeId}`
  );
  return response.data.responseData;
};

export const getPositionEmployeeDetails = async () => {
  const response = await axios.get(`${apiKey}/getRecruiterAndLeadsList`);
  return response.data.responseData;
};
export const getAllocationByEmpID = async (empId) => {
  const response = await axios.get(
    `${apiKey}/getAllocationByEmpID?empId=${empId}`
  );
  return response.data.responseData;
};
export const getPastAllocationByPositionId = async (
  limit,
  page,
  positionId,
  empId
) => {
  const response = await axios.get(
    `${apiKey}/getPastAllocationByPositionId?limit=${limit}&page=${page}&positionId=${positionId}&empId=${empId}`
  );
  return response.data.responseData;
};

export const getEditById = async (data) => {
  const response = await axios.post(`${apiKey}/editPosition`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};

export const getPositionCodeForFilter = async (id) => {
  const response = await axios.get(
    `${apiKey}/getPositionCodeForFilter?empId=${employeeId}
    `
  );
  return response.data.responseData;
};
export const getAllPositionCodes = async () => {
  const response = await axios.get(`${apiKey}/get-all-position-codes`);
  return response.data.responseData;
};
// export const getAllocatedPositionCodeForFilter = async () => {
//   const response = await axios.get(
//     `${apiKey}/getAllocatedPositionCodeForFilter?empId=${employeeId}
//     `
//   );
//   return response.data.responseData;
// };

export const getPositionCoreSkillForFilter = async () => {
  const response = await axios.get(
    `${apiKey}/getPositionCoreSkillForFilter
    `
  );
  return response.data.responseData;
};

export const getPositionOwnerForFilter = async () => {
  const response = await axios.get(
    `${apiKey}/getPositionOwnerForFilter
    `
  );
  return response.data.responseData;
};
export const deletePositionByPositionId = async (id) => {
  const response = await axios.delete(
    `${apiKey}/deletePositionByPositionId?id=${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};

export const deleteResume = async (url) => {
  const response = await axios.put(`${apiKey}/deleteResume?url=${url}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};

export const changePositionStatus = async (data) => {
  const response = await axios.post(`${apiKey}/changePositionStatus`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};
export const downloadPositionInformation = async (data) => {
  const response = await axios.post(
    `${apiKey}/downloadPositionInformation`,
    data,
    {
      responseType: "arraybuffer",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    }
  );
  return response;
};

// create candidate
export const createCandidate = async (data) => {
  const response = await axios.post(`${apiKey}/createCandidate`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data;
};

//${apiKey}/uploadResume post api
export const uploadResume = async (data) => {
  let formData = new FormData();
  formData.append("file", data);
  const response = await axios.post(`${apiKey}/uploadResume`, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Authorization: null,
    },
  });
  return response.data.responseData;
};

export const getCandidateById = async (id) => {
  const response = await axios.get(
    `${apiKey}/getCandidateById?candidateId=${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: null,
      },
    }
  );
  return response.data.responseData;
};
export const getAllCandidates = async (limit, page, filter) => {
  console.log("Filter", filter);
  const response = await axios.post(
    `${apiKey}/getAllCandidates?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const getAllNonClientCandidates = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllNonClientCandidates?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const getAllClientCandidates = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllClientCandidates?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const getPoolResource = async (limit, page, filter) => {
  const response = await axios.post(
    `${resourceUrl}/getPoolResource?limit=${limit}&page=${page}`,
    filter,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: null,
      },
    }
  );
  return response.data.responseData;
};
export const getPoolResourcesEmployeeForFilter = async () => {
  const response = await axios.get(
    `${resourceUrl}/get-pool-resources-employee-for-filter`,
    {
      headers: {
        Authorization: null,
      },
    }
  );
  return response.data.responseData;
};
export const deleteCandidateById = async (id) => {
  const response = await axios.delete(
    `${apiKey}/deleteCandidateById?candidateId=${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const getClientSharedEmployeeList = async (page, employeeId) => {
  const response = await axios.get(
    `${apiKey}/get-client-shared-employee-list?limit=10&page=${page}&empId=${employeeId}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const viewCandidateById = async (id, employeeId) => {
  const response = await axios.get(
    `${apiKey}/viewCandidateById?candidateId=${id}&employeeId=${employeeId}`
  );
  return response.data.responseData;
};
export const allocateCandidateForPosition = async (obj) => {
  const response = await axios.post(
    `${apiKey}/allocateCandidateForPosition?candidateId=${obj?.candidateId}&positionId=${obj?.positionID}&recruiterId=${obj.recruiterId}`,

    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const allocateCandidateOnClientPositions = async (obj) => {
  const response = await axios.post(
    `${apiKey}/allocateCandidateOnClientPositions`,
    obj,

    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data;
};
export const getNoticePeriodList = async () => {
  const response = await axios.get(`${apiKey}/getNoticePeriodList`);
  return response.data.responseData;
};
export const getOfferByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getOfferByCandidateId?candidateId=${id}
    `
  );
  return response.data.responseData;
};

export const createOffer = async (data) => {
  const response = await axios.post(`${apiKey}/createCandidateOffer`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};

export const getOfferByOfferId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getOfferByOfferId?offerId=${id}
    `
  );

  return response.data.responseData;
};

export const getPastMonthOffersByRecruiterId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getPastMonthOffersByRecruiterId?recruiterId=${id}`
  );
  return response.data.responseData;
};

export const releaseOffer = async (data) => {
  const response = await axios.post(`${apiKey}/releaseOffer`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};

export const revokeOffer = async (data) => {
  const response = await axios.post(`${apiKey}/cancel-revoke-offer`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response;
};

export const cancelOffer = async (data) => {
  const response = await axios.post(`${apiKey}/cancel-revoke-offer`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response;
};

export const changeOfferStatus = async (data) => {
  const response = await axios.post(
    `${apiKey}/changeOfferStatus
    `,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};

export const getInterviewMode = async () => {
  const response = await axios.get(`${apiKey}/getInterviewMode`);
  return response.data.responseData;
};

export const getInterviewType = async () => {
  const response = await axios.get(`${apiKey}/getInterviewType`);
  return response.data.responseData;
};

export const getDuration = async () => {
  const response = await axios.get(`${apiKey}/getDuration`);
  return response.data.responseData;
};

export const getAllInterviewerName = async (id) => {
  const response = await axios.get(
    `${apiKey}/getAllInterviewerName?type=${id}`
  );
  return response.data.responseData;
};

export const getAllCandidateInterviewList = async (limit, page, data) => {
  const response = await axios.post(
    `${apiKey}/getAllCandidateInterviewList?limit=${limit}&page=${page}`,
    data
  );
  return response.data.responseData;
};

export const getCandidateDetailsByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getCandidateDetailsByCandidateId?candidateId=${id}`
  );
  return response.data.responseData;
};

export const getCandidateInterviewListByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getCandidateInterviewListByCandidateId?candidateId=${id}`
  );
  return response.data.responseData;
};

export const UpdateOffer = async (data) => {
  const response = await axios.post(`${apiKey}/editOffer`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};

export const approveOffer = async (empId, offerId) => {
  const response = await axios.post(
    `${apiKey}/approveOffer?empId=${empId}&offerId=${offerId}`
  );
  return response.data.responseData;
};

export const addAcceptDeclineOffer = async (data) => {
  const response = await axios.post(`${apiKey}/addAcceptDeclineOffer`, data);
  return response.data;
};

export const getAllOffers = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllOffers?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const scheduleInterview = async (data) => {
  const response = await axios.post(`${apiKey}/scheduleInterview`, data);
  return response.data;
};
export const getFeedbackField = async (id) => {
  const response = await axios.get(
    `${apiKey}/getFeedbackField?candidateId=${id}`
  );
  return response.data.responseData;
};

export const getInterviewById = async (id) => {
  const response = await axios.get(
    `${apiKey}/getInterviewById?interviewId=${id}`
  );
  return response.data.responseData;
};
export const sendFeedbackMail = async (id) => {
  const response = await axios.get(
    `${apiKey}/sendFeedbackMail?interviewId=${id}`
  );
  return response.data.responseData;
};
export const uploadFeedback = async ({ interviewId, file }) => {
  let formData = new FormData();
  formData.append("file", file);
  const response = await axios.post(
    `${apiKey}/uploadFeedback?interviewId=${interviewId}`,
    formData
  );
  return response.data.responseData;
};

export const getInterviewStatus = async () => {
  const response = await axios.get(`${apiKey}/getInterviewStatus`);
  return response.data.responseData;
};
export const submitFeedback = async (data) => {
  const response = await axios.post(`${apiKey}/submitFeedback`, data);
  return response.data;
};

// -------------------Joining Process----------------

export const getTaskListDropDown = async () => {
  const response = await axios.get(`${apiKey}/getTaskListDropDown`);
  return response.data.responseData;
};

export const getAllJoiningStatusList = async () => {
  const response = await axios.get(`${apiKey}/getAllJoiningStatusList`);
  return response.data.responseData;
};

export const getTaskListByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getTaskListByCandidateId?candidateId=${id}`
  );
  return response.data.responseData;
};

export const getTaskByTaskId = async (id) => {
  const response = await axios.get(`${apiKey}/getTaskByTaskId?taskId=${id}`);
  return response.data.responseData;
};

export const getAllCandidateByRecruiterID = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllCandidateByRecruiterID?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};
export const createTask = async (data) => {
  const response = await axios.post(`${apiKey}/createTask`, data);
  return response.data;
};
export const deleteTask = async ({ recruiterId, taskId }) => {
  const response = await axios.post(
    `${apiKey}/deleteTask?recruiterId=${recruiterId}&taskId=${taskId}`
  );
  return response.data;
};
export const getCandidateTaskDetailsByRecruiterId = async (
  limit,
  currentPage,
  filter
) => {
  const response = await axios.post(
    `${apiKey}/getCandidateTaskDetailsByRecruiterId?limit=10&page=${currentPage}`,
    filter
  );
  return response.data.responseData;
};
export const getAllClients = async (limit, name, page) => {
  const response = await axios.get(
    `${apiKey}/getAllClients?limit=${limit}&name=${name}&page=${page}`
  );
  return response.data.responseData;
};

export const createClient = async (data) => {
  const response = await axios.post(`${apiKey}/createClient`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};

export const getClientListFilter = async () => {
  const response = await axios.get(`${baseUrl}/get-clients-for-filter`);
  return response?.data?.responseData;
};

export const deleteClient = async (id) => {
  const response = await axios.delete(`${apiKey}/deleteClient?clientId=${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};
export const getClientById = async (id) => {
  const response = await axios.get(`${apiKey}/getClientById?clientId=${id}`);
  return response.data.responseData;
};

export const addMandatorySkill = async ({ coreSkillId, mandatorySkill }) => {
  const response = await axios.post(
    `${apiKey}/addMandatorySkill?coreSkillId=${coreSkillId}&mandatorySkill=${mandatorySkill}`
  );
  return response.data.responseData;
};
export const addSkill = async ({ skill, id }) => {
  let data = { name: skill, id };
  const response = await axios.post(`${apiKey}/addSkill`, data);
  return response.data.responseData;
};
export const getAllPartner = async (limit, name, page, ratingId) => {
  const response = await axios.get(
    `${apiKey}/getAllPartnerList?limit=${limit}&name=${name}&page=${page}&ratingId=${ratingId}`
  );
  return response.data.responseData;
};

export const CreatePartner = async (data) => {
  const response = await axios.post(`${apiKey}/createPartner`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};

export const deletePartner = async (id) => {
  const response = await axios.delete(
    `${apiKey}/deletePartner?partnerId=${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const getPartnerById = async (id) => {
  const response = await axios.get(`${apiKey}/getPartnerById?id=${id}`);
  return response.data.responseData;
};

export const getAllClientName = async (empId) => {
  if (!empId) {
    empId = "";
  }
  const response = await axios.get(`${apiKey}/getAllClientName?empId=${empId}`);
  return response.data.responseData;
};

export const getAllPartnerName = async () => {
  const response = await axios.get(`${apiKey}/getAllPartnerName`);
  return response.data.responseData;
};

export const getAllSourceList = async () => {
  const response = await axios.get(`${apiKey}/getAllSourceList`);
  return response.data.responseData;
};
export const addPreferredSkill = async ({ preferredSkill }) => {
  const response = await axios.post(
    `${apiKey}/addPreferredSkill?preferredSkill=${preferredSkill}`
  );
  return response.data.responseData;
};
export const getAllAllocatedClientCandidate = async (data) => {
  const response = await axios.post(
    `${apiKey}/getAllAllocatedClientCandidate`,
    data
  );
  return response.data.responseData;
};

export const getAllCandidateAllocationStages = async (
  id,
  isForEmployee,
  prositionId
) => {
  const response = await axios.get(
    `${apiKey}/getAllCandidateAllocationStages?id=${id}&isForEmployee=${isForEmployee}&positionId=${prositionId}`
  );
  return response.data.responseData;
};

export const getAllocationStageById = async (id, isForEmployee) => {
  const response = await axios.get(
    `${apiKey}/getAllocationStageById?id=${id} &isForEmployee=${isForEmployee}`
  );
  return response.data.responseData;
};

export const getAllStages = async () => {
  const response = await axios.get(`${apiKey}/getAllStages`);
  return response.data.responseData;
};

export const createAllocationStage = async (data) => {
  const response = await axios.post(`${apiKey}/createAllocationStage`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data;
};

export const getClientPositions = async (limit, page, positionCode) => {
  const response = await axios.get(
    `${apiKey}/getClientPositions?empId=${employeeId}&limit=${limit}&page=${page}&positionCode=${positionCode}`
  );
  return response.data.responseData;
};

export const getNoAssessmentScheduled = async (
  limit,
  page,
  empId,
  sortBy,
  sortOrder
) => {
  const response = await axios.get(
    `${apiKey}/getNoAssessmentScheduled?employeeId=${empId}&limit=${limit}&page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}`
  );
  return response.data.responseData;
};

export const getInterviewPlateform = async () => {
  const response = await axios.get(`${apiKey}/getInterviewPlatform`);
  return response.data.responseData;
};

export const dropCandidateStatus = async (filter) => {
  const response = await axios.post(`${apiKey}/dropCandidateStatus`, filter);
  return response.data.responseData;
};
export const addScreenRejectNote = async (data) => {
  const response = await axios.post(
    `${apiKey}/send-mail-to-partnert-update
  `,
    data
  );
  return response.data.responseData;
};

export const getfeedbackbyinterviewid = async (id) => {
  const response = await axios.get(
    `${apiKey}/getFeedbackByInterviewId?interviewId=${id}`
  );
  return response.data.responseData;
};

export const getAllDropReason = async (id) => {
  const response = await axios.get(`${apiKey}/getAllDropReason`);
  return response.data.responseData;
};

export const getInterviewTypeByInterviewId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getInterviewTypeByInterviewId?interviewId=${id}`
  );
  return response.data.responseData;
};

export const checkIfCandidateEligibleForCreateOffer = async (id) => {
  const response = await axios.get(
    `${apiKey}/checkIfCandidateEligibleForCreateOffer?candidateId=${id}`
  );
  return response.data.responseData;
};

export const checkIfCandidateEligibleForInterviewSchedule = async (id) => {
  const response = await axios.get(
    `${apiKey}/checkIfCandidateEligibleForInterviewSchedule?candidateId=${id}`
  );
  return response.data.responseData;
};

export const checkEligibilityForCreateOffer = async (id) => {
  const response = await axios.get(
    `${apiKey}/checkEligibilityForCreateOffer?candidateId=${id}`
  );
  return response.data.responseData;
};

export const addJoiningDate = async (filter) => {
  const response = await axios.post(`${apiKey}/addJoiningDate`, filter);
  return response.data;
};
export const addAbscondDate = async (filter) => {
  const response = await axios.post(`${apiKey}/addAbscondedDate`, filter);
  return response.data;
};
// getEmpRoleByEmpId?empId=42 get api
export const getEmpRoleByEmpId = async (id) => {
  const response = await axios.get(`${apiKey}/getEmpRoleByEmpId?empId=${id}`);
  return response.data.responseData;
};

export const getScheduleByInterviewId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getInterviewDataById?interviewId=${id}`
  );
  return response.data.responseData;
};
export const viewCandidateDetailsById = async (
  candidateId,
  positionId,
  isForEmployee,
  empId
) => {
  if (!positionId) {
    positionId = "";
  }
  const response = await axios.get(
    `${apiKey}/viewCandidateDetailsById?candidateId=${candidateId}&positionId=${positionId}&empId=${empId}&isForEmployee=${isForEmployee}`
  );
  return response.data.responseData;
};

export const addNote = async (filter) => {
  const response = await axios.post(`${apiKey}/addNote`, filter);
  return response.data.responseData;
};
export const updateNote = async (filter) => {
  const response = await axios.put(`${apiKey}/updateNote`, filter);
  return response.data.responseData;
};

export const getEmployeesAllNotes = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeesAllNotes?empId=${id}`
  );
  return response.data.responseData;
};

export const getNoteById = async (id) => {
  const response = await axios.get(`${apiKey}/getNoteById?noteId=${id}`);
  return response.data.responseData;
};

export const deleteNote = async (id) => {
  const response = await axios.get(`${apiKey}/deleteNote?noteId=${id}`);
  return response.data.responseData;
};

export const getFinalStatusByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getFinalStatusByCandidateId?candidateId=${id}`
  );
  return response.data.responseData;
};
export const markAsCompleted = async (id) => {
  const response = await axios.post(`${apiKey}/markAsCompleted?taskId=${id}`);
  return response.data.responseData;
};

export const getCandidateHistoryByCandidateID = async (
  limit,
  page,
  candidateId
) => {
  const response = await axios.get(
    `${apiKey}/getCandidateHistoryByCandidateID?limit=${limit}&page=${page}&candidateId=${candidateId}`
  );
  return response.data.responseData;
};

export const downloadScreeningCommentsByCandidateId = async (data) => {
  const response = await axios.post(
    `${apiKey}/downloadScreeningCommentsByCandidateId?candidateId=${data}`,
    {
      responseType: "arraybuffer",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    }
  );
  return response;
};

export const getAllDegrees = async () => {
  const response = await axios.get(`${apiKey}/getAllDegrees`, {
    headers: {
      Authorization: "",
    },
  });
  return response.data.responseData;
};
export const getAllPriorityList = async () => {
  const response = await axios.get(`${apiKey}/getAllPriorityList`);
  return response.data.responseData;
};
export const getOwnersOfActivePositions = async () => {
  const response = await axios.get(`${apiKey}/get-owners-of-active-positions`);
  return response.data.responseData;
};
export const getClientPositionCodesForFilter = async () => {
  const response = await axios.get(`${apiKey}/getClientPositionCodesForFilter`);
  return response.data.responseData;
};
export const getWorkModesList = async () => {
  const response = await axios.get(`${apiKey}/getWorkModesList`);
  return response.data.responseData;
};
export const checkCandidateByEmail = async (candidateId, email) => {
  const response = await axios.get(
    `${apiKey}/checkCandidateByEmail?candidateId=${candidateId}&email=${email}`
  );
  return response.data.responseData;
};
export const checkCandidateByMobileNo = async (candidateId, mobileNo) => {
  mobileNo = mobileNo && encodeURIComponent(mobileNo);
  const response = await axios.get(
    `${apiKey}/checkCandidateByMobileNo?candidateId=${candidateId}&mobileNo=${mobileNo}`
  );
  return response.data.responseData;
};
export const addDegree = async ({ degree }) => {
  const response = await axios.post(`${apiKey}/addDegree?degree=${degree}`);
  return response.data.responseData;
};
export const getCandidateJourney = async (candidateId, sourcing) => {
  const response = await axios.get(
    `${apiKey}/get-candidate-journey?candidateId=${candidateId}&sourcing=${sourcing}`
  );
  return response.data.responseData;
};
export const getCandidatesByName = async (name, date, email, mobileNo) => {
  const response = await axios.get(
    `${apiKey}/get-candidates-by-name?email=${
      email ? email : ""
    }&empId=${employeeId}&mobileNo=${mobileNo ? mobileNo : ""}&name=${
      name ? name.trim() : name
    }&sourcedDate=${date ? date : ""}`
  );
  return response.data;
};
export const viewEmployeeDetailsById = async (empId) => {
  const response = await axios.get(
    `${apiKey}/viewEmployeeDetailsById?empId=${empId}`
  );
  return response.data?.responseData;
};
export const getPositionReport = async (data) => {
  const response = await axios.post(`${apiKey}/get-position-report`, data);
  return response.data?.responseData;
};
export const getFutureHireList = async () => {
  const response = await axios.get(`${apiKey}/getFutureHireList`);
  return response.data?.responseData;
};
export const getJoiningDropDetailDyCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-joining-drop-detail-by-candidate-id?candidateId=${id}`
  );
  return response.data?.responseData;
};
export const getSourcingReport = async (data) => {
  const response = await axios.post(`${apiKey}/get-sourcing-report`, data);
  return response.data.responseData;
};
export const deleteAllocationStages = async ({ id }) => {
  const response = await axios.delete(
    `${apiKey}/delete-allocation-stages?stageId=${id}`
  );
  return response.data.responseData;
};
export const getAllLeadList = async () => {
  const response = await axios.get(`${apiKey}/get-all-lead-list`);
  return response.data?.responseData;
};

export const getIncentiveHistoryDetails = async (
  limit,
  page,
  recruiterId,
  candidateId
) => {
  const response = await axios.get(
    `${apiKey}/get-incentives-history?candidateId=${candidateId}&limit=${limit}&page=${page}&recruiterId=${recruiterId}`
  );
  return response.data?.responseData;
};

export const getIncentiveDetails = async (recruiterId, candidateId) => {
  const response = await axios.get(
    `${apiKey}/get-incentives-details?candidateId=${candidateId}&recruiterId=${recruiterId}`
  );
  return response.data?.responseData;
};

export const addIncentiveReport = async (modalData) => {
  const response = await axios.post(
    `${apiKey}/add-incentives-to-recruiter`,
    modalData
  );
  return response.data;
};

export const getClientServiceReport = async (
  id,
  client,
  salesOwner,
  all,
  positionType,
  priorityId,
  positionOwnerId
) => {
  const response = await axios.get(
    `${apiKey}/get-client-service-report?all=${all}&createdBy=${id}&client=${client}&salesOwner=${salesOwner}&positionType=${positionType}&priorityId=${priorityId}${
      positionOwnerId ? `&positionOwnerId=${positionOwnerId}` : ""
    }`
  );
  return response.data?.responseData;
};

export const getClientServiceReport1 = async (
  empId,
  clientId,
  salesOwner,
  historic,
  positionType,
  priority,
  positionOwner
) => {
  const params = {
    empId,
    clientId,
    salesOwner,
    positionType,
    priority,
    positionOwner,
    historic,
  };

  const response = await axios.post(
    `${apiKey}/get-client-service-report-new`,
    params
  );
  return response.data?.responseData;
};

export const getDetailedSourceReport = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-detailed-source-report`,
    data
  );
  return response.data?.responseData;
};

export const getDetailedSourceReportNew = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-detailed-source-report-new`,
    data
  );

  // return response1.responseData;
  console.log(response.data.responseData);
  return response.data.responseData;
};

export const getToolTipData = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-detailed-source-report-data`,
    data
  );

  return response.data?.responseData;
};

export const getDetailedSourceWithBacklog = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-detailed-source-report-with-backlog`,
    data
  );
  return response.data?.responseData;
};

export const getTotalLogComponent = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-detailed-source-report`,
    data
  );
  return response.data?.responseData;
};

export const getPartnerPerformanceReport = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-partner-performance-report`,
    data
  );
  return response.data?.responseData;
};
export const getPositionSourcingReport = async (id, type) => {
  const response = await axios.post(
    `${apiKey}/get-position-sourcing-report?createdBy=${id}&positionType=${type}`
  );
  return response.data?.responseData;
};
export const getEmployeeNameById = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-employee-name-by-id?empId=${id}`
  );
  return response.data?.responseData;
};
export const getLowerEmployee = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-lower-employee?empId=${id}&active=true`
  );
  return response.data?.responseData;
};
export const getEmploymentType = async () => {
  const response = await axios.get(`${apiKey}/get-employment-type`);
  return response.data?.responseData;
};
export const getAllRecruiterByManager = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-all-recruiter-by-manager?empId=${id}`
  );
  return response.data?.responseData;
};
export const getCaseSharedStatus = async () => {
  const response = await axios.get(`${apiKey}/get-case-shared-status`);
  return response.data?.responseData;
};
export const getSalaryAssessmentStatus = async () => {
  const response = await axios.get(`${apiKey}/get-salary-assessment-status`);
  return response.data?.responseData;
};
export const addCaseSharedStatus = async (data) => {
  const response = await axios.post(`${apiKey}/add_case_shared_status`, data);
  return response.data?.responseData;
};
export const addSalaryAssessmentStatus = async (data) => {
  const response = await axios.post(
    `${apiKey}/add_salary_assessment_status
`,
    data
  );
  return response.data?.responseData;
};
export const getCaseSharedStatusById = async (id) => {
  const response = await axios.get(
    `${apiKey}/get_candidate_case_shared_status?candidateId=${id}`
  );
  return response.data?.responseData;
};
export const getCandidateSalaryAssById = async (id) => {
  const response = await axios.get(
    `${apiKey}/get_candidate_salary_assessment_status?candidateId=${id}`
  );
  return response.data?.responseData;
};
export const cancelMarkForOffer = async (id) => {
  const response = await axios.put(
    `${apiKey}/cancel-mark-for-offer?candidateId=${id}`
  );
  return response.data?.responseData;
};
export const assignNewRecruiter = async ({
  candidateId,
  recruiterId,
  date,
}) => {
  const response = await axios.put(`${apiKey}/assign-new-recruiter`, {
    candidateId,
    recruiterId,
    date,
  });
  return response.data?.responseData;
};
export const getAllRecruiters = async () => {
  const response = await axios.get(`${apiKey}/get-all-recruiters`);
  return response.data?.responseData;
};

export const getPositionCodeByFilter = async (empId) => {
  const response = await axios.post(`${apiKey}/getPositionCodeByFilter`, empId);
  return response.data?.responseData;
};

export const getCandidateBgvList = async (candidateName, email, mobileNo) => {
  // try {
    const response = await axios.get(
      `${apiKey}/getCandidateBgvList?empId=${employeeId}&candidateName=${candidateName}&email=${email}&mobileNo=${mobileNo}`
    );
    return response?.data
  //   const { responseData, message } = response.data;
  //   if (response.data?.responseData.getAllData && response.data.responseData.getAllData.length > 0) {
  //     return response.data.responseData;
  //   } else {
  //     // toast.error('No candidate data found.');
  //     return {message: message || "NO DATA FOUND"}
  //   }
  //   // return response.data?.responseData;
  // } catch (error) {
  //   toast.error(`Error fetching data: ${error.message}`);
  // }
};

export const getBGVByCandidate = async (candidateId) => {
  const response = await axios.get(
    `${apiKey}/getBGVByCandidate?candidateId=${candidateId}`
  );
  return response.data?.responseData;
};
export const createBGV = async (data) => {
  const response = await axios.post(`${apiKey}/createBGV`, data);
  return response.data?.responseData;
};
export const deleteBgvByBgvId = async ({ bgvId }) => {
  const response = await axios.delete(
    `${apiKey}/deleteBgvByBgvId?bgvId=${bgvId}`
  );
  return response.data?.responseData;
};
export const addPartnerRating = async (data) => {
  const response = await axios.post(`${apiKey}/addPartnerRating`, data);
  return response.data?.responseData;
};
export const getAllPartnerRatingList = async (partnerId) => {
  const response = await axios.get(
    `${apiKey}/getAllPartnerRatingList?partnerId=${partnerId}`
  );
  return response.data?.responseData;
};

export const getAcademicsByEmail = async ({ emailId }) => {
  const response = await axios.get(
    `${apiKey}/get-academics-by-email?emailId=${emailId}`,
    {
      headers: {
        Authorization: "",
      },
    }
  );
  return response.data?.responseData;
};
export const getWorkExperienceByEmail = async ({ emailId }) => {
  const response = await axios.get(
    `${apiKey}/get-work-experience-by-email?emailId=${emailId}`,
    {
      headers: {
        Authorization: "",
      },
    }
  );
  return response.data?.responseData;
};
export const addReadyForClientSharing = async (data) => {
  const response = await axios.put(
    `${apiKey}/add-ready-for-client-sharing`,
    data
  );
  return response.data?.responseData;
};
export const getCandidateAllocatedPositions = async (candidateId, isEmp) => {
  const response = await axios.get(
    `${apiKey}/get-candidate-allocated-positions?candidateId=${candidateId}&isEmp=${isEmp}`
  );
  return response.data?.responseData;
};
export const getEmployeeClientAllocationStages = async (empId, limit, page) => {
  const response = await axios.get(
    `${apiKey}/get-employee-client-allocation-stages?empId=${empId}&limit=${limit}&page=${page}`
  );
  return response.data?.responseData;
};
export const getAllJoinedCandidates = async () => {
  const response = await axios.get(`${apiKey}/get-all-joined-candidates`, {
    headers: {
      Authorization: "",
    },
  });
  return response.data?.responseData;
};
export const getPositionStatus = async () => {
  const response = await axios.get(`${apiKey}/get-position-status`);
  return response.data?.responseData;
};
export const getInActiveReason = async () => {
  const response = await axios.get(`${apiKey}/get-all-inactive-reasons`);
  return response.data?.responseData;
};
export const getEmploymentModes = async () => {
  const response = await axios.get(`${apiKey}/get-employment-modes`);
  return response.data?.responseData;
};

export const addPositionStatus = async (data) => {
  const response = await axios.post(`${apiKey}/add-position-status`, data);
  return response.data?.responseData;
};

export const getSourcingPerformanceMatrixReport = async (
  leadId,
  reportType,
  report,
  empType
) => {
  const response = await axios.get(
    `${apiKey}/get-sourcing-performance-matrix-report?leadId=${leadId}&empType=${empType}&report=${report}&reportType=${reportType}`
  );
  return response.data?.responseData;
};

export const getSourcingPerformanceMatrixReport1 = async (
  leadId,
  reportType,
  empType
) => {
  const response = await axios.get(
    `${apiKey}/get-interview-sourcing-report?leadId=${leadId}&empType=${empType}&durationType=${reportType}`
  );
  return response.data?.responseData;
};

export const getSourcingPerformanceMatrixReportNew = async (
  leadId,
  reportType,
  empType
) => {
  const response = await axios.get(
    `${apiKey}/get-profile-sourcing-report?leadId=${leadId}&empType=${empType}&durationType=${reportType}`
  );
  return response.data?.responseData;
};
export const getBgvByCandidateEmail = async (data) => {
  const response = await axios.get(
    `${apiKey}/get-bgv-by-candidate-email?emailId=${data}`
  );
  return response.data?.responseData;
};
export const getClosureReport = async (id, recruiterId, year, all) => {
  const response = await axios.get(
    `${apiKey}/get-closure-report?year=${year}&all=${all}${
      id ? `&leadId=${id}` : ""
    }${recruiterId ? `&recruiterId=${recruiterId}` : ""}`
  );
  return response.data?.responseData;
};

export const getIncentiveReport = async (id, recruiterId, year, all) => {
  const response = await axios.get(
    `${apiKey}/get-incentive-report?year=${year}&all=${all}${
      id ? `&leadId=${id}` : ""
    }${recruiterId ? `&recruiterId=${recruiterId}` : ""}`
  );
  return response.data?.responseData;
};

export const getSourcingVsSharedReport = async (leadId, reportType) => {
  const response = await axios.get(
    `${apiKey}/get-sourcing-vs-shared-report?leadId=${leadId}&reportType=${reportType}`
  );
  return response.data?.responseData;
};

export const getUnsharedEmployeeForClient = async (positionId, type) => {
  let baseUrl = ` ${apiKey}/get-unshared-employee-for-client?`;

  if (positionId) {
    baseUrl += `&positionId=${positionId}`;
  }
  if (type) {
    baseUrl += `&type=${type}`;
  }
  const response = await axios.get(baseUrl);
  return response.data?.responseData;
};
export const getMonthlyDeploymentReport = async (year) => {
  const response = await axios.get(
    `${apiKey}/get-monthly-deployment-report?year=${year}`
  );
  return response.data?.responseData;
};
export const getSkillWiseDeploymentReport = async (year) => {
  const response = await axios.get(
    `${apiKey}/get-skill-wise-deployment-report?year=${year}`
  );
  return response.data?.responseData;
};
export const getClientWiseDeploymentReport = async () => {
  const response = await axios.get(
    `${apiKey}/get-client-wise-deployment-report`
  );
  return response.data?.responseData;
};
export const getSourcingDeploymentReport = async (year) => {
  const response = await axios.get(
    `${apiKey}/get-sourcing-deployment-report?year=${year}`
  );
  return response.data?.responseData;
};
export const getAvailableResourcesList = async (getType) => {
  const response = await axios.get(
    `${apiKey}/get-available-resources-list?type=${getType}`
  );
  return response.data?.responseData;
};

export const getAvailablePositionForCandidate = async (idType) => {
  const data = idType?.split("-");
  const id = data[0];
  const type = data[1];
  const response = await axios.get(
    `${apiKey}/get-available-position-for-candidate?id=${id}&type=${type}`
  );
  return response.data?.responseData;
};
export const getAllInactiveRecruiterCandidates = async (
  limit,
  page,
  filter
) => {
  const response = await axios.post(
    `${apiKey}/get-all-inactive-recruiters-candidates?limit=${limit}&page=${page}`,
    filter
  );
  return response.data?.responseData;
};
export const getCreatePartnerList = async (limit, page, data) => {
  const response = await axios.post(
    `${baseUrl}/getCreatePartnerList?limit=${limit}&page=${page}`,
    data
  );
  return response.data?.responseData;
};
export const getPartnerListForJDShare = async (
  limit,
  page,
  data,
  positionId,
  reverse,
  responded
) => {
  const response = await axios.post(
    `${baseUrl}/get-partner-list-for-jd-share?limit=${limit}&page=${page}&positionId=${positionId}&reverse=${reverse}&responded=${responded}`,
    data
  );
  return response.data?.responseData;
};
export const getAllSpecializatiponForfilter = async () => {
  const response = await axios.get(`${baseUrl}/getAllSpecializatiponForfilter`);
  return response.data?.responseData;
};
export const getPartnerRecruiterList = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getPartnerRecruiterList?empId=${id}`
  );
  return response.data?.responseData;
};
export const getPartnerRecruiterList1 = async (id) => {
  const response = await axios.get(`${baseUrl}/getPartnerRecruiterList`);
  return response.data?.responseData;
};

export const getLeadLocationList = async (id) => {
  const response = await axios.get(`${baseUrl}/partner-lead/location`);
  return response.data?.responseData;
};

export const getLeadSourceList = async (id) => {
  const response = await axios.get(`${baseUrl}/partner-lead/source`);
  return response.data?.responseData;
};

export const getLeadStatusList = async (id) => {
  const response = await axios.get(`${baseUrl}/partner-lead/status`);
  return response.data?.responseData;
};

export const getPartnerListForFilter = async () => {
  const response = await axios.get(`${baseUrl}/getPartnerListForFilter`);
  return response.data?.responseData;
};
export const jdSendToPartner = async (data) => {
  const response = await axios.post(`${apiKey}/jd-send-to-partner`, data);
  return response.data?.responseData;
};

export const getCityList = async () => {
  const response = await axios.get(`${baseUrl}/getCityList`);
  return response.data?.responseData;
};
export const getTextSend = async (id) => {
  const response = await axios.get(`${apiKey}/get-text-send?positionId=${id}`);
  return response.data?.responseData;
};
export const getRecruiter = async () => {
  const response = await axios.get(`${apiKey}/get-recruiter`);
  return response.data?.responseData;
};
// export const getPositionCoreSkills = async () => {
//   const response = await axios.get(`${apiKey}/get-position-core-skills`);
//   return response.data?.responseData;
// };
export const validateEmployeeAccess = async (empId) => {
  const response = await axios.get(
    `${baseUrl}/validate-employee-access?empId=${empId}`
  );
  return response.data?.responseData;
};
export const getUserUiElementAccess = async (empId) => {
  const response = await axios.get(
    `${apiKey}/get-user-ui-element-access?empId=${empId}`
  );
  return response.data?.responseData;
};
export const getClientsSalesOwnerList = async () => {
  const response = await axios.get(
    `${apiKey}/get-all-clients-sales-owner-list`
  );
  return response.data?.responseData;
};
export const getCandidatesByEmailAndMobile = async (email, number) => {
  const response = await axios.get(
    `${apiKey}/get-candidates-by-email-and-mobile?email=${email}&number=${number}`
  );
  return response.data?.responseData;
};
export const getAllDroppedReasonsWithCount = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-all-dropped-reasons-with-count`,
    data
  );
  return response.data.responseData;
};
export const getEngagementReport = async (sortBy, sortOrder) => {
  const response = await axios.get(
    `${apiKey}/candidate-engagement-report?sortBy=${
      sortBy ? sortBy : ""
    }&sortOrder=${sortOrder ? sortOrder : ""}`
  );
  return response.data.responseData;
};
export const getDroppedCandidatesWithFutureApproachable = async (
  limit,
  page,
  skillID,
  maxExp,
  minExp,
  location,
  ctc,
  sources,
  interviewStatusId,
  name,
  offerStatusId,
  markAsOfferRelease,
  sortBy
) => {
  let data = {
    limit: limit,
    location: location,
    maxExp: maxExp,
    minExp: minExp,
    page: page,
    skillId: skillID?.map((e) => e?.value),
    ctc: ctc,
    sources: sources,
    interviewStatusId: interviewStatusId,
    name: name,
    offerStatusId: offerStatusId,
    markAsOfferRelease: markAsOfferRelease,
    sortBy: sortBy,
  };
  const response = await axios.post(
    `${apiKey}/get-dropped-candidates-with-future-approachable`,
    data
  );
  return response.data.responseData;
};
export const getDroppedCandidatesWithFutureApproachableForExcelDownload =
  async (limit, page, skillID, maxExp, minExp, location, ctc, sources) => {
    let data = {
      limit: limit,
      location: location,
      maxExp: maxExp,
      minExp: minExp,
      page: page,
      skillId: skillID?.map((e) => e?.value),
      ctc: ctc,
      sources: sources,
    };
    const response = await axios.post(
      `${apiKey}/get-dropped-candidates-with-future-approachable`,
      data
    );
    const sheetHeader = [
      "Name",
      "Email ID",
      "Core Skill",
      "Source Date",
      "Recruiter Name",
      "Total Experience",
      "Relevant Experience",
      "CTC",
      "Current Location",
      "Home Town",
      "Mobile No",
      "FM Rating",
      "Drop Reason",
      "ATS Database Comments",
      "Note",
    ];
    const handleExcelData = (data) => {
      return data?.map((e) => {
        return [
          e?.name,
          e?.email,
          e?.coreSkill,
          moment(e?.sourceDate).format("DD MMM YY"),
          e?.recruiterName,
          (function () {
            let year = Math.floor(e.totalExperienceYear);
            let month = e.totalExperienceMonth;
            year = year > 0 ? year + " Y  " : "0 Y";
            month = month > 0 ? ` - ${month}  M ` : "";
            return `   ${year}  ${month}`;
          })(),
          (function () {
            let year = Math.floor(e.totalExperienceYear);
            let month = e.totalExperienceMonth;
            year = year > 0 ? year + " Y  " : "0 Y";
            month = month > 0 ? ` - ${month}  M ` : "";
            return `   ${year}  ${month}`;
          })(),
          e?.ctc,
          e?.currentLocation,
          e?.homeTown,
          e?.mobileNo,
          e?.fmRating,
          e?.dropReason,
          e?.atsDataBaseComments,
          e?.note,
        ];
      });
    };
    CustomExcelFileDownload(
      sheetHeader,
      handleExcelData(response.data.responseData?.getAllData),
      "Dropped Candidates With Future Approachable"
    );
    return;
  };

export const getWishlistReviewCandidates = async (
  requesterId,
  page,
  sortCoreSkill,
  toBeReviewed
) => {
  const response = await axios.get(
    `${apiKey}/getWishlistReviewCandidates?limit=50&page=${page}&requesterId=${requesterId}&sortCoreSkill=${sortCoreSkill}&toBeReviewed=${toBeReviewed}`
  );
  return response.data.responseData;
};

export const removeCandidateFromWishlist = async ({
  candidateId,
  requesterId,
}) => {
  const response = await axios.post(
    `${apiKey}/remove-candidate-from-wishlist?candidateId=${candidateId}&requesterId=${requesterId}`
  );
  return response.data.responseData;
};

export const addCandidateToWishlistReview = async (data) => {
  const response = await axios.post(
    `${apiKey}/addCandidateToWishlistReview`,
    data
  );
  return response.data;
};
export const statusMarkByTeamLead = async (data) => {
  const response = await axios.post(`${apiKey}/statusMarkByTeamLead`, data);
  return response.data;
};

export const removeCandidateFromWishlistReview = async ({
  candidateId,
  requesterId,
  toBeReviewed,
}) => {
  const response = await axios.post(
    `${apiKey}/removeCandidateFromWishlistReview?candidateId=${candidateId}&requesterId=${requesterId}&toBeReviewed=${toBeReviewed}`
  );
  return response.data.responseData;
};

export const getInterviewerInterviews = async (
  all,
  interviewerId,
  limit,
  page
) => {
  const response = await axios.get(
    `${apiKey}/get-interviewer-interviews?all=${all}&interviewerId=${interviewerId}&limit=${limit}&page=${page}`
  );
  return response.data.responseData;
};

export const getNegotiableDaysList = async () => {
  const response = await axios.get(`${apiKey}/get-negotiable-days-list`);
  return response.data.responseData;
};
export const getInterviewCancelReasons = async () => {
  const response = await axios.get(`${apiKey}/get-interview-cancel-reasons`);
  return response.data.responseData;
};
export const cancelInterview = async (data) => {
  const response = await axios.put(`${apiKey}/cancel-interview`, data);
  return response.data.responseData;
};

export const getRecruiterList = async () => {
  const response = await axios.get(`${apiKey}/get-recruiter`);
  return response.data.responseData;
};
export const addResourceNote = async (data) => {
  const response = await axios.post(`${apiKey}/add-resource-note`, data);
  return response.data.responseData;
};
export const getHoldsPostionsAccountManagementInactiveReport = async (
  limit,
  page,
  statusId,
  reportType
) => {
  const response = await axios.get(
    `${apiKey}/get-holds-postions-account-management-inactive-report?limit=${limit}&page=${page}&reportType=${reportType}&statusId=${statusId}`
  );
  return response.data.responseData;
};
export const getDashboardForInActiveReport = async (
  limit,
  page,
  salesOwner,
  date,
  isMonth
) => {
  const response = await axios.post(
    `${apiKey}/get-dashboard-for-inactive-position-report?limit=${limit}&page=${page}`,
    {
      salesOwner: salesOwner ? salesOwner : null,
      isMonth:
        isMonth === true || isMonth === false ? (date ? isMonth : null) : null,
      startDate: date ? date : null,
      statusId: null,
    }
  );
  return response.data.responseData;
};

export const getInActivePositionReport = async (
  salesOwner,
  statusId,
  date,
  isMonth
) => {
  const response = await axios.post(`${apiKey}/get-inactive-position-report`, {
    salesOwner: salesOwner ? salesOwner : null,
    isMonth:
      isMonth === true || isMonth === false ? (date ? isMonth : null) : null,
    startDate: date ? date : null,
    statusId: statusId >= 0 ? statusId : null,
  });
  return response.data.responseData;
};

export const addBlackListMarketingCompny = async (data) => {
  const response = await axios.post(
    `${apiKey}/addBlackListMarketingCompny`,
    data
  );
  return response.data;
};

export const getAllBlackLIstedCompany = async (limit, page, searchFilter) => {
  const response = await axios.get(
    `${apiKey}/getAllBlackLIstedCompany?limit=${limit}&page=${page}&searchfilter=${searchFilter}`
  );
  return response.data.responseData;
};

export const checkExistCompanyName = async (companyName) => {
  const response = await axios.get(
    `${apiKey}/check-exist-company-name?companyName=${companyName}`
  );
  return response.data.responseData;
};

export const addCommentsAtsDatabase = async (data) => {
  const response = await axios.put(
    `${apiKey}/add-comments-ats-data-base`,
    data
  );
  return response.data.responseData;
};

export const getClientSharedEmployeeListForFilter = async () => {
  const response = await axios.get(
    `${apiKey}/get-client-shared-employee-list-for-filter`
  );
  return response.data.responseData;
};
export const getAllActivePipelineCount = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-all-active-pipeline-count`,
    data
  );
  return response.data.responseData;
};

// ATS _RECRUITER DASHBOARD

export const getAllRecruiterDasboardCount = async (empId, recruiterId) => {
  if (recruiterId === 0) {
    const response = await axios.get(
      `${apiKey}/get-all-recruiter-dasboard-count?empId=${empId}`
    );
    return response.data.responseData;
  } else {
    const response = await axios.get(
      `${apiKey}/get-all-recruiter-dasboard-count?empId=${empId}&recruiterId=${recruiterId}`
    );
    return response.data.responseData;
  }
};
export const getAllSourcingDroppedReasonsCount = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-all-sourcing-dropped-reasons-count`,
    data
  );
  return response.data.responseData;
};
export const getClientsSalesOwnerListForCreate = async (data) => {
  const response = await axios.get(
    `${apiKey}/get-clients-sales-owner-list-for-create`
  );
  return response.data.responseData;
};
export const getAllCoreSkillList = async (data) => {
  const response = await axios.get(`${apiKey}/get-all-core-skill-list`);
  return response.data.responseData;
};
export const getAllWishlistReviewCases = async () => {
  const response = await axios.get(`${apiKey}/getAllWishlistReviewCases`);
  return response.data?.responseData;
};

export const getSourcingReportNew = async (data) => {
  const response = await axios.post(`${apiKey}/get-sourcing-report-new`, data);
  return response.data.responseData;
};

export const getTestSourcingReportNew = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-sourcing-report-new-by-count`,
    data
  );
  return response.data.responseData;
};

export const getSourcingReportData = async (data) => {
  // Check if any field in the data object is null
  if (Object.values(data).some((value) => value === null)) {
    throw new Error("One or more fields in the data object are null.");
  }

  const response = await axios.post(
    `${apiKey}/get-sourcing-report-data-new-by-id`,
    data
  );
  return response.data.responseData;
};

export const getSourcingDataByPosition = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-sourcing-data-candidate-by-position`,
    data
  );
  return response.data.responseData;
};
export const getAllEmployees = async () => {
  const response = await axios.get(`${prUrl}/get-all-employees`);
  return response.data?.responseData?.employeeList;
};
export const getAllCandidateComments = async (candidateId) => {
  const response = await axios.get(
    `${apiKey}/get-all-candidate-comments?candidateId=${candidateId}`
  );
  return response.data?.responseData;
};

export const getAllJobComment = async (JobId) => {
  const response = await axios.get(
    `${apiKey}/get-all-job-applicants-comments?jobApplicantsId=${JobId}`
  );
  return response.data?.responseData;
};

export const getAllPositionComments = async (positionId) => {
  const response = await axios.get(
    `${apiKey}/get-all-position-comments?positionId=${positionId}`
  );
  return response.data?.responseData;
};

export const getPartnerLeadCommentByLeadId = async (partnerLeadId)=>{
  const response = await axios.get(`${baseUrl}/partner-lead/comments?partnerLeadId=${partnerLeadId}`);
  return response.data?.responseData;
}

export const addPartnerLeadComment = async (data)=>{
  const response = await axios.post(`${baseUrl}/partner-lead/comment/add`,
    data
  );
  return response.data
}

export const editPartnerLeadComment = async (data)=>{
  const response = await axios.put(`${baseUrl}/partner-lead/comment/edit`,
    data
  );
  return response?.data
}

export const deletePartnerLeadCommentByLeadId = async (commentId)=>{
  const response = await axios.delete(`${baseUrl}/partner-lead/comments/delete?commentId=${commentId}`)
}

export const getAllPartnerComments = async (partnerId) => {
  const response = await axios.get(
    `${baseUrl}/getPartnerCommentListByPartnerId?partnerId=${partnerId}`
  );
  return response.data?.responseData;
};

export const addCommentsForCandidate = async (data) => {
  const response = await axios.post(
    `${apiKey}/add-comments-for-candidate`,
    data
  );
  return response.data;
};

export const addCommentsForJobApplicant = async (data) => {
  const response = await axios.post(
    `${apiKey}/add-comments-for-job-applicants`,
    data
  );
  return response.data;
};

export const addCommentsForPosition = async (data) => {
  const response = await axios.post(
    `${apiKey}/add-comments-for-position`,
    data
  );
  return response.data;
};

export const addCommentsForPartner = async (data) => {
  const response = await axios.post(`${baseUrl}/partnerComment`, data);
  return response.data;
};
export const updateCandidateComment = async ({ commentId, empId }) => {
  const response = await axios.put(
    `${apiKey}/update-candidate-comment?commentId=${commentId}&employeeId=${empId}`
  );

  return response.data.responseData;
};

export const updateJobComment = async ({ commentId, empId }) => {
  const response = await axios.put(
    `${apiKey}/update-job-applicants-comment?commentId=${commentId}&employeeId=${empId}`
  );

  return response.data.responseData;
};

export const updatePositionComment = async ({ commentId, empId }) => {
  const response = await axios.put(
    `${apiKey}/update-position-comment?commentId=${commentId}&employeeId=${empId}`
  );

  return response.data.responseData;
};

export const updatePartnerComment = async ({ commentId, empId }) => {
  const response = await axios.delete(
    `${baseUrl}/deletePartnerComment?commentId=${commentId}&commetedById=${empId}`
  );

  return response.data.responseData;
};
export const knowledgeBase = async () => {
  const response = await axios.get(`${apiKey}/knowledge-base`);
  return response.data.responseData;
};
export const getSourcingReportByRecruiter = async (data) => {
  const response = await axios.get(
    `${apiKey}/get-sourcing-report-by-recruiter?date=${data.date}&recruiterId=${data.recruiterId}`
  );
  return response.data.responseData;
};

export const viewRMSNote = async (empId) => {
  const response = await axios.get(
    `${resourceUrl}/getAllPoolResourceComments?empId=${empId}&isPoolNotes=true&limit=10&page=1`
  );
  return response.data.responseData;
};

export const addRMSNote = async (data) => {
  const response = await axios.post(
    `${resourceUrl}/addPoolResourceComments`,
    data
  );
  return response.data;
};

export const getAllApplicants = async (
  limit,
  page,
  filter,
  sortingBy,
  sortingOrder
) => {
  const filteredEntries = Object.entries(filter).filter(
    ([key, value]) => value !== ""
  );
  const filterString = filteredEntries
    .map(([key, value]) => `${key}-${value}`)
    .join(",");
  const finalFilterString = filterString ? `filter=${filterString}` : "";
  const response = await axios.get(
    `${apiKey}/applicants?${finalFilterString}&limit=${limit}&page=${page}&sortBy=${sortingBy}&sortOrder=${sortingOrder}`
  );
  return response.data.responseData;
};
export const checkDuplicates = async ({ email, phone }) => {
  const response = await axios.get(
    `${apiKey}/applicants/duplicate?email=${email}&phone=${phone}`
  );
  return response.data.responseData;
};

export const updateApplicant = async (data) => {
  const response = await axios.put(`${apiKey}/applicants/update`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data;
};
export const removeApplicant = async (applicantId) => {
  const response = await axios.put(
    `${apiKey}/applicants/remove/${applicantId}`,
    null,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data;
};
export const addApplicant = async (data) => {
  const response = await axios.post(`${apiKey}/applicants/add`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data;
};

export const getUserCodeByType = async (type) => {
  const response = await axios.get(
    `${url}/auto-generate-employee-code?type=${type}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: null,
      },
    }
  );
  return response.data.responseData;
};
export const jobApplicantScreening = async ({
  id,
  screening,
  screeningNotes,
}) => {
  const response = await axios.post(
    `${apiKey}/job-applicants-screening?jobApplicantsId=${id}&screening=${screening}&screeningNotes=${screeningNotes}&screeningBy=${employeeId}`,
    null,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data;
};
export const getScreenRejectJobApplicants = async (
  limit,
  page,
  sortingBy1,
  sortingOrder1
) => {
  const response = await axios.get(
    `${apiKey}/get-screen-reject-job-applicants?limit=${limit}&page=${page}&sortBy=${sortingBy1}&sortOrder=${sortingOrder1}`
  );
  return response.data.responseData;
};

export const getPartnerNameList = async () => {
  const response = await axios.get(`${baseUrl}/partner-lead/partner`);
  return response.data?.responseData;
};
export const getEmployeeRoleByEmployeeId = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-employee-role-by-employee-id?employeeId=${id}`
  );
  return response.data?.responseData;
};

// get all comments for client
export const getAllCommentsForClient = async (clientId) => {
  const response = await axios.get(
    `${apiKey}/get-all-client-comments?clientId=${clientId}`
  );
  return response.data?.responseData;
};

// add/edit comment
export const addCommentsForClientMngmnt = async (data) => {
  const response = await axios.post(`${apiKey}/add-comments-for-client`, data);
  return response.data;
};

// delete comment for client
export const deleteCommentsForClientMngmnt = async (data) => {
  const response = await axios.put(
    `${apiKey}/update-client-comment?commentId=${data?.commentId}&employeeId=${employeeId}`,
    data
  );
  return response.data;
};

// api for offer process - 6 till now
export const submitCurrSalary = async (data)=>{
  const res = await axios.post(`${apiKey}/compensation/estimation`
    ,data
  );
  console.log(res)
  return res
}

export const getEstimateByCandidateId = async (id)=>{
  const res = await axios.get(`${apiKey}/compensation/estimation/all?candidateId=${id}`)
  return res
}

export const updateCurrSalary = async (data)=>{
  const res = await axios.post(`${apiKey}/compensation/estimation`
    ,data
  );
  return res
}

export const getComponentList = async ()=>{
  const res = await axios.get(`${apiKey}/compensation/breakdowns/component`
  );
  return res?.data?.responseData
}

export const createBreakDown = async (data)=>{
    const res = await axios.post(`${apiKey}/compensation/breakdown`,
    data
  );
  return res
}

export const getBreakDowns = async (id)=>{
  const res = await axios.get(`${apiKey}/compensation/breakdowns?candidateId=${id}`)
  return res?.data?.responseData;
}

export const getFixedDistribution = async (offerId)=>{
  const res = await axios.get(`${apiKey}/compensation/breakdowns/component/distribution?offerId=${offerId}`)
  return res?.data?.responseData;
}

export const approveCompensationBreakdown = async (approveBy, offerId)=>{
  const res = await axios.put(`${apiKey}/compensation/breakdowns/approve?approveBy=${approveBy}&offerId=${offerId}`)
  return res?.data?.responseData;
}

export const createJDShareComment = async (data)=>{
  const res = await axios.post(`${apiKey}/create-jd-share-comment`,
    data
  )
  return res?.data;
}

export const getAllJDShareComments = async (partnerId, positionId)=>{
  const res = await axios.get(`${apiKey}/get-all-jd-share-comments?partnerId=${partnerId}&positionId=${positionId}`);
  return res?.data?.responseData;
}

export const updateJDShareComment = async (commentId)=>{
  const res = await axios.put(`${apiKey}/update-jd-share-comment?commentId=${commentId}&employeeId=${employeeId}`);
  return res?.data;
}