import React, { useState } from "react";
import BreadCrum from "../../../breadcrums/BreadCrum";
import ATSContainer from "../../../../atsDashboard/customTooltip/ATSContainer";
import style from "./empanelment.module.scss";
import searchIcon from "../../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import { customSearch } from "../../../utils/style";
import {
  getAllLead,
  getPartnerReport,
} from "../../../../../Utils/axios/PortalManagement";
import { useQuery } from "react-query";
import moment from "moment";
import { useEffect } from "react";
import {
  handleCurrentPreviousTwoMonths,
  handleFourWeek,
  handleCurrentPreviousThreeYears
} from "../../../../atsDashboard/utils/validation";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import BusinessIcon from "@mui/icons-material/Business";

const EmpanelmentReport = ({ prevData, setPrevData, setNextScreen }) => {
  const [filter, setFilter] = useState({
    leadId: null,
    reportType: 1,
  });
  const [candidateData, setCandidate] = useState([]);
  const [week, setWeek] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([])
  useEffect(() => {
    setMonths(handleCurrentPreviousTwoMonths());
    setWeek(handleFourWeek());
    setYears(handleCurrentPreviousThreeYears());
  }, []);
  const {
    data: partnerData,
    isLoading,
    isError,
  } = useQuery(
    ["getPartnerReport", filter],
    () => filter?.leadId && getPartnerReport(filter?.leadId, filter?.reportType)
  );
  const { data } = useQuery(["getAllLead"], getAllLead);
  const getAllLeadOption =
    data?.length > 0
      ? data?.map((item) => {
        return { value: item?.id, label: item?.name };
      })
      : [];
  const handleColor = (status) => {
    switch (status) {
      case "Inactive":
        return { color: "red", fontWeight: "bold" };
      case "Active":
        return { color: "green", fontWeight: "bold" };
      default:
        return
    }
  };
  const handlePage = (item) => {
    setNextScreen("ViewPartner");
    setPrevData((prevData) => ({
      ...prevData,
      page: "positionALL",
      sidebar: "report",
      page1: "report",
      page2: "empanelmentReport",
      page3: "ViewPartner",
      page4: null,
      active: "ViewPartner",
      partnerId: item?.partnerId,
    }));
  }

  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      >
        <div className={style.topBar}>
          <div
            className={style.s1}
            style={{
              display: "flex",
              width: "31rem",
              justifyContent: "space-between",
            }}
          >
            <div className={style.radioitem}>
              <input
                type="radio"
                id={`Daywise`}
                name={`present`}
                value={1}
                checked={filter?.reportType === 1}
                onChange={() => {
                  setCandidate([]);
                  setFilter((prev) => ({
                    ...prev,
                    reportType: 1,
                  }));
                }}
              />
              <label
                htmlFor={`Daywise`}
                style={{ fontSize: "1rem", color: "#000000" }}
              >
                Daywise
              </label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id={`Weekwise`}
                name={`present`}
                checked={filter?.reportType === 2}
                onChange={() => {
                  setCandidate([]);
                  setFilter((prev) => ({
                    ...prev,
                    reportType: 2,
                  }));
                }}
              />
              <label
                htmlFor={`Weekwise`}
                style={{ fontSize: "1rem", color: "#000000" }}
              >
                Weekwise
              </label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id={`Monthwise`}
                name={`present`}
                checked={filter?.reportType === 3}
                onChange={() => {
                  setCandidate([]);
                  setFilter((prev) => ({
                    ...prev,
                    reportType: 3,
                  }));
                }}
              />
              <label
                htmlFor={`Monthwise`}
                style={{ fontSize: "1rem", color: "#000000" }}
              >
                Monthwise
              </label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id={`Yearwise`}
                name={`present`}
                checked={filter?.reportType === 4}
                onChange={() => {
                  setCandidate([]);
                  setFilter((prev) => ({
                    ...prev,
                    reportType: 4,
                  }));
                }}
              />
              <label
                htmlFor={`Yearwise`}
                style={{ fontSize: "1rem", color: "#000000" }}
              >
                Yearwise
              </label>
            </div>
          </div>
          <div className={style.s2}>
            {filter?.leadId === null ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="searchIcon" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilter({
                    ...filter,
                    leadId: null,
                  });
                  setCandidate([]);
                }}
              >
                <AiOutlineClose />
              </span>
            )}

            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Select Lead"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filter?.leadId
                  ? getAllLeadOption?.find(
                    (e) => e?.value === parseInt(filter?.leadId)
                  )
                  : null
              }
              onChange={(e) => {
                setFilter((prev) => ({ ...prev, leadId: e.value }));
                setCandidate([]);
              }}
              options={getAllLeadOption}
            />
          </div>
        </div>
      </BreadCrum>
      <div>
        {isLoading && <div className={style.no_data}>Loading...</div>}
        {isError && <div className={style.no_data}>Something went wrong</div>}

        {filter?.leadId && filter?.reportType === 1 && (
          <ATSContainer
            styles={{
              maxHeight: "26rem",
              overflowY: "auto",
            }}
          >
            <div>
              <div className={style.th1}>
                <div>Recruiter Name</div>
                <div>
                  Total Number of <br />
                  Empanelment
                  <div>{partnerData?.totalImplement}</div>
                </div>
                {partnerData?.reportData
                  ?.slice(0)[0]
                  ?.dateCount?.map((item) => {
                    return (
                      <div> {moment(item?.date).format("DD MMM (ddd)")}
                        <p>{item.totalCounts}</p>
                      </div>
                    );
                  })}
              </div>

              {!isLoading && !isError ? (
                partnerData?.reportData?.length > 0 ? (
                  partnerData?.reportData?.map((item, key) => {
                    return (
                      <div className={style.td1} key={key}>
                        <div>{item?.empName}</div>
                        <div
                          onClick={() => {
                            setCandidate(item?.totalData);
                          }}
                        >
                          {item?.totalCount > 0 && item?.totalCount}
                        </div>
                        {item?.dateCount?.map((el) => {
                          return (
                            <div
                              onClick={() => {
                                setCandidate(el?.partnerData);
                              }}
                            >
                              {el?.count > 0 && el?.count}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className={style.no_data}>No Data</div>
                )
              ) : null}
            </div>
          </ATSContainer>
        )}
        {filter?.leadId && filter?.reportType === 2 && (
          <ATSContainer
            styles={{
              maxHeight: "26rem",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div>
              <div className={style.weekth1}>
                <div>Recruiter Name</div>
                <div>
                  Total Number of <br />
                  Empanelment
                  <div>{partnerData?.totalImplement}</div>
                </div>
                {partnerData?.reportData
                  ?.slice(0)[0]
                  ?.dateCount?.map((item, idx) => {
                    return (
                      <div>
                        {week[idx]?.label}
                        <p>{item.totalCounts}</p>
                      </div>
                    );
                  })}
              </div>

              {!isLoading && !isError ? (
                partnerData?.reportData?.length > 0 ? (
                  partnerData?.reportData?.map((item, key) => {
                    return (
                      <div className={style.weektd1} key={key}>
                        <div>{item?.empName}</div>
                        <div
                          onClick={() => {
                            setCandidate(item?.totalData);
                          }}
                        >
                          {item?.totalCount > 0 && item?.totalCount}
                        </div>
                        {item?.dateCount?.map((el) => {
                          return (
                            <div
                              onClick={() => {
                                setCandidate(el?.partnerData);
                              }}
                            >
                              {el?.count > 0 && el?.count}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className={style.no_data}>No Data</div>
                )
              ) : null}
            </div>
          </ATSContainer>
        )}
        {filter?.leadId && filter?.reportType === 3 && (
          <ATSContainer
            styles={{
              maxHeight: "26rem",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div>
              <div className={style.monthth1}>
                <div>Recruiter Name</div>
                <div>
                  Total Number of <br />
                  Empanelment
                  <div>{partnerData?.totalImplement}</div>
                </div>
                {partnerData?.reportData
                  ?.slice(0)[0]
                  ?.dateCount?.map((item, idx) => {
                    return (
                      <div>
                        {months[idx]?.month}
                        <p>{item.totalCounts}</p>
                      </div>
                    );
                  })}
              </div>

              {!isLoading && !isError ? (
                partnerData?.reportData?.length > 0 ? (
                  partnerData?.reportData?.map((item, key) => {
                    return (
                      <div className={style.monthd1} key={key}>
                        <p>{item?.empName}</p>
                        <p
                          onClick={() => {
                            setCandidate(item?.totalData);
                          }}
                        >
                          {item?.totalCount > 0 && item?.totalCount}
                        </p>
                        {item?.dateCount?.map((el) => {
                          return (
                            <p
                              onClick={() => {
                                setCandidate(el?.partnerData);
                              }}
                            >
                              {el?.count > 0 && el?.count}
                            </p>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className={style.no_data}>No Data</div>
                )
              ) : null}
            </div>
          </ATSContainer>
        )}
        {filter?.leadId && filter?.reportType === 4 && (
          <ATSContainer
            styles={{
              maxHeight: "26rem",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div>
              <div className={style.monthth1}>
                <div>Recruiter Name</div>
                <div>
                  Total Number of <br />
                  Empanelment
                  <div>{partnerData?.totalImplement}</div>
                </div>
                {partnerData?.reportData
                  ?.slice(0)[0]
                  ?.dateCount?.map((item, idx) => {
                    return (
                      <div>
                        {years[idx]?.year}
                        <p>{item.totalCounts}</p>
                      </div>
                    );
                  })}
              </div>

              {!isLoading && !isError ? (
                partnerData?.reportData?.length > 0 ? (
                  partnerData?.reportData?.map((item, key) => {
                    return (
                      <div className={style.monthd1} key={key}>
                        <p>{item?.empName}</p>
                        <p
                          onClick={() => {
                            setCandidate(item?.totalData);
                          }}
                        >
                          {item?.totalCount > 0 && item?.totalCount}
                        </p>
                        {item?.dateCount?.map((el) => {
                          return (
                            <p
                              onClick={() => {
                                setCandidate(el?.partnerData);
                              }}
                            >
                              {el?.count > 0 && el?.count}
                            </p>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className={style.no_data}>No Data</div>
                )
              ) : null}
            </div>
          </ATSContainer>
        )}
        {candidateData?.length > 0 && (
          <ATSContainer
            styles={{
              maxHeight: "26rem",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <div className={style.table_th}>
              <div>Partner Name </div>
              <div>Location </div>
              <div>Company Size</div>
              <div>Rating</div>
              <div>On Boarding Date</div>
              <div>LinkedIn Url</div>
              <div>Company Url</div>
              <div>Status</div>
            </div>
            {candidateData?.map((item, key) => {
              return (
                <div className={style.table_td} key={`table${key}`}>
                  <div
                    style={{ color: "#C3B14B", textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handlePage(item)}
                  >
                    {item?.partnerName}
                  </div>
                  <div>{item?.location}</div>
                  <div>{item?.companySize}</div>
                  <div>{item?.rating}</div>
                  <div>{moment(item?.onBoardingDate).format("DD MMM YY")}</div>
                  <div>
                    {item?.linkedinLink ? (
                      <a
                        href={item?.linkedinLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedInIcon className={style.linkedin_icon} />
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div>
                    {item?.companyLink ? (
                      <a
                        href={item?.companyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <BusinessIcon className={style.linkedin_icon} />
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div style={handleColor(item?.status)}>{item?.status}</div>
                </div>
              );
            })}
          </ATSContainer>
        )}
      </div>
    </>
  );
};

export default EmpanelmentReport;
