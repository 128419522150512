import React, { useEffect } from "react";
import style from "./Tech.module.css";
import StarRating from "../interview/viewInterview/StartRating";
import { useMutation } from "react-query";
import {
  submitFeedback,
  uploadResume,
  deleteResume,
} from "../../../../Utils/axios/api";
import { useState } from "react";
import moment from "moment";
import Loader from "../../../../GenericComponent/Loader/Loader";
import { Modal } from "react-responsive-modal";
import { ToastifyError } from "../../../../App";
function TechnicalFeedback({
  fieldName,
  interviewId,
  getInterviewBy,
  setCheckSubmit,
  getInterviewTypeByInterview
}) {
  console.log(getInterviewTypeByInterview);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(false);
  const [validate, setValidate] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const demo = fieldName?.map((item) => {
    return {
      fieldName: item.fieldName,
      feedbackName: 0,
    };
  });

  const [feedbackRating, setFeedbackRating] = React.useState([]);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    setFeedbackRating(demo);
  }, [fieldName]);

  const sumbitMutation = useMutation(submitFeedback, {
    onMutate: () => {
      setDisabled(true);
    },
    onSuccess: () => {
      setShow(true);
      setDisabled(false);
      setErr(false);
    },
    onError: () => {
      setShow(true);
      setErr(true);
      setDisabled(false);
    },
  });

  const onSubmitHandler = () => {
    setDisabled(true);
    if (formData?.overAll === undefined || formData?.status === undefined) {
      setValidate(true);
      setDisabled(false);
      return;
    } else {
      setValidate(false);

      setTimeout(() => {
        let feedbackData = [];
        if (formData?.status !== "Did not happen") {
          feedbackData = feedbackRating?.map((item) => {
            return {
              feedbackName: item?.fieldName,
              feedback: item?.feedbackName,
              interviewId: interviewId,
            };
          });
        }

        sumbitMutation.mutate([
          {
            feedbackName: "overall feedback",
            feedback: formData?.overAll,
            interviewId: interviewId,
          },
          {
            feedbackName: "status",
            feedback: formData?.status,
            interviewId: interviewId,
          },
          {
            feedbackName: "uploadSnapshot",
            feedback: uploadImage,
            interviewId: interviewId,
          },
          ...feedbackData,
        ]);
      }, 1000);
    }
  };
  const uploadResumeMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setUploadImage(data.toString());
      setDisabled(false);
    },
  });
  const handleImage = (e) => {
    uploadResumeMutation.mutate(e.target.files[0]);
    setDisabled(true);
  };

  return (
    <div className={style.HrFeedback}>
      {disabled ? <Loader /> : null}


      {getInterviewTypeByInterview?.expired === true ? <h1 style={{ display: 'flex', justifyContent: 'center' }}>Interview Feedback Expired</h1> : <>
        <p>Interviewer Feedback</p>   <p>
          {getInterviewBy?.candidateName} |{" "}
          {/* {getInterviewBy?.interviewer} |{" "} */}
          {getInterviewBy?.profileName} |{" "}
          {moment(getInterviewBy?.dateOfInterView).format("DD MMM YY")}
        </p><div className={style.card}>
          <p className={style.title}>Feedback</p>
          <div className={style.cardDiv}>
            {formData?.status !== "Did not happen" && (
              <>
                <p>
                  Skills Rating (1-10): 1-3 Poor, 4-5 Average, 6-7 Good, 8-10
                  Excellent
                </p>
                {demo?.map((item, index) => {
                  console.log("item", item);
                  console.log("index", index);
                  return (
                    <div key={index} className={style.feedback}>
                      <p>{item.fieldName}</p>
                      <StarRating
                        feedbackRating={feedbackRating}
                        setFeedbackRating={setFeedbackRating}
                        item={item}
                      />
                    </div>
                  );
                })}
                <div className={style.feedback}>
                  <p>Rating</p>
                  <StarRating
                    feedbackRating={feedbackRating}
                    setFeedbackRating={setFeedbackRating}
                    item={{ fieldName: 'Rating', feedbackName: 0 }}
                  />
                </div>
              </>
            )}
            <div className={style.overAllfeedback}>
              <p>
                {formData?.status !== "Did not happen"
                  ? "Overall Feedback"
                  : "Notes"}{" "}
                <span className={style.mand}>*</span>
              </p>
              <textarea
                className={style.textArea}
                placeholder={
                  formData?.status !== "Did not happen"
                    ? "Enter your feedback"
                    : ""
                }
                onChange={(e) => {
                  setFormData({ ...formData, overAll: e.target.value });
                }}
              />
              <p
                className={
                  validate && !formData?.overAll
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                {"Please enter Overall Feedback"}
              </p>
            </div>
            <div className={style.radio}>
              <div>Status:</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test2"
                  name="radio-group"
                  onChange={() =>
                    setFormData({ ...formData, status: "Shortlisted" })
                  }
                />
                <label for="test2">Shortlisted</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test3"
                  name="radio-group"
                  onChange={() =>
                    setFormData({ ...formData, status: "Rejected" })
                  }
                />
                <label for="test3">Rejected</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test4"
                  name="radio-group"
                  onChange={() => setFormData({ ...formData, status: "Hold" })}
                />
                <label for="test4">Hold</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test5"
                  name="radio-group"
                  onChange={() =>
                    setFormData({ ...formData, status: "Did not happen" })
                  }
                />
                <label for="test5">Did not happen</label>
              </div>
            </div>
            <p
              className={
                validate && !formData?.formData?.status
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              {"Please enter Status"}
            </p>
            <div className={style.Cu_row1}>
              <div style={{ width: "10rem" }}>
                <p>Upload Snapshot</p>
              </div>
              {uploadImage ? (
                <div className={style.imageupload}>
                  <label for="file-input">
                    <img
                      id="image"
                      src={uploadImage}
                      alt="resumeName"
                      style={{
                        width: "4rem",
                        height: "3rem",
                        border: "1px solid black",
                        cursor: "pointer",
                      }}
                    // onClick={() => setShowImageProfile(true)}
                    />
                  </label>
                  <p
                    onClick={() => {
                      // setFormData({ ...formData, resumeUrl: "" });
                      setUploadImage(null);
                      deleteResume(uploadImage);
                    }}
                    className={style.rmpdfcross}
                  >
                    x
                  </p>
                </div>
              ) : (
                <div style={{ width: "22rem" }}>
                  <input
                    id="file-input"
                    className={style.upload_resume}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleImage(e);
                    }}
                  />
                </div>
              )}
            </div>
            <div className={style.warn}>
              <p>
                <span className={style.mand}>*</span>NS - Candidate does not have
                skill
              </p>
              <p>
                <span className={style.mand}>*</span>NE - Skill not evaluated by
                interviwer
              </p>
            </div>
          </div>
        </div>
        <div className={style.btn}>
          {/* <button className={style.CancelBtn}>Cancel</button> */}
          <button className={style.SumbitBtn} onClick={onSubmitHandler}>
            Submit Feedback
          </button>
        </div>
        <Modal
          open={show}
          center
          onClose={() => setShow(false)}
          showCloseIcon={false}
          classNames={{
            modal: "reactmodal",
          }}
        >
          <div className={style.modal}>
            <p>
              {err
                ? "Internal Server Error."
                : "Feedback Submitted Successfully."}
            </p>
            <button
              onClick={() => {
                setShow(false);
                // window.open("about:blank", "_self");
                // window.close();
                setCheckSubmit(true);
              }}
            >
              OK
            </button>
          </div>
        </Modal>
      </>
      }


      {/*  */}

      {/*  */}

    </div>
  );
}

export default TechnicalFeedback;
