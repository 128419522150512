import React, { useEffect, useState } from "react";
import classes from "./ProjectLead.module.css";
import { Col, Container, Row } from "react-bootstrap";
import searchIcon from "../../../../assets/Icon-search.png";
import Select from "react-select";
import { customSearch } from "../../utils/style";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import CustomTooltip from "../../../PartnerManagement/customTooltip/CustomTooltip.js";
import { MdOutlineInsertComment } from "react-icons/md";
import { Modal } from "react-responsive-modal";
import { FaLinkedin } from "react-icons/fa";
import { BiPlusCircle } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import moment from "moment";

import {
  validateEmployeeAccess,
  ProjectLeadsData,
} from "../../../../Utils/axios/PortalManagement.js";
import {
  getLeadLocationList,
  getLeadSourceList,
  getLeadStatusList,
  getPartnerNameList,
  getPartnerRecruiterList1,
} from "../../../../Utils/axios/api";
import Pagination from "../../CommonComponent/pagination/pagination.js";
import TotalCount from "./../../TotalCount/TotalCount";
import ExcelFileDownloadPartner from "../ExcelFileDownloadPartner/ExcelFileDownloadPartner";
import { employeeId } from "../../../../Utils/constants";
import { Toastify, ToastifyError } from "../../../../App.js";
import CommentHistoryPartnerLead from "../../CommonComponent/CommentNew/CommentHistory/CommentHistoryPartnerLead.js";

const PartnerList = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const history = useHistory();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [myPositionId, setMyPositionId] = useState(null);
  const [myPartnerName, setMyPartnerName] = useState(null)
  const [selectedData, setSelectedData] = useState({});
  const [filter, setFilter] = useState(
    prevData?.PLFilter
      ? prevData?.PLFilter
      : {
          source: "",
          location: "",
          status: "",
          ta: "",
          partnerName: "",
        }
  );
  const { data: getValidateEmp } = useQuery(["validateEmployeeAccess"], () =>
    validateEmployeeAccess(parseInt(employeeId))
  );

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  useEffect(() => {
    setPrevData((prevData) => ({
      ...prevData,
      PLFilter: filter,
    }));
  }, [filter]);
  const [filterName, setFilterName] = useState({});
  const onSearchHandler = (e, option) => {
    setCurrentPage(1);
    if (e || e === "") {
      switch (option) {
        case "Status":
          setFilter({ ...filter, status: e?.value });
          break;
        case "Location":
          setFilter({ ...filter, location: e?.value });
          break;
        case "Source":
          setFilter({ ...filter, source: e?.value });
          break;
        case "TA":
          setFilter({ ...filter, ta: e?.value });
          break;
        case "partnerName":
          setFilter({...filter, partnerName: e?.label });
          break;
        default:
      }
    } else {
      setFilter({
        source: "",
        location: "",
        status: "",
        ta: "",
      });
      setFilterName({
        source: "",
        location: "",
        status: "",
        ta: "",
      });
    }
  };
  const { data, isLoading, isError, refetch } = useQuery(
    ["ProjectLeadsData", { currentPage, filter }],
    () => ProjectLeadsData(10, currentPage, filter)
  );

  useEffect(() => {
    if (data) {
      const totalPages = Math.ceil(data.totalCount / 10);
      const newSelectData = Array(totalPages).fill({
        isSelectAll: false,
        selected: [],
      });
      if (!selectedData?.data) {
        setSelectedData({ data: newSelectData, totalSelected: [] });
      }
    }
  }, [data]);

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const { data: partnerList } = useQuery(
    ["getPartnerNameList"],
    getPartnerNameList
  );

  const partnerOptions = partnerList?.map((item, index)=>({
    value : index,
    label : item,
  }))

  const { data: statusList } = useQuery(
    ["getLeadStatusList"],
    getLeadStatusList
  );

  const statusOptions = statusList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const { data: sourceList } = useQuery(
    ["getLeadSourceList"],
    getLeadSourceList
  );

  const sourceOptions = sourceList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const { data: locationList } = useQuery(
    ["getLeadLocationList"],
    getLeadLocationList
  );

  const locationOptions = locationList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const { data: recruiterList } = useQuery(
    ["getPartnerRecruiterList1"],
    getPartnerRecruiterList1
  );

  const recruiterOptions = recruiterList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const handleLinkedin = (linkedInUrl) => {
    window.open(linkedInUrl, "_blank");
  };

  function getWeekNumber(dateString) {
    const date = new Date(dateString);
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  }

  return (
    <>
      <>
        <Container fluid>
          <div className={classes.partnertop}>
            {/* <div className={classes.topBar}>
              <div className={classes.sEmpName}>
                {filter?.cityId === "" ? (
                  <span className={classes.cp_EmpName}>
                    <img src={searchIcon} alt="" />
                  </span>
                ) : (
                  <span
                    className={classes.cp_EmpName}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        cityId: "",
                      });
                      setFilterName({ ...filterName, cityId: "" });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <input
                  style={{ width: '127px', height: '37px',color:'black' }}
                  classNamePrefix={"create_position"}
                  placeholder="Location"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    filter?.cityId
                      ? cityOption?.find((e) => e?.value === filter?.cityId)
                      : null
                  }
                  onChange={(e) => onSearchHandler(e, "cityId")}
                  options={cityOption}
                />
              </div>
            </div>
            <div className={classes.topBar}>
              <div className={classes.sEmpName}>
                {filter?.cityId === "" ? (
                  <span className={classes.cp_EmpName}>
                    <img src={searchIcon} alt="" />
                  </span>
                ) : (
                  <span
                    className={classes.cp_EmpName}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        cityId: "",
                      });
                      setFilterName({ ...filterName, cityId: "" });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <input
                  style={{ width: '127px', height: '37px' }}
                  classNamePrefix={"create_position"}
                  placeholder="Location"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    filter?.cityId
                      ? cityOption?.find((e) => e?.value === filter?.cityId)
                      : null
                  }
                  onChange={(e) => onSearchHandler(e, "cityId")}
                  options={cityOption}
                />
              </div>
            </div> */}
            <div className={classes.leftHeader}>
              <div className={classes.partnerlist}>
                <h6 className={classes.manage}>Partner Leads</h6>
                {getVData?.some((e) => {
                  return e?.label === "329X7157D19173YX";
                }) && (
                  <div className={classes.createPartner}>
                    <div
                      onClick={() => {
                        setNextScreen("CreateList");
                        setPrevData((prevData) => ({
                          ...prevData,
                          page2: "CreateList",
                          page3: null,
                          page4: null,
                          active: "CreateList",
                        }));
                      }}
                      className={classes.add_btn}
                    >
                      +
                    </div>
                  </div>
                )}
                <TotalCount totalCount={data?.totalCount} m={"0"} />
                <div>
                  <ExcelFileDownloadPartner
                    ml={"0"}
                    totalCount={data?.totalCount}
                    filter={filter}
                  />
                </div>
              </div>
            </div>
            <div className={classes.rightHeader}>
            <div className={classes.topBarSKill}>
                <div className={classes.s1}>
                  {filter?.partnerName === "" ? (
                    <span className={classes.cp_Skill}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_Skill}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          partnerName: "",
                        });
                        setFilterName({ ...filterName, partnerName: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Partner Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.partnerName
                        ? partnerOptions?.find(
                            (e) => e?.value === filter?.partnerName
                          )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "partnerName")}
                    options={partnerOptions}
                  />
                </div>
              </div>
              <div className={classes.topBarSKillNew}>
                <div style={{ position: "relative" }} className={classes.s1}>
                  {filter?.ta === "" ? (
                    <span
                      style={{
                        right: "6%",
                        top: "14%",
                        position: "absolute",
                      }}
                      className={classes.cp_status}
                    >
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_status}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          ta: "",
                        });
                        setFilterName({ ...filterName, ta: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="T.A."
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.ta
                        ? recruiterOptions?.find((e) => e?.value === filter?.ta)
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "TA")}
                    options={recruiterOptions}
                  />
                </div>
              </div>
              <div className={classes.topBarSKill}>
                <div className={classes.s1}>
                  {filter?.status === "" ? (
                    <span className={classes.cp_Skill}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_Skill}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          status: "",
                        });
                        setFilterName({ ...filterName, status: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Status"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.status
                        ? statusOptions?.find(
                            (e) => e?.value === filter?.status
                          )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "Status")}
                    options={statusOptions}
                  />
                </div>
              </div>
              <div className={classes.topBarName}>
                <div className={classes.sEmpName}>
                  {filter?.source === "" ? (
                    <span className={classes.cp_EmpName}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_EmpName}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          source: "",
                        });
                        setFilterName({ ...filterName, source: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Source"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.source
                        ? sourceOptions?.find(
                            (e) => e?.value === filter?.source
                          )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "Source")}
                    options={sourceOptions}
                  />
                </div>
              </div>
              <div className={classes.topBarName}>
                <div className={classes.sEmpName}>
                  {filter?.location === "" ? (
                    <span className={classes.cp_EmpName}>
                      <img src={searchIcon} alt="" />
                    </span>
                  ) : (
                    <span
                      className={classes.cp_EmpName}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          location: "",
                        });
                        setFilterName({ ...filterName, location: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Location"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filter?.location
                        ? locationOptions?.find(
                            (e) => e?.value === filter?.location
                          )
                        : null
                    }
                    onChange={(e) => onSearchHandler(e, "Location")}
                    options={locationOptions}
                  />
                </div>
              </div>
            </div>
          </div>
          <Row>
            {/* <Col md={12} style={{ marginTop: "8px" }}>
              
            </Col> */}
            <Col md={12}>
              <InvoiceContainer styles={{ overflow: "hidden" }}>
                <div className={classes.table}>
                  <div className={classes.th}>
                    <p>Partner Name</p>
                    <p></p>
                    <p>Created On</p>
                    <p>Week</p>
                    {/* <p>Source Of Lead</p> */}
                    <p>T.A.</p>
                    <p>Company Size</p>
                    <p>Status</p>
                    <p>Source</p>
                    <p>Location</p>
                    <p></p>
                  </div>
                  <div>
                    {isLoading && (
                      <p className={classes.no_data}>Loading .....</p>
                    )}
                    {!isLoading && !isError ? (
                      data?.partnerLeads?.length > 0 ? (
                        data?.partnerLeads?.map((item, index) => {
                          return (
                            <div
                              className={classes.td}
                              key={item?.id}
                              style={{ position: "relative" }}
                            >
                              <p
                                onClick={() => {
                                  setNextScreen("ViewCreateProject");
                                  setPrevData((prevData) => ({
                                    ...prevData,
                                    page1: "ViewCreateProject",
                                    page2: null,
                                    page3: null,
                                    page4: null,
                                    active: "ViewCreateProject",
                                    CreateId: item?.id,
                                  }));
                                }}
                              >
                                <CustomTooltip
                                  value={item?.partnerName}
                                  limit={20}
                                  index={`partnerName${index}`}
                                />
                              </p>
                              <p>{item?.statusId===13 ? <BiPlusCircle
                                  onClick={() => {
                                    setNextScreen("CreatePartnerByLead");
                                    setPrevData((prevData) => ({
                                      ...prevData,
                                      item: item,
                                      page1: "ProjectLead",
                                      page2: "CreatePartnerByLead",
                                      page3: null,
                                      page4: null,
                                      active: "CreatePartnerByLead",
                                    }));
                                  }}
                                  title="Create Partner"
                                  style={{
                                    color: "#C3B14B",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    fontSize: "18px",
                                  }}
                                />:<></>}</p>
                              <p>
                                <MdOutlineInsertComment
                                  style={{
                                    cursor: "pointer",
                                    height: "13.5px",
                                    marginLeft: "1px",
                                    color: "black",
                                  }}
                                  onClick={() => {
                                    setShowCommentModal(true);
                                    setMyPositionId(item?.id);
                                    setMyPartnerName(item?.partnerName)
                                  }}
                                  title={" Add Comment For Partner"}
                                />
                              </p>
                              <p>{moment(item.createdAt).format("DD MMM YY")}</p>
                              <p>
                                {item?.createdAt &&
                                  getWeekNumber(item?.createdAt)}
                              </p>
                              {/* <CustomTooltip
                                value={item?.sourceOfLead}
                                limit={20}
                                index={`sourceOfLead${index}`}
                              /> */}
                              <CustomTooltip
                                value={item?.talentAcquisitionName}
                                limit={20}
                                index={`talentAcquisitionName${index}`}
                              />
                              <p>{item?.companySize}</p>
                              <CustomTooltip
                                value={item?.statusName}
                                limit={20}
                                index={`statusName${index}`}
                              />
                              <CustomTooltip
                                value={item?.sourceName}
                                limit={20}
                                index={`sourceName${index}`}
                              />
                              <CustomTooltip
                                value={item?.locationName}
                                limit={20}
                                index={`locationName${index}`}
                              />
                              <p>
                                <FaLinkedin
                                  onClick={() =>
                                    handleLinkedin(item?.linkedInUrl)
                                  }
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                  }}
                                />
                                
                              </p>
                            </div>
                          );
                        })
                      ) : (
                        <p className={classes.no_data}>No Data Found</p>
                      )
                    ) : null}
                  </div>
                </div>
              </InvoiceContainer>
            </Col>
          </Row>
        </Container>
        <div className={classes.pagination}>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagesCount={data?.totalCount / 10}
            color="#C3B14B"
            color2="white"
          />
        </div>
        <Modal
        open={showCommentModal}
        onClose={() => {
          setShowCommentModal(false);
        }}
        showCloseIcon={true}
        center
      >
        <div className={classes.modal_container_watchlist2}>
          <div className={classes.watchlist_inner_container}>
            <CommentHistoryPartnerLead
              setShowCommentModal={setShowCommentModal}
              partnerId={myPositionId}
              partnerName={myPartnerName}
            />
          </div>
        </div>
      </Modal>
      </>

      
    </>
  );
};

export default PartnerList;
