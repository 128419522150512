import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import style from "../../filter/Filter.module.css";
import { useQuery } from "react-query";
import { getAllType, getAllFunctionalArea } from "../../../../Utils/axios/umsApi";
import "../../filter/style.css"

const PmsFilter = ({
    setShowFilter,
    showFilter,
    filterData,
    setFilterData,
    clearFilterAll
}) => {

    const [selectData, setSelectData] = useState({
        functionalArea: [...filterData?.functionalArea],
        employeeType: [...filterData?.employeeType]
    });

    const [funcZero, setFuncZero] = useState(false);
    const [empZero, setEmpZero] = useState(false);

    
    const { data: getAllFunctionalData } = useQuery(["getAllFunctionalArea"], getAllFunctionalArea, {
            refetchOnWindowFocus: false,
        }
      );
      const getFunctionalOption = getAllFunctionalData?.map((e) => ({
        value: e.id,
        label: e.name,
      }));
      getFunctionalOption?.unshift({value: 0, label: "All"})
  

      const { data: getAllTypeData } = useQuery(["getAllType"], getAllType, {
        refetchOnWindowFocus: false,
      });
      const getAllTypeOption = getAllTypeData?.map((e) => ({
        value: e.id,
        label: e.name,
      }));
      getAllTypeOption?.unshift({value: 0, label: "All"})


      const handleCheckboxChange = (e, type)=>{
        const checking = parseInt(e.target.value);
        let val=[];
        if(checking!==0){
            val = [parseInt(e.target.value)]
        }
        
        if(type==="funcArea"){
            if(checking===0){
                setFuncZero(prev=>!prev)
                getFunctionalOption.forEach((ele)=>{
                    if(ele.value!==0) val.push(parseInt(ele.value))
                })
            }
            if(e.target.checked){
                let temp = [...selectData.functionalArea];
                val.forEach((ele)=>{
                    if(!temp.includes(ele)){
                        temp.push(ele)
                    }
                })
                setSelectData((prev)=>({
                    ...prev,
                    functionalArea : temp
                }))
            }
            else{
                setFuncZero(false)
                let temp = [...selectData.functionalArea];
                temp = temp.filter(value=> !val.includes(value));
                setSelectData((prev)=>({
                    ...prev,
                    functionalArea : temp
                }))
            }
        }
        else if(type==="allType"){
            if(checking===0){
                setEmpZero(prev=>!prev)
                getAllTypeOption.forEach((ele)=>{
                    if(ele.value!==0) val.push(parseInt(ele.value))
                })
            }
            if(e.target.checked){
                
                let temp = [...selectData.employeeType];
                val.forEach((ele)=>{
                    if(!temp.includes(ele)){
                        temp.push(ele)
                    }
                })
                setSelectData((prev)=>({
                    ...prev,
                    employeeType : temp
                }))
            }
            else{
                setEmpZero(false)
                let temp = [...selectData.employeeType];
                temp = temp.filter(value=> !val.includes(value));
                setSelectData((prev)=>({
                    ...prev,
                    employeeType : temp
                }))
            }
        }
                
      }

      const filterUpdateFuc = ()=>{
        setFilterData((prev)=>({
            ...prev,
            functionalArea : selectData.functionalArea,
            employeeType : selectData.employeeType
        }))
        setShowFilter(false)
      }
  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.filterModal}>
            <div className={style.filterCards}>
            
              <div className={style.firstCard}>
                <div className={style.filte_heading}>Functional Area</div>
                <div
                  style = {{
                    margin: "5px 10px",
                    overflow: "auto",
                    height: "30vh",
                  }}
                >
                    
                  {getFunctionalOption?.map((item) => (
                    <div
                      key={item.value}
                      style={{ display: "flex", gap: "20px", width: "15vw" }}
                    >
                      <input
                        type="checkbox"
                        value={item.value}
                        name="checkbox"
                        checked={selectData?.functionalArea?.includes(item.value) || parseInt(item.value)===0 && funcZero}
                        onChange={(e) => handleCheckboxChange(e, "funcArea")}
                        style={{ visibility: "visible" }}
                      />
                      <label style={{color: "black"}}>{item.label}</label>
                    </div>
                  ))}</div></div>

            <div className={style.firstCard}>
                <div className={style.filte_heading}>Employee Type</div>
                <div
                  style={{
                    margin: "5px 10px",
                    overflow: "auto",
                    height: "30vh",
                  }}
                >

                  {getAllTypeOption?.map((item) => (
                    <div
                      key={item.value}
                      style={{ display: "flex", gap: "20px", width: "15vw" }}
                    >
                      <input
                        type="checkbox"
                        value={item.value}
                        name="checkbox"
                        checked={selectData.employeeType?.includes(item.value) || parseInt(item.value)===0 && empZero}
                        onChange={(e) => handleCheckboxChange(e, "allType")}
                        style={{ visibility: "visible" }}
                      />
                      <label style={{color: "black"}}>{item.label}</label>
                    </div>
                  ))}</div></div>
                </div>
              </div>
              <div className={style.filterBtn}>
                <button
                    className={style.cancelBtn}
                    onClick={() => {
                        clearFilterAll();
                        setSelectData({
                            functionalArea:[],
                            employeeType:[]
                        })
                        setShowFilter(false)
                        setFuncZero(false);
                        setEmpZero(false);
                    }}
                >
                    Clear All
                </button>
                <button
                    className={style.createBtn}
                    onClick={() => {
                    filterUpdateFuc();
                    }}
                >
                    Apply
                </button>
            </div>
      </Modal>
    </>
  )
}

export default PmsFilter