import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import style from "./Filter.module.css";
import "./style.css";
import MonthYearPicker from "./MonthYearPicker";
import calender from "../../../assets/calblue.png";
import * as Img from "../../../assets";
import DatePicker from "react-datepicker";
import moment from "moment";

const FilterModal = ({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  firstCardlist,
  secondCardlist,
  thirdCard,
  thirdCardlist,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
  forthCard,
  forthCardlist,
  fiveCardlist,
  fiveCard,
  SixCardlist,
  SixCard,
  sevenCard,
  sevenCardlist,
  Eightcard,
  Eightcardlist,
  Ninecard,
  Ninecardlist,
  TenthCard,
  EleventhCard,
  EleventhCardList,
  disabled,
  date,
  setDate,
}) => {
  const [selectData, setSelectData] = useState({
    allocated: [...filterData?.allocated],
    functionalArea: [...filterData?.functionalArea],
    leadId: [...filterData?.leadId],
    clientId: [...filterData?.clientId],
    employeeType: [...filterData?.employeeType],
    salaryRange: [...filterData?.salaryRange],
    status: [...filterData?.status],
  });
  const [technology, setTechnology] = useState(false);
  const [funcZero, setFuncZero] = useState(false);
  const [lead, setLead] = useState(false);
  const [client, setClient] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [salary, setSalary] = useState(false);
  const [statusArea, setStatusArea] = useState(false);
  const [abc, setAbc] = useState(true);

  const handleCheckBox = (e, status) => {
    console.log(e, status);    
    const checking = parseInt(e.target.value);
    let val = [];
    if (checking !== 0 && status!==firstCard) {
      val = [checking];
    } else if(status==firstCard && checking!==2){
        val = [checking];
    } 
    switch (status) {
      case firstCard:

        // let data = filterData;
        // if (data.status.includes(Number(e.target.value))) {
        //   let index = data.status.indexOf(Number(e.target.value));
        //   data.status.splice(index, 1);
        //   return setFilterData({ ...filterData, status: data.status });
        // } else {
        //   data.status.push(Number(e.target.value));
        //   return setFilterData({ ...filterData, status: data.status });
        // }

        // PMS Filter
        if(checking == 2){
          setStatusArea(prev=>!prev)
          firstCardlist.forEach((ele, index)=>{
              if(index!==0) 
                val.push(parseInt(index+2))
          })
        }
        if(e.target.checked){
            let temp = [...selectData?.status];
            val.forEach((ele)=>{
                if(!temp.includes(ele)){
                    temp.push(ele)
                }
            })
            setSelectData((prev)=>({
                ...prev,
                status : temp
            }))
        }
        else{
            setStatusArea(false)
            let temp = [...selectData.status];
            temp = temp.filter(value=> !val.includes(value));
            setSelectData((prev)=>({
                ...prev,
              status : temp
          }))
        } 
        break
      case secondCard:
        // let data2 = filterData;
        // if (data2.salaryRange.includes(Number(e.target.value))) {
        //   let index = data2.salaryRange.indexOf(Number(e.target.value));
        //   data2?.salaryRange?.splice(index, 1);
        //   return setFilterData({
        //     ...filterData,
        //     salaryRange: data2.salaryRange,
        //   });
        // } else {
        //   data2.salaryRange.push(Number(e.target.value));
        //   return setFilterData({
        //     ...filterData,
        //     salaryRange: data2.salaryRange,
        //   });
        // }
        if(checking===0){
          setTechnology(prev=>!prev)
          secondCardlist.forEach((ele)=>{
              if(ele.value!==0) 
                val.push(parseInt(ele.value))
          })
        }
        if(e.target.checked){
            let temp = [...selectData.allocated];
            val.forEach((ele)=>{
                if(!temp.includes(ele)){
                    temp.push(ele)
                }
            })
            setSelectData((prev)=>({
                ...prev,
                allocated : temp
            }))
        }
        else{
            setTechnology(false)
            let temp = [...selectData.allocated];
            temp = temp.filter(value=> !val.includes(value));
            setSelectData((prev)=>({
                ...prev,
              allocated : temp
          }))
        } 
        break
      case thirdCard:
        // let data3 = filterData;
        // if (data3.employeeType.includes(Number(e.target.value))) {
        //   let index = data3.employeeType.indexOf(Number(e.target.value));
        //   data3?.employeeType?.splice(index, 1);
        //   return setFilterData({
        //     ...filterData,
        //     employeeType: data3.employeeType,
        //   });
        // } else {
        //   data3.employeeType.push(Number(e.target.value));
        //   return setFilterData({
        //     ...filterData,
        //     employeeType: data3.employeeType,
        //   });
        // }
        if(checking===0){
          setEmployee(prev=>!prev)
          thirdCardlist.forEach((ele)=>{
              if(ele.value!==0) 
                val.push(parseInt(ele.value))
          })
        }
        if(e.target.checked){
            let temp = [...selectData.employeeType];
            val.forEach((ele)=>{
                if(!temp.includes(ele)){
                    temp.push(ele)
                }
            })
            setSelectData((prev)=>({
                ...prev,
                employeeType : temp
            }))
        }
        else{
            setEmployee(false)
            let temp = [...selectData.employeeType];
            temp = temp.filter(value=> !val.includes(value));
            setSelectData((prev)=>({
                ...prev,
              employeeType : temp
          }))
        } 
        break
      case forthCard:
        // let data4 = filterData;
        // if (data4.salaryRange.includes(Number(e.target.value))) {
        //   let index = data4.salaryRange.indexOf(Number(e.target.value));
        //   data4?.salaryRange?.splice(index, 1);
        //   return setFilterData({
        //     ...filterData,
        //     salaryRange: data4.salaryRange,
        //   });
        // } else {
        //   data4.salaryRange.push(Number(e.target.value));
        //   return setFilterData({
        //     ...filterData,
        //     salaryRange: data4.salaryRange,
        //   });
        // }
        if(checking===0){
          setSalary(prev=>!prev)
          forthCardlist.forEach((ele)=>{
              if(ele.value!==0) 
                val.push(parseInt(ele.value))
          })
        }
        if(e.target.checked){
            let temp = [...selectData.salaryRange];
            val.forEach((ele)=>{
                if(!temp.includes(ele)){
                    temp.push(ele)
                }
            })
            setSelectData((prev)=>({
                ...prev,
                salaryRange : temp
            }))
        }
        else{
            setSalary(false)
            let temp = [...selectData.salaryRange];
            temp = temp.filter(value=> !val.includes(value));
            setSelectData((prev)=>({
                ...prev,
              salaryRange : temp
          }))
        } 
        break
      case fiveCard:
        if(checking===0){
          setFuncZero(prev=>!prev)
          fiveCardlist.forEach((ele)=>{
              if(ele.value!==0) 
                val.push(parseInt(ele.value))
          })
        }
        if(e.target.checked){
            let temp = [...selectData.functionalArea];
            val.forEach((ele)=>{
                if(!temp.includes(ele)){
                    temp.push(ele)
                }
            })
            setSelectData((prev)=>({
                ...prev,
                functionalArea : temp
            }))
        }
        else{
            setFuncZero(false)
            let temp = [...selectData.functionalArea];
            temp = temp.filter(value=> !val.includes(value));
            setSelectData((prev)=>({
                ...prev,
              functionalArea : temp
          }))
        } 
        break
      case SixCard:
        // let data6 = filterData;
        // if (data6?.leadId?.includes(Number(e.target.value))) {
        //   let index = data6.leadId.indexOf(Number(e.target.value));
        //   data6?.leadId?.splice(index, 1);
        //   return setFilterData({
        //     ...filterData,
        //     leadId: data6.leadId,
        //   });
        // } else {
        //   data6?.leadId?.push(Number(e.target.value));
        //   return setFilterData({
        //     ...filterData,
        //     leadId: data6.leadId,
        //   });
        // }
        if(checking===0){
          setLead(prev=>!prev)
          SixCardlist.forEach((ele)=>{
              if(ele.value!==0) 
                val.push(parseInt(ele.value))
          })
        }
        if(e.target.checked){
            let temp = [...selectData.leadId];
            val.forEach((ele)=>{
                if(!temp.includes(ele)){
                    temp.push(ele)
                }
            })
            setSelectData((prev)=>({
                ...prev,
                leadId : temp
            }))
        }
        else{
            setLead(false)
            let temp = [...selectData.leadId];
            temp = temp.filter(value=> !val.includes(value));
            setSelectData((prev)=>({
                ...prev,
              leadId : temp
          }))
        }
        break
      case sevenCard:
        // let data7 = filterData;
        // if (data7?.clientId?.includes(Number(e.target.value))) {
        //   let index = data7.clientId.indexOf(Number(e.target.value));
        //   data7?.clientId?.splice(index, 1);
        //   return setFilterData({
        //     ...filterData,
        //     clientId: data7.clientId,
        //   });
        // } else {
        //   data7?.clientId?.push(Number(e.target.value));
        //   return setFilterData({
        //     ...filterData,
        //     clientId: data7.clientId,
        //   });
        // }
        if(checking===0){
          setClient(prev=>!prev)
          sevenCardlist.forEach((ele)=>{
              if(ele.value!==0) 
                val.push(parseInt(ele.value))
          })
        }
        if(e.target.checked){
            let temp = [...selectData.clientId];
            val.forEach((ele)=>{
                if(!temp.includes(ele)){
                    temp.push(ele)
                }
            })
            setSelectData((prev)=>({
                ...prev,
                clientId : temp
            }))
        }
        else{
            setClient(false)
            let temp = [...selectData.clientId];
            temp = temp.filter(value=> !val.includes(value));
            setSelectData((prev)=>({
                ...prev,
                clientId : temp
          }))
        }
        break
      case Eightcard:
        let data8 = filterData;
        if (data8?.experienceRange?.includes(Number(e.target.value))) {
          let index = data8?.experienceRange.indexOf(Number(e.target.value));
          data8?.experienceRange?.splice(index, 1);
          return setFilterData({
            ...filterData,
            experienceRange: data8?.experienceRange,
          });
        } else {
          data8?.experienceRange?.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            experienceRange: data8?.experienceRange,
          });
        }
      case EleventhCard:
        let data11 = filterData;
        if(data11?.location?.includes(e.target.value)){
          let index = data11?.location?.indexOf(e.target.value);
          data11?.location?.splice(index, 1);
          return setFilterData({
            ...filterData,
            location: data11?.location
          })
        } else {
          data11?.location?.push(e.target.value)
          return setFilterData({
            ...filterData,
            location: data11?.location
          })
        }

      default:
        return;
    }
  };

  // const isAllChecked = allStatus.length === filterData.status.length;

  const filterUpdateFunc = ()=>{
    setFilterData((prev)=>({
        ...prev,
        allocated : selectData.allocated,
        functionalArea : selectData.functionalArea,
        leadId: selectData.leadId,
        clientId: selectData.clientId,
        employeeType: selectData.employeeType,
        salaryRange: selectData.salaryRange,
        status: selectData.status,
    }))
    setShowFilter(false);
    setAbc((prev)=>!prev)
  }

  useEffect(()=>{
    filterUpdateFuc()
  },[abc])

  const handleRadioChange = (e) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      pfApplicable: e.target.value, // Update the state with the selected value
    }));
  };

  const handleMonthChange = (selectedDate) => {
    setDate(selectedDate)
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      month: selectedDate?.getMonth() + 1,
      year: selectedDate?.getFullYear(),
    }));
  };

  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.filterModal}>
          <div className={style.filterCards}>
            {secondCard && secondCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{secondCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {secondCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={item.value}
                          value={item.value}
                          checked={selectData?.allocated?.includes(item.value) || parseInt(item.value)===0 && technology}
                          onChange={(e) => handleCheckBox(e, secondCard)}
                        />
                        <label
                          htmlFor={item.value}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {fiveCard && fiveCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{fiveCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {fiveCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`F${key}`}
                          value={Number(item.value)}
                          checked={selectData?.functionalArea?.includes(Number(item.value)) || parseInt(item.value)===0 && funcZero}
                          onChange={(e) => handleCheckBox(e, fiveCard)}
                        />
                        <label
                          htmlFor={`F${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {SixCard && SixCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{SixCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {SixCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`L${key}`}
                          value={Number(item.value)}
                          checked={selectData?.leadId?.includes(Number(item.value)) || parseInt(item.value)===0 && lead}
                          onChange={(e) => handleCheckBox(e, SixCard)}
                        />
                        <label
                          htmlFor={`L${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {sevenCard && sevenCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sevenCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {sevenCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`CI${key}`}
                          value={Number(item.value)}
                          checked={selectData?.clientId?.includes(Number(item.value)) || parseInt(item.value)===0 && client}
                          onChange={(e) => handleCheckBox(e, sevenCard)}
                        />
                        <label
                          htmlFor={`CI${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {thirdCard && thirdCardlist && (
              <div className={style.firstCard} style={{ width: "12rem" }}>
                <div className={style.filte_heading}>{thirdCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {thirdCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={`Emp${key}`}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`EMP${key}`}
                          value={Number(item.value)}
                          checked={selectData?.employeeType?.includes(Number(item.value)) || parseInt(item.value)===0 && employee}
                          onChange={(e) => handleCheckBox(e, thirdCard)}
                        />
                        <label
                          htmlFor={`EMP${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {forthCard && forthCardlist && (
              <div className={style.firstCard} style={{ width: "12rem" }}>
                <div className={style.filte_heading}>{forthCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {forthCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`S${key}`}
                          value={Number(item.value)}
                          checked={selectData?.salaryRange?.includes(Number(item.value)) || parseInt(item.value)===0 && salary}
                          onChange={(e) => handleCheckBox(e, forthCard)}
                        />
                        <label
                          htmlFor={`S${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {firstCard && (
              <div className={style.firstCard} style={{ width: "13rem" }}>
                <div className={style.filte_heading}>{firstCard}</div>
                {firstCardlist?.map((item, key) => {
                  return (
                    <div className={style.list} key={key}>
                      {console.log(selectData?.status)}
                      <input
                        type="checkbox"
                        className={style.vh}
                        id={item}
                        value={key + 2}
                        checked={selectData?.status?.includes(key + 2) || parseInt(key)+2===2 && statusArea}
                        onChange={(e) => handleCheckBox(e, firstCard)}
                        disabled={disabled}
                      />
                      <label
                        htmlFor={item}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={style.label}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
            {Eightcard && (
              <div className={style.firstCard} style={{ width: "12rem" }}>
                <div className={style.filte_heading}>{Eightcard}</div>
                {Eightcardlist?.map((item, key) => {
                  return (
                    <div className={style.list} key={`experience${key}`}>
                      <input
                        type="checkbox"
                        className={style.vh}
                        id={item}
                        value={key + 1}
                        checked={selectData?.experienceRange?.includes(key + 1)}
                        onChange={(e) => handleCheckBox(e, Eightcard)}
                      />
                      <label
                        htmlFor={item}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={style.label}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
            <div className={style.firstCard} style={{ width: "12rem" }}>
              <div className={style.filte_heading}>{Ninecard}</div>
              <div className={style.listRadio}>
                <input
                  type="radio"
                  id={1}
                  value={true}
                  checked={filterData?.pfApplicable === "true"}
                  onChange={(e) => handleRadioChange(e)}
                  className={style.customRadioInput}
                />
                <label htmlFor={"Yes"} className={style.customRadioLabel}>
                  Yes
                </label>
              </div>
              <div className={style.listRadio}>
                <input
                  type="radio"
                  id={2}
                  value={false}
                  checked={filterData?.pfApplicable === "false"}
                  onChange={(e) => handleRadioChange(e)}
                  className={style.customRadioInput}
                />
                <label htmlFor={"No"} className={style.customRadioLabel}>
                  No
                </label>
              </div>
            </div>
            {TenthCard && (
              <div className={style.firstCard} style={{ width: "12rem", alignItems: "center" }}>
                <div className={style.filte_heading}>{TenthCard}</div>
                {/* <MonthYearPicker monthSelected={filterData?.month} yearSelected={filterData?.year} onChange={(date)=>handleMonthChange(date)}/> */}

                <div className={style.date}>
                  <DatePicker
                    className={style.datePicker}
                    placeholderText="Select Date"
                    onChange={handleMonthChange} // Provide the onChange handler
                    showMonthYearPicker
                    showFullMonthYearPicker
                    customInput={
                      date ? (
                        <CustomInputCross
                          setSelectedDate={setDate}
                          setFilter={() => {
                            setFilterData((prevFilterData) => ({
                              ...prevFilterData,
                              month:null,
                              year: null,
                            }));
                          }}
                          selectedDate={date}
                          placeholder="Select Date"
                          icon={calender}
                        />
                      ) : (
                        <CustomInputClg icon={calender} />
                      )
                    }
                  />
                </div>
              </div>
            )}
            {EleventhCard && <div className={style.firstCard} style={{ width: "12rem"}}>
                <div className={style.filte_heading}>{EleventhCard}</div>
                {EleventhCardList?.map((item, key)=>{
                  return <div className={style.list} key={`location${key}`}>
                  <input
                    type="checkbox"
                    className={style.vh}
                    id={item}
                    value={item}
                    checked={filterData?.location?.includes(item)}
                    onChange={(e) => handleCheckBox(e, EleventhCard)}
                  />
                  <label
                    htmlFor={item}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={style.label}
                  >
                    {item}
                  </label>
                </div>
                })}
              </div>}
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
              setSelectData({
                allocated:[],
                functionalArea:[],
                leadId:[],
                clientId: [],
                employeeType: [],
                salaryRange: [],
                status: []
              })
              setShowFilter(false)
              setTechnology(false);
              setFuncZero(false);
              setLead(false);
              setClient(false);
              setEmployee(false);
              setSalary(false);
              setStatusArea(false);
            }}
          >
            Clear All
          </button>
          <button className={style.createBtn} 
            onClick={() => filterUpdateFunc()}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
}

export default FilterModal;

export const CustomInputCross = React.forwardRef(
  ({ setSelectedDate, selectedDate, placeholder, onClick, icon , setFilter}, ref) => {
    return (
      <>
        <span
          onClick={onClick}
          ref={ref}
          style={{
            cursor: "pointer",
            fontWeight: "400",
          }}
        >
          {selectedDate ? moment(selectedDate).format("MM/YYYY") : placeholder}
        </span>
        <img
          src={Img.cross}
          width="8px"
          height="8px"
          alt="Cross"
          style={{
            position: "absolute",
            right: "23%",
            top: "44%",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedDate(null);
            setFilter()
          }}
          onKeyDown={() => {
            setSelectedDate(null);
            setFilter()
          }}
          tabIndex={0}
        />
        <img
          onClick={onClick}
          src={icon}
          alt="calender"
          style={
            icon === "cc" || "pm"
              ? {
                  position: "absolute",
                  right: "5%",
                  top: "10%",
                  cursor: "pointer",
                  width: "20px",
                }
              : {
                  position: "absolute",
                  right: "5%",
                  top: "10%",
                  cursor: "pointer",
                }
          }
        />
      </>
    );
  }
);

export const CustomInputClg = React.forwardRef((props, ref) => {
  return (
    <div onClick={props.onClick} onKeyDown={props.onClick}>
      <span
        ref={ref}
        style={{
          cursor: "pointer",
          fontWeight: "400",
        }}
      >
        {props.value || props.placeholder}
      </span>
      <img
        src={props.icon}
        alt="calender"
        style={
          props.icon === "cc" || "pm"
            ? {
                position: "absolute",
                right: "5%",
                top: "10%",
                cursor: "pointer",
                width: "20px",
              }
            : {
                position: "absolute",
                right: "5%",
                top: "10%",
                cursor: "pointer",
              }
        }
      />
    </div>
  );
});
