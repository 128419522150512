import * as XLSX from "xlsx";
import React from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { getAllUsersWithFilter } from "../../../../Utils/axios/umsApi";
import moment from "moment";
import { useQuery } from "react-query";
import {
  handleExperiece,
  ExitDicisionPlanDate,
  ExitPlanDate,
  BondDate,
  ReginationData,
  ExitDate,
  RetainDate,
  ExperienceDuration,
} from "./../umsUtills/umsUtills";
import Loader from "../../../../GenericComponent/Loader/Loader";
const ExcelFileDownload = ({ filter }) => {
  const {isLoading, refetch } = useQuery(
    ["getAllUsersWithFilter1", { filter }],
    () => getAllUsersWithFilter(1, 1, filter),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        let data = [...res?.employeeResponseModels];
        let mainArray = [];
        const checkLength = (data) => {
          if (data?.length > 0) {
            return data?.length;
          } else {
            return 1;
          }
        };
        data.forEach((item, i) => {
          let newJsonData = [];
          const mainObject = (data) => {
            let json = {};
            for (let key in data) {
              if (!Array.isArray(data[key]) || key === "additionalSkill") {
                json[key] = data[key];
              }
            }
            return json;
          };
          const mergeData = (first = [], second = []) => {
            let arrays = [];
            for (
              let i = 0;
              i < Math.max(checkLength(first), checkLength(second));
              i++
            ) {
              let educationObj = first[i] || {};
              let workExpObj = second[i] || {};
              let firstobj = i == 0 ? mainObject(item) : {};
              let obj = { ...firstobj, ...educationObj, ...workExpObj };
              arrays.push(obj);
            }
            return arrays;
          };

          let ExpEducation = [
            ...mergeData(
              item?.educationResponseModels,
              item?.workExpResponseModels
            ),
          ];
          newJsonData.push(
            ...mergeData(
              ExpEducation,
              item.bgvResponseModels?.map((e) => ({
                bgvCompanyName: e?.companyName,
                feedbackStatus: e?.feedbackStatus,
                bgvstatus: e?.bgvstatus,
                bgvDocUrl: e?.bgvDocUrl,
                comments: e?.comments,
              }))
            )
          );
          mainArray.push(...newJsonData);
        });
        handleDownloadButtonClick(mainArray);
      },
    }
  );

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Type",
        "Code",
        "Name",
        "Email",
        "Contact Number",
        "WhatsApp Contact Number",
        "Career Start Date",
        "Experience",
        "Relevant Experience",
        "Date of Joining",
        "Technology",
        "Additional Skill",
        "Salary Range",
        "Designation",
        "Functional Area",
        "Assigned Lead",
        "Assigned Client",
        "Exit Decision Date",
        "Exit Planned Date",
        "Exit Note",
        "Notes",
        "Resignation Date",
        "Exit Date",
        "Bond Date",
        "Retain Date",
        "Date of Birth",
        "Gender",
        "Marital Status",
        "Marriage Anniversary",
        "Confirmation Date",
        "Period",
        "LinkedIn",
        "Folder URL",
        "Company Name",
        "From",
        "To",
        "Duration",
        "Role",
        "Reason For Change",
        "Degree",
        "Passing Year",
        "Grade",
        "Board of Education/University",
        "BGV Applicable",
        "Company Name",
        "Status",
        "Positive/Negative",
        "Upload Doc",
        "Comments",
        "Fixed Salary (As on Date)",
        "SA1",
        "SA2",
        "Incentives",
        "Variable Bonus",
        "Joining Bonus",
        "Retention Bonus",
        "Others",
        "Current Yearly Core CTC",
        "Current Monthly Full CTC",
        "Current Yearly Full CTC",
        "Salary ( As per Project & Costing )",
        "Salary Note",
      ], // Header row
      ...data.map((val) => [
        val.type,
        val.code,
        val.name,
        val.email,
        val.number,
        val.whatsappNumber,
        val.careerStartDate && moment(val.careerStartDate).format("DD MMM YY"),
        handleExperiece(val.totalExperience),
        handleExperiece(val.relevantExperience),
        val.dateOfJoining && moment(val.dateOfJoining).format("DD MMM YY"),
        val?.technology,
        val?.additionalSkill?.map((e) => e?.additionalSkillName).join(", "),
        val?.salaryRangeName,
        val?.employeeDesignation,
        val?.functionalAreaName,
        val?.assignedLead,
        val?.clientName,
        ExitDicisionPlanDate(val?.exitDecisionDate),
        ExitPlanDate(val?.exitPlanDate),
        val?.exitComment,
        val?.note,
        ReginationData(val?.resignStatus, val?.resignationDate),
        ExitDate(!val?.active, val?.lastWorkingDayDate),
        BondDate(val.bond, val?.bondDate),
        RetainDate(val.retain, val?.retainDate),
        val?.dob ? moment(val?.dob).format("DD MMM YY") : "",
        val?.gender,
        val?.maritalStatus,
        val?.marriageAnniversary
          ? moment(val?.marriageAnniversary)?.format("DD MMM YY")
          : "",
        val?.confirmationDate
          ? moment(val?.confirmationDate)?.format("DD MMM YY")
          : "",
        val?.period,
        val?.linkedInUrl,
        val?.folderUrl,
        val?.companyName,
        val.startDate && moment(val.startDate).format("DD MMM YY"),
        val?.presentlyWorking
          ? "Till Date"
          : val.endDate && moment(val.endDate).format("DD MMM YY"),
        ExperienceDuration(val.expDurationYear, val.expDurationMonth),
        val?.role,
        val?.reasonForChange,
        val?.degree,
        val?.passingYear,
        val?.grade &&
          `${val?.grade}${val?.gradeStatus === "CGPA" && " CGPA"}${
            val?.gradeStatus === "Percentage" && "%"
          }`,
        val?.board,
        val?.isBGVeligible?"Yes":"No",
        val?.bgvCompanyName,
        val?.bgvstatus,
        val?.hasOwnProperty("feedbackStatus")
          ? val?.feedbackStatus
            ? "Positive"
            : "Negative"
          : "",
        val?.bgvDocUrl,
        val?.comments,
        val?.salary,
        val?.sa1,
        val?.sa2,
        val?.incentives,
        val?.variableBonus,
        val?.joiningBonus,
        val?.retentionBonus,
        val?.others,
        val?.currentYearlyCoreCTC,
        val?.currentMonthlyFullCTC,
        val?.currentYearlyFullCTC,
        val?.reviseSalaryAsProjectCosting,
        val?.salaryNotes,
      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);
    downloadExcelFile(workbook);
  }
  return (
    <div>
    {isLoading && <Loader />}   
      <FiDownload
        style={{ verticalAlign: "baseline" }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default ExcelFileDownload;
