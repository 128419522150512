import React, { useEffect, useState } from "react";
import SideBar from "../sidebar/SideBar";
import style from "./AtsNavigation.module.css";
import ProgressBar from "../progressbar/ProgressBar";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import { getEmpRoleByEmpId } from "../../../Utils/axios/api";
import { lazyLoad } from "../../../GenericComponent/lazyLoad/LazyLoadCommon";
import DuplicityCheck from "../pageLayout/duplicityCheck/DuplicityCheck";
import { employeeId, localStorageGetItem } from "../../../Utils/constants";
import { getUserUiElementAccess } from "./../../../Utils/axios/api";
import { ValidateRedirect } from "../pageLayout/validationEmpAccess/ValidationEmpAccess";
import MyWatchlist from "../pageLayout/myWatchlist/MyWatchlist";
import MyInterviews from "../pageLayout/myInterview/MyInterview";
import InActivePositionReport from "../pageLayout/PositionReport/InActivePositionReport/InActivePositionReport";
import BlackListedCompany from "../pageLayout/BlackListCompany/BlackListedCompany";
import RecruiterDashBoard from "../pageLayout/recruiterDashBoard/RecruiterDashBoard";
import ReportNewDashboard from "../pageLayout/PositionReport/ReportNewDashboard/index.js";
import { useNavigation } from "../../Projects_and_Resource_Master/Resources/PoolResources/ResourceOnsiteDescription/NavigationContext.js";
const EditPosition = lazyLoad(() =>
  import("../pageLayout/editPosition/EditPosition")
);
const CreatePosition = lazyLoad(() =>
  import("../pageLayout/createPosition/CreatePosition")
);
const ViewPosition = lazyLoad(() =>
  import("../pageLayout/viewPosition/ViewPosition")
);
const Allocation = lazyLoad(() =>
  import("../pageLayout/Allocation/Allocation")
);
const CreateCandidate = lazyLoad(() =>
  import("../pageLayout/createCandidate/CreateCandidate")
);
const NoteCandidate = lazyLoad(() =>
  import("../pageLayout/createCandidate/NoteCandidate")
);
const ViewCandidate = lazyLoad(() =>
  import("../pageLayout/viewCandidate/ViewCandidate")
);
const EditCandidate = lazyLoad(() =>
  import("../pageLayout/editCandidate/EditCandidate")
);
const ListOfProfile = lazyLoad(() =>
  import("../pageLayout/listOfProfile/ListOfProfile")
);
const Confirmation = lazyLoad(() =>
  import("../pageLayout/confirmation/Confirmation")
);
const Confirm2 = lazyLoad(() =>
  import("../pageLayout/createPosition/confirmation/Confirmation")
);
const ViewCandidateTwo = lazyLoad(() =>
  import("../pageLayout/viewCandidateTwo/ViewCandidateTwo")
);
const PositionListTwo = lazyLoad(() =>
  import("../pageLayout/positionListTwo/PositionListTwo")
);
const CandidateOffer = lazyLoad(() =>
  import("../pageLayout/candidateOffer/CandidateOffer")
);
const Menu = lazyLoad(() =>
  import("../pageLayout/Menu/Menu.js")
);
const ViewOffer = lazyLoad(() => import("../pageLayout/viewOffer/ViewOffer"));
const EditOffer = lazyLoad(() => import("../pageLayout/editOffer/EditOffer"));
const CreateOffer = lazyLoad(() =>
  import("../pageLayout/createOffer/CreateOffer")
);
const CandidateList = lazyLoad(() =>
  import("../pageLayout/candidateList/CandidateList")
);
const ViewInterview = lazyLoad(() =>
  import("../pageLayout/interview/viewInterview/ViewInterview")
);
const ViewAssessment = lazyLoad(() =>
  import("../pageLayout/viewAssessment/ViewAssessment")
);
const MyInterviewList = lazyLoad(() =>
  import("../pageLayout/interview/myInterviewList/MyInterviewList")
);
const CandidateAssessment = lazyLoad(() =>
  import(
    "../pageLayout/interview/candidateAssessmentList/CandidateAssessmenList"
  )
);
const ScheduleInterview = lazyLoad(() =>
  import("../pageLayout/interview/scheduleInterview/ScheduleInterview")
);
const Scheduleinterview = lazyLoad(() =>
  import(
    "../pageLayout/interview/scheduleInterview/confirmationSchedule/ScheduleInterview"
  )
);
const ListOfCandidate = lazyLoad(() =>
  import("../pageLayout/joinigProcess/listofCandidate/ListOfCandidate")
);
const CreateJoingProcess = lazyLoad(() =>
  import("../pageLayout/joinigProcess/createJoiningProcess/CreateJoinigProcess")
);
const EditJoingProcess = lazyLoad(() =>
  import("../pageLayout/joinigProcess/editJoinigProcess/EditJoiningProcess")
);
const JoingProcess = lazyLoad(() =>
  import("../pageLayout/joinigProcess/joinigProcess/JoinigProcess")
);
const ViewJoing = lazyLoad(() =>
  import("../pageLayout/joinigProcess/viewJoing/ViewJoing")
);
const CandidateFeedBackByID = lazyLoad(() =>
  import("../pageLayout/interview/candidateFeedbackByID/CandidateFeedBackByID")
);
const EditScheduleInterview = lazyLoad(() =>
  import(
    "../pageLayout/interview/scheduleInterview/EditScheduleInterview/EditScheduleInterview"
  )
);
const ClientList = lazyLoad(() =>
  import("../pageLayout/ClientManagement/clientsList/Clients")
);
const CreateClient = lazyLoad(() =>
  import("../pageLayout/ClientManagement/createClient/CreateClient")
);
const EditClient = lazyLoad(() =>
  import("../pageLayout/ClientManagement/editClient/EditClient")
);
const ViewClient = lazyLoad(() =>
  import("../pageLayout/ClientManagement/viewClient/ViewClient")
);
const Confirm3 = lazyLoad(() =>
  import(
    "../pageLayout/ClientManagement/createClient/Confirmation/Confirmation"
  )
);
const CreatePartner = lazyLoad(() =>
  import("../pageLayout/PartnerManagement/createPartner/CreatePartner")
);
const EditPartner = lazyLoad(() =>
  import("../pageLayout/PartnerManagement/editPartner/EditPartner")
);
const ViewPartner = lazyLoad(() =>
  import("../pageLayout/PartnerManagement/partnerView/ViewPartner")
);
const Confirm4 = lazyLoad(() =>
  import(
    "../pageLayout/PartnerManagement/createPartner/Confirmation/Confirmation"
  )
);
const Partner = lazyLoad(() =>
  import("../pageLayout/PartnerManagement/partnerList/Partner")
);
const CandidateAllocationList = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/candidateAllocationList/CandidateAllocationList"
  )
);
const CandidateAllocationView = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/viewCandidateAllocation/ViewCandidateAllocation"
  )
);
const AddAllocationStatus = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/addAllocation/AddAllocationStatus"
  )
);
const ViewAllocation = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/viewAllocation/ViewAllocation"
  )
);
const EditAllocation = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/editAllocation/EditAllocation"
  )
);
const ListOfProfileInClient = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/listOfProfileInClient/ListOfProfileInClient"
  )
);
const ClientViewCandidate = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/listOfProfileInClient/ClientViewCandidate/ClientViewCandidate"
  )
);
const ClientPositionList = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/listOfProfileInClient/ClientPositionList/ClientPositionList"
  )
);
const ClientConfirmation = lazyLoad(() =>
  import(
    "../pageLayout/candidateAllocation(Client)/listOfProfileInClient/ClientCanfirmation"
  )
);
const CurrentAllocation = lazyLoad(() =>
  import("../pageLayout/CurrentAllocation/CurrentAllocation")
);
const CurrentCandidateAllocation = lazyLoad(() =>
  import("../pageLayout/currentCandidateAllocation/CurrentCandidateAllocation")
);
const OfferProcess = lazyLoad(() =>
  import("../pageLayout/offerProcess/OfferProcess")
)
const CandidateOfferRelease = lazyLoad(() =>
  import("../pageLayout/candidateOfferRelease/CandidateOfferRelease")
);
const GetFeedById = lazyLoad(() =>
  import("../pageLayout/interview/GetFeedById/GetFeedById")
);
const NonClientCandidateList = lazyLoad(() =>
  import("../pageLayout/NonClientCandidateList/NonClientCandidateList")
);
const ReportCandidateList = lazyLoad(() =>
  import("../pageLayout/report/reportCandidateList/ReportCandidateList")
);
const CandidateMovement = lazyLoad(() =>
  import("../pageLayout/report/candidateMovement/CandidateMovement")
);
const SearchPosition = lazyLoad(() =>
  import("../pageLayout/PositionReport/SearchPosition/SearchPosition")
);
const ReportCard = lazyLoad(() =>
  import("../pageLayout/PositionReport/PositionReport/ReportCard")
);
const SourcingNumberReport = lazyLoad(() =>
  import(
    "../pageLayout/PositionReport/SourcingNumberReport/SourcingNumberReport"
  )
);
const ClientServiceReport = lazyLoad(() =>
  import("../pageLayout/PositionReport/clientServiceReport/ClientServiceReport")
);

const PositionHealthReport = lazyLoad(() =>
  import("../pageLayout/PositionReport/PositionHealthReport/ClientServiceReport")
)
const DetailsSoucingReportNew = lazyLoad(() =>
  import(
    "../pageLayout/PositionReport/detailsSourcingReportTest/DetailsSoucingReportNew"
  )
);

const DetailsSoucingReport = lazyLoad(() =>
  import(
    "../pageLayout/PositionReport/detailsSourcingReport/DetailsSoucingReport"
  )
);

const PositionReport = lazyLoad(() =>
  import("../pageLayout/PositionReport/newPositionReport/PositionReport")
);
const BGVList = lazyLoad(() =>
  import("../pageLayout/backgroundVarification/BGVList/BGVList")
);
const CreateBGV = lazyLoad(() =>
  import("../pageLayout/backgroundVarification/CreateBGV/CreateBGV")
);
const SourcingPerformanceReport = lazyLoad(() =>
  import(
    "./../pageLayout/PositionReport/sourcingPerformanceReport/SourcingPerformanceReport"
  )
);

const SourcingPerformanceReportNew = lazyLoad(() =>
  import(
    "./../pageLayout/PositionReport/sourcingPerformanceReportNew/SourcingPerformanceReport"
  )
);
const ClosureReport = lazyLoad(() =>
  import("../pageLayout/PositionReport/closureReport/ClosureReport")
);
const SourcingVsShared = lazyLoad(() =>
  import("../pageLayout/PositionReport/sourcingVsShared/SourcingVsShared")
);
const ResourceAvailability = lazyLoad(() =>
  import(
    "../pageLayout/PositionReport/resourceAvailability/ResourceAvailability"
  )
);
const DeploymentReport = lazyLoad(() =>
  import("../pageLayout/PositionReport/deploymentReport/DeploymentReport")
);
const AvailablePosition = lazyLoad(() =>
  import("./../pageLayout/PositionReport/availablePosition/AvailablePosition")
);
const JDShare = lazyLoad(() => import("../pageLayout/viewPosition/JDShare"));
const PositionList = lazyLoad(() =>
  import("../pageLayout/PositionList/PositionList")
);
const PartnerPerformanceReport = lazyLoad(() =>
  import(
    "./../pageLayout/PositionReport/partnerPerformanceReport/PartnerPerformanceReport"
  )
);
const ReportNew = lazyLoad(() =>
  import(
    "../pageLayout/PositionReport/reportNew/Index"
  )
);

const TestSourcingReportNew = lazyLoad(() =>
  import(
    "../pageLayout/PositionReport/testReportNew/Index"
  )
);
const SourcingReportDetails = lazyLoad(() =>
  import(
    "../pageLayout/PositionReport/SourcingReportDetails/index.js"
  )
);

const ATSDatabase = lazyLoad(() =>
  import("../pageLayout/PositionReport/ATSDatabase/ATSDatabase")
);

const JobApplicantList = lazyLoad(() =>
  import("../pageLayout/jobApplicant/jobApplicantList/index.js")
);
const CreateJobApplicant = lazyLoad(() =>
  import("../pageLayout/jobApplicant/createJobApplicant/CreateJobApplicant.js")
);
export default function AtsNavigation() {
  const { data } = useQuery(
    ["getEmpRole"],
    () => getEmpRoleByEmpId(employeeId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: validateAccess } = useQuery(
    ["getUserUiElementAccess"],
    () => getUserUiElementAccess(employeeId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { nextScreen, setNextScreen, prevData, setPrevData, poolCondition, setPoolCondition } = useNavigation();
  const poolResources = localStorage.getItem("pool_resources")



  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState([]);


  useEffect(() => {
    if (poolCondition && validateAccess?.menuElementCodeResponse?.length > 0) {
      const redirectJSON = ValidateRedirect(
        validateAccess?.menuElementCodeResponse[0]?.elementCode
      );
      if (
        redirectJSON?.nextScreen === sessionStorage.getItem("redirectCheck")
      ) {
        return;
      } else {
        setNextScreen(redirectJSON?.nextScreen);
        setPrevData(redirectJSON?.prevData);
        sessionStorage.setItem("redirectCheck", redirectJSON?.nextScreen);
      }
    }
  }, [validateAccess?.menuElementCodeResponse]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setRoles(data);
    }
  }, [data]);

  const sidebarHandler = () => {
    sessionStorage.setItem("nextScreen", nextScreen);
    JSON?.stringify(
      sessionStorage.setItem("prevData", JSON?.stringify(prevData))
    );
    if(prevData?.active!=="myInterviewList"){window.scrollTo({
      top: 0,
      behavior: "auto", // Add smooth scrolling behavior if you prefer
    });}

    // console.log("nextScreen....",nextScreen)

    switch (nextScreen) {
      case "recruiterDashBoard":
        return (
          <RecruiterDashBoard
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            roles={roles}
          />
        );
      case "Menu":
        return (
          <Menu
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            roles={roles}
          />
        );
      case "positionList":
        return (
          <PositionList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            roles={roles}
          />
        );
      case "PositionList ":
        return (
          <PositionListTwo
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            roles={roles}
          />
        );
      case "Watchlist":
        return (
          <MyWatchlist
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
          />
        );
      case "editPosition":
        return (
          <EditPosition
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
          />
        );
      case "candidateList":
        return (
          <CandidateList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "nonClientCandidateList":
        return (
          <NonClientCandidateList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "createPosition":
        return (
          <CreatePosition
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "viewPosition":
        return (
          <ViewPosition
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            roles={roles}
          />
        );
      case "CurrentAllocation":
        return (
          <CurrentAllocation
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            roles={roles}
          />
        );
      case "createCandidate":
        return (
          <CreateCandidate
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
          />
        );
      case "noteCandidate":
        return (
          <NoteCandidate
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
          />
        );
      case "ViewCandidate":
        return (
          <ViewCandidate
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            roles={roles}
          />
        );
      case "ViewCandidate ":
        return (
          <ViewCandidateTwo
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
          />
        );
      case "EditCandidate":
        return (
          <EditCandidate
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
          />
        );
      case "currentCandidateAllocation":
        return (
          <CurrentCandidateAllocation
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            roles={roles}
          />
        );
      case "List ofProfile":
        return (
          <ListOfProfile
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
          />
        );
      case "Confirmation_noheader":
        return (
          <Confirmation
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "Confirm2_noheader":
        return (
          <Confirm2
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );

      case "myCandidateOfferProcess":
        return (
          <OfferProcess
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "myCandidateFinalStages":
        return (
          <CandidateOfferRelease
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "CandidateOfferScreen":
        return (
          <CandidateOffer
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );

      case "EditOffer":
        return (
          <EditOffer
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "CreateOffer":
        return (
          <CreateOffer
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "viewOffer":
        return (
          <ViewOffer
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "viewInterview":
        return (
          <ViewInterview
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "viewAssessment":
        return (
          <ViewAssessment
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "myInterviewList":
        return (
          <MyInterviewList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "candidateAssessmentList":
        return (
          <CandidateAssessment
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "scheduleInterview":
        return (
          <ScheduleInterview
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );

      case "ScheduleInterview":
        return (
          <Scheduleinterview
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "createJoingProcess":
        return (
          <CreateJoingProcess
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "editJoingProcess":
        return (
          <EditJoingProcess
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "joingProcess":
        return (
          <JoingProcess
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "viewJoing":
        return (
          <ViewJoing
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );

      case "CandidateFeedBackByID":
        return (
          <CandidateFeedBackByID
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );

      case "myTasks":
        return (
          <ListOfCandidate
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "EditScheduleInterview":
        return (
          <EditScheduleInterview
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "clients":
        return (
          <ClientList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "createClient":
        return (
          <CreateClient
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "Confirm3_noheader":
        return (
          <Confirm3
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "editClient":
        return (
          <EditClient
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "viewClient":
        return (
          <ViewClient
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "partners":
        return (
          <Partner
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "createPartner":
        return (
          <CreatePartner
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "Confirm4_noheader":
        return (
          <Confirm4
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "editPartner":
        return (
          <EditPartner
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "viewPartner":
        return (
          <ViewPartner
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "candidateAllocation(Client)":
        return (
          <CandidateAllocationList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "viewCandidateAllocation(client)":
        return (
          <CandidateAllocationView
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "addAllocationStatus":
        return (
          <AddAllocationStatus
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "viewAllocation":
        return (
          <ViewAllocation
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "editAllocation":
        return (
          <EditAllocation
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "ListOfProfileInClient":
        return (
          <ListOfProfileInClient
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "ClientViewCandidate":
        return (
          <ClientViewCandidate
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "ClientPositionList":
        return (
          <ClientPositionList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "ClientConfirmation":
        return (
          <ClientConfirmation
            setNextScreen={setNextScreen}
            ClientConfirmation
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "Allocation":
        return (
          <Allocation
            setNextScreen={setNextScreen}
            ClientConfirmation
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "GetFeedById":
        return (
          <GetFeedById
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "reportCandidateList":
        return (
          <ReportCandidateList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "candidateMovement":
        return (
          <CandidateMovement
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "positionWise":
        return (
          <SearchPosition
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );

      case "report":
        return (
          <ReportCard
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
          />
        );
      case "sourcingReport":
        return (
          <SourcingNumberReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "positionHealthReport":
        return (
          <ClientServiceReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "positionHealthReportNew":
        return (
          <PositionHealthReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "ActiveRecruiterPipelineReport":
        return (
          <DetailsSoucingReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "positionReport ":
        return (
          <PositionReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );

      case "BGV":
        return (
          <BGVList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "addBGV":
        return (
          <CreateBGV
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "sourcingPerformanceMatrix":
        return (
          <SourcingPerformanceReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );

      case "sourcingPerformanceMatrixNew":
        return (
          <SourcingPerformanceReportNew
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "closureReport":
        return (
          <ClosureReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "sourcingVsShared":
        return (
          <SourcingVsShared
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "resourceAvailability":
        return (
          <ResourceAvailability
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "deploymentReport":
        return (
          <DeploymentReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "positionMapping":
        return (
          <AvailablePosition
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "JDShare":
        return (
          <JDShare
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "duplicityCheck":
        return (
          <DuplicityCheck
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "partnerPipelineReport":
        return (
          <PartnerPerformanceReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "ATSDatabase":
        return (
          <ATSDatabase
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "myInterviews":
        return (
          <MyInterviews
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "InActivePositionsReport":
        return (
          <InActivePositionReport
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "blacklistedCompany":
        return (
          <BlackListedCompany
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );

      case "reportNew":
        return (
          <ReportNewDashboard
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      // Sourcing Report New
      // case "sourcingReportNew":
      //   return (
      //     <ReportNew
      //       setNextScreen={setNextScreen}
      //       prevData={prevData}
      //       validateAccess={validateAccess?.elementCodeResponse}
      //       setPrevData={setPrevData}
      //       roles={roles}
      //     />
      //   );

      // Sourcing Report New Test
      case "sourcingReportSummary":
        return (
          <TestSourcingReportNew
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "DetailedSourcingReport":
        return (
          <SourcingReportDetails
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );

      case "ActiveRecruiterPipelineReportNew":
        return (
          <DetailsSoucingReportNew
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );

      case "jobApplicants":
        return (
          <JobApplicantList
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      case "createJobApplicant":
        return (
          <CreateJobApplicant
            setNextScreen={setNextScreen}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            setPrevData={setPrevData}
            roles={roles}
          />
        );
      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      {/* <Header />*/}
      <div className={style.mainSection}>
        <div style={{ width: "20%" }}>
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            roles={roles}
            menuAccess={validateAccess?.menuElementCodeResponse}
          />
        </div>
        <div className={style.ATScontainer}>
          <ProgressBar
            prevData={prevData}
            validateAccess={validateAccess?.elementCodeResponse}
          />

          {sidebarHandler()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
