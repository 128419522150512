import axios from "axios";
import cookie from "react-cookies";
import { employeeId } from "../../../Utils/constants";
const apiKey = process.env.REACT_APP_EPR_APP_URL;
const apiKey1 = process.env.REACT_APP_EMPLOYEEONBOARDING_APP_URL;
const apiKey2 = process.env.REACT_APP_RESOURCEMASTER_APP_URL;
const apiKey3 = process.env.REACT_APP_UMS_APP_URL;
const apiKey4 = process.env.REACT_APP_PARTNER_MANAGEMENT;
let token = cookie.load("token");
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const PerformanceReviewList = async (
  limit,
  page,
  empId,
  empName,
  empLeadId,
  functionalFieldId,
  reportStatus,
  reviewMonth,
  reviewSourceType,
  reviewType,
  employeeId
) => {
  let queryParams = `limit=${limit}&page=${page}&reportStatus=${reportStatus}&requesterId=${employeeId}`;

  if (functionalFieldId) {
    console.log(functionalFieldId)
    queryParams += `&functionalFieldId=${functionalFieldId}`;
  }
  if (empLeadId) {
    console.log(empLeadId)
    queryParams += `&empLeadId=${empLeadId}`;
  }
  if(empId){
    console.log(empId)
    queryParams += `&empId=${empId}`;
  }
  if(empName){
    console.log(empName)
    queryParams += `&empName=${empName}`;
  }
  console.log(queryParams);

  if (reviewMonth && reviewMonth.length > 0) {
    const monthParams = reviewMonth
      .map((month) => `&reviewMonth=${month}`)
      .join("");
    queryParams += monthParams;
  }

  if (reviewSourceType && reviewSourceType.length > 0) {
    const sourceTypeParams = reviewSourceType
      .map((type) => `&reviewSourceType=${type}`)
      .join("");
    queryParams += sourceTypeParams;
  }

  if (reviewType && reviewType.length > 0) {
    const reviewTypeParams = reviewType
      .map((type) => `&reviewType=${type}`)
      .join("");
    queryParams += reviewTypeParams;
  }

  const response = await axios.get(
    `${apiKey}/getPerformanceReview?${queryParams}`
  );
  return response.data.responseData;
};
export const PerformanceReviewCasesList = async (
  limit,
  page,
  empId,
  empName,
  empLeadId,
  functionalFieldId,
  reportStatus,
  reviewMonth,
  reviewSourceType,
  reviewType,
  employeeId
) => {
  let queryParams = `limit=${limit}&page=${page}&reportStatus=${reportStatus}&requesterId=${employeeId}`;

  if (functionalFieldId) {
    console.log(functionalFieldId)
    queryParams += `&functionalFieldId=${functionalFieldId}`;
  }
  if (empLeadId) {
    console.log(empLeadId)
    queryParams += `&empLeadId=${empLeadId}`;
  }
  if(empId){
    console.log(empId)
    queryParams += `&empId=${empId}`;
  }
  if(empName){
    console.log(empName)
    queryParams += `&empName=${empName}`;
  }
  console.log(queryParams);
  

  if (reviewMonth && reviewMonth.length > 0) {
    const monthParams = reviewMonth
      .map((month) => `&reviewMonth=${month}`)
      .join("");
    queryParams += monthParams;
  }

  if (reviewSourceType && reviewSourceType.length > 0) {
    const sourceTypeParams = reviewSourceType
      .map((type) => `&reviewSourceType=${type}`)
      .join("");
    queryParams += sourceTypeParams;
  }

  if (reviewType && reviewType.length > 0) {
    const reviewTypeParams = reviewType
      .map((type) => `&reviewType=${type}`)
      .join("");
    queryParams += reviewTypeParams;
  }
  const response = await axios.get(
    `${apiKey}/getEmployeeInReviewCases?${queryParams}`
  );
  return response.data.responseData;
};

export const getReviewTypesInListing = async () => {
  const response = await axios.get(
    `${apiKey}/get-review-types-used-in-listing`
  );
  return response.data.responseData;
};

export const getReviewMonthListing = async () => {
  const response = await axios.get(
    `${apiKey}/get-review-months-used-in-listing`
  );
  return response.data.responseData;
};

export const PerformanceReviewList2 = async () => {
  const response = await axios.get(`${apiKey}/get-performance-report`);
  return response.data.responseData;
};

export const getReviewType = async (id) => {
  const response = await axios.get(`${apiKey}/getReview?id=${id}`);
  return response.data.responseData;
};

export const getReviewSourcType = async () => {
  const response = await axios.get(`${apiKey}/getReviewSourceType`);
  return response.data.responseData;
};

export const getEmployeeList = async () => {
  const response = await axios.get(`${apiKey1}/getEmployeeList`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};

export const getAllEmployeeList = async () => {
  const response = await axios.get(`${apiKey}/get-all-employees`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};

export const getEmployeeInformation = async () => {
  const response = await axios.get(`${apiKey}/get-active-employee-details`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: employeeId,
    },
  });
  return response.data.responseData;
};

export const createPerformanceFileType = async (data) => {
  const response = await axios.post(
    `${apiKey}/createPerformanceFileType`,
    data
  );
  return response.data.responseData;
};

export const createPerformanceReviewRating = async (data) => {
  const response = await axios.post(
    `${apiKey}/createPerformanceReviewRating`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: employeeId,
      },
    }
  );
  return response.data;
};

export const createPerformanceReviewSubjectiveType = async (data) => {
  const response = await axios.post(
    `${apiKey}/createPerformanceReviewSubjectiveType`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: employeeId,
      },
    }
  );
  return response.data;
};

export const createPerformanceReviewTest = async (data) => {
  const response = await axios.post(
    `${apiKey}/createPerformanceReviewTest`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: employeeId,
      },
    }
  );
  return response.data;
};

export const getSkills = async () => {
  const response = await axios.get(`${apiKey}/getSkills`);
  return response.data.responseData;
};

export const getClientForFilter = async () => {
  const response = await axios.get(`${apiKey2}/getClientForFilter`);
  return response.data.responseData;
};

export const getFoxMatrixInterviewers = async () => {
  const response = await axios.get(`${apiKey}/getFoxMatrixInterviewers`);
  return response.data.responseData;
};

export const getEmployeePerformanceByUserId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeePerformanceByUserId?userId=${id}`
  );
  return response.data.responseData;
};

export const getPerformanceReviewById = async (id) => {
  const response = await axios.get(
    `${apiKey}/getPerformanceReviewById?id=${id}`
  );
  return response.data.responseData;
};

export const getEmployeeDetailsByEmployeeId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeeDetailsByEmployeeId?empId=${id}`
  );
  return response.data.responseData;
};

export const viewFeedback = async (id) => {
  const response = await axios.get(
    `${apiKey}/viewFeedback?performnaceFeedbackId=${id}`
  );
  return response.data.responseData;
};

export const editPerformanceReviewSubjectiveType = async (data) => {
  const response = await axios.post(
    `${apiKey}/editPerformanceReviewSubjectiveType`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: employeeId,
      },
    }
  );
  return response.data;
};

export const editPerformanceReviewFileType = async (data) => {
  const response = await axios.post(
    `${apiKey}/editPerformanceReviewFileType`,
    data
  );
  return response.data.responseData;
};

export const updatePerformanceReviewRating = async (data) => {
  const response = await axios.post(
    `${apiKey}/updatePerformanceReviewRating`,
    data
  );
  return response.data.responseData;
};

export const getAllClientInterviewer = async () => {
  const response = await axios.get(`${apiKey}/get-all-client-interviewer`);
  return response.data.responseData;
};

export const deletePerformanceReview = async ({ performanceReviewId }) => {
  const response = await axios.put(
    `${apiKey}/deletePerformanceReview?performanceReviewId=${performanceReviewId}`
  );
  return response.data;
};

export const getAllActiveProject = async () => {
  try {
    const response = await axios.get(`${apiKey2}/getAllActiveProject`, {
      headers: {
        Authorization: null,
      },
    });

    return response.data.responseData;
  } catch (error) {
    // Handle error
    console.error('Error in getAllActiveProject:', error);
    throw error;
  }
};


export const getProjectLeads = async () => {
  const response = await axios.get(`${apiKey3}/get-all-leads`);
  return response.data.responseData;
};

export const getDepartment = async () => {
  const response = await axios.get(`${apiKey3}/getAllFunctionalArea`);
  return response.data.responseData;
};

export const validateEmployeeAccess = async (id) => {
  const response = await axios.get(
    `${apiKey4}/validate-employee-access?empId=${id}`
  );
  return response.data.responseData;
};

export const viewALlFeedbackOfEmp = async (id) => {
  const response = await axios.get(
    `${apiKey}/view-all-feedbacks-of-an-employee?employeeeId=${id}`
  );
  return response.data.responseData;
};

export const getFeedbackCountOfEmployee = async (id) => {
  const response = await axios.get(
    `${apiKey}/view-feedback-count-of-employee?employeeeId=${id}`
  );
  return response?.data?.responseData;
};
export const downloadPerformanceReviewInformation = async (data) => {
  const response = await axios.post(
    `${apiKey}/downloadPerformanceReviewInfoPDF`,
    data,
    {
      responseType: "arraybuffer",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    }
  );
  return response;
};
export const getAllNotesOfAnEmployee = async (id,page) => {
  const response = await axios.get(
    `${apiKey}/getAllNotesOfAnEmployee?empId=${id}&limit=15&page=${page}`
  );
  return response.data.responseData;
};
export const addNote = async (filter) => {
  const response = await axios.post(`${apiKey}/addNotesOfAnEmployee`, filter);
  return response.data.responseData;
};
export const deleteNote = async ({noteId,noteByEmpId}) => {
  const response = await axios.delete(`${apiKey}/deleteNotesOfAnEmployee?noteByEmpId=${noteByEmpId}&notesId=${noteId}`);
  return response.data.responseData;
};

export const getDashboardList = async (empId, leadId, limit, page, year, func, empTyp)=>{
  const response = await axios.get(`${apiKey}/dashboard?empId=${empId}&leadId=${leadId}&limit=${limit}&page=${page}&year=${year}&functionalArea=${func}&employeeType=${empTyp}`)
  return response?.data.responseData
}